export default function listPagination(data){
    const offset = data.offset;
    const limit = data.limit;
    const rowCount = data.rowCount;
    const page = data.page;
    const prefix = data.prefix;
    const type = data.type;
    if(type == "start"){
        if(offset != 0){
        this.reloadList(0,1,prefix);
        }
    }else if(type == "back"){

        if(offset >= limit){
            this.reloadList(offset-limit,page-1,prefix)
        }
    }else if(type == "next"){
        console.log(rowCount,offset,limit);
        if(rowCount == limit){
            this.reloadList(offset+limit,page+1,prefix)
        }
    }
}