export default function createMeetingFromOrd(params) {
    this.load();
    this.rest.post('getOrdToMeetData', {id: params.id}, data => {
        this.unLoad();
        this.detailPredefinedFields = [];
        // Relate - na firmu
        this.detailPredefinedFields.push({
            type: 'parent',
            name: 'parent_name',
            parentName: data['accountName'],
            parentId: data['accountId'],
            parentModule: 'Accounts',
        });

        // Typ - Montáž
        this.detailPredefinedFields.push({
            type: 'field',
            name: 'meeting_type',
            value: 'montaz',
        });
        // Popis - Produkty
        this.detailPredefinedFields.push({
            type: 'field',
            name: 'description',
            value: data['description'],
        });

        // Custom Data
        let user = data['user'];
        this.detailPredefinedFields.push({
            type: 'customData',
            path: 'invitees/relatedInv/Users',
            data: [user],
        });
        this.detailNewRecord({
            module: 'Meetings',
            way: params.prefix
        });
    });
}