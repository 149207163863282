import moment from "moment";

export default function dateFormat(date) {
  var returnDate = "";
  if (date) {
    if(date == "0000-00-00"){
      return "";
    }
    var datePole = date.split(" ");
    if (datePole[0] != undefined && datePole[0] != null) {
      returnDate = moment(new Date(datePole[0])).format(this.getDateFormat("moment"));
    }
    if (datePole[1] != undefined && datePole[1] != null) {
      if (returnDate != "") {
        returnDate += " ";
      }
      returnDate += moment(new Date(date)).format(this.getTimeFormat("moment"));
    }
    return returnDate;
  }
  return date;
}
