import {calPlanReload} from "../../exportConfig";

export default function saveCalSettings(state, callBack){
    var self = this;

    this.rest.post("saveCalSettings", state, function(ret){
        if(ret.retCode == "OK"){
            callBack();
            self.calPlanReload();
        }
        else{
            alert(ret.retMsg);
        }
    });
}
