import React from "react"
import sAction from "sAction"
export default function infoTable(props){
    const data = props.data
    var linesRender = [];
    data.get("lines").forEach((line,index) => {
        var trRender = [] 
        line.forEach((cell,cellIndex) => {

            var value = cell.get("value");
            if(cell.get("type") == "url"){
                value = <a title={value} href={cell.get("url")}>{value}</a>
            }
            const label = sAction.translate(cell.get("label"));
            const labelIcon = cell.get("labelIcon") != undefined ? 
            <div className={cell.get("labelIcon")+" labelIcon"} /> : null
            trRender.push(<td key={cellIndex}>
            {labelIcon}
            {label+" "}
            {value}
            </td>)
        })
        linesRender.push(<tr key={index}>{trRender}</tr>)
    })
 
    return (
        <div className="detailTopInfo">
            {data.get("header") != undefined && 
            <div className="detailViewPanelHeader">
                {sAction.translate(data.get("header"))}
            </div>
            }
            <table>
                <tbody>
                    {linesRender}
                </tbody>
            </table>
        </div>
    )
}