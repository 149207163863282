import React from "react";
import PureComponent from "../pure";

import ReportWindow from "./reportWindow/ReportWindow";
import RelateListView from "./relateListView/RelateListView";
import CalWindowContainer from "./calendarWindow/calWindowContainer";
import CalSettingsForm from "../calendar/calSettingsForm";
import AuditLog from "./auditLog/auditLog";
import ExportList from "./exportList/ExportList";
import DetailViewPopup from "./DetailView/DetailViewPopup";
import FileUpload from "./Files/FileUpload";
import FileDownload from "./Files/FileDownload"
import FilePreview from "./Files/FilePreview"
import Button from "../formElements/Button";

import sAction from "sAction";
import EmailAddContact from "../email/EmailAddContact";
import EmailSettingsOePopup from "./email/EmailSettingsOePopup";
import EmailSelectFolderPopup from "./email/EmailSelectFolderPopup";
import EmailSignaturePopup from "./email/EmailSignaturePopup";
import AresChooseAccountPopup from "./detailApi/AresChooseAccountPopup";
import FinstatChooseAccountPopup from "./detailApi/FinstatChooseAccountPopup";
import ChangesDetectedPopup from "./listview/ChangesDetectedPopup";
import ChooseAddressPopup from "./detailApi/ChooseAddressPopup";
import OppChooseTemplate from "./detailApi/OppChooseTemplate";
import CloseOpportunityPopup from "./detailApi/CloseOpportunityPopup";
import CloseQuotePopup from "./detailApi/CloseQuotePopup";

import OppDuplicatePopup from "./detailApi/OppDuplicatePopup";
import OppDuplicateQuotePopup from "./detailApi/OppDuplicateQuotePopup";
import OppCreateQuoteFromTemplate from "./detailApi/OppCreateQuoteFromTemplate";

import QuoteCreateTemplatePopup from "./detailApi/QuoteCreateTemplatePopup"

import EmailCompose from "../email/EmailCompose";
import SweetSpot from "./../SweetSpot/SweetSpot";
import Loader from "../loader";
import RolesPopup from "./roles/Roles";
import AddGiftPopup from "./DetailView/addGiftPopup";
import ActivitiesTimeline from "../RightPanel/ActivitiesTimeline/ActivitiesTimeline";
import DynamicEnumPopup from "./DynamicEnumPopup/DynamicEnumPopup";
import ChangePasswordPopup from "./Users/ChangePasswordPopup";

import AddRelate from './tags/AddRelate';
import TagRelatesList from './tags/TagRelatesList';

import PopupAcmDate from './popupAcmDate/PopupAcmDate'
import PopupAcmDateTime from './popupAcmDate/popupAcmDateTime'

import CreateMultipleCalls from './createMultipleCalls/CreateMultipleCalls';

import Image from './image/Image'
import QuoteCreateOrderPlasPopup from "./DetailView/QuoteCreateOrderPlasPopup";
class Popup extends PureComponent {
  constructor(props) {
    super(props);
    this.popup = React.createRef();
  }
  componentDidUpdate() {
    super.componentDidUpdate();
  }

  close(e) {
    const classes = e.target.classList;
    if (classes != undefined) {
      if (classes.contains("popupShow") || classes.contains("popupCloseIcon")) {
        this.forceClose();
        //
      }
    }
  }
  forceClose() {
    console.log("ted");
    this.popup.current.classList.add("popupHide");
    setTimeout(() => {
      const closeFnc = this.props.data.data.get("onClose");
      sAction.popupHide(closeFnc);
    }, 200);
  }
  callback(callBackData) {
    const data = this.props.data;
    const popupData = data.data;
    const callback = popupData.get("callback");

    if (callback !== undefined) {
      callback(callBackData);
    }
  }
  render() {
    var data = this.props.data;
    var content = data.content;
    var popupData = data.data;
    var customStyle = null;
    var customClass = null;
    const way = this.props.way;
    var popupContainer = true;
    // hodnota true => zabrani zavreni popupu klikem mimo okno
    // popup bude mozne zavrit pouze krizkem vpravo nahore
    var preventClose = false;

    switch (content) {
      case "reportWindow":
        content = (
          <ReportWindow
            data={this.props.reportWindow}
            listData={this.props.popupListData}
            close={() => this.forceClose()}
          />
        );
        customStyle = { width: "90%", height: "80%" };
        break;

      case "relateListView":
        content = (
          <RelateListView
            popupData={popupData}
            data={popupData.get("listView")}
          />
        );
        customClass = data.content;
        customStyle = { width: "80%", height: "70%", padding: "0px" };
        break;

      case "roles":
        content = <RolesPopup popupData={popupData} />;
        break;

      case "load":
        content = <Loader />;
        break;
      case "popupDetail":
        content = <DetailViewPopup data={popupData} way="conf/popup" />;
        customClass = "detailPopup";
        break;
      case "changesCheck":
        content = <ChangesDetectedPopup data={popupData} />;
        // customClass = "detailPopup";
        break;
      case "calQuickEdit":
        //editView
        const module = popupData.get("module");
        const prefix = popupData.get("prefix");
        const pomProms = sAction.dataGet(prefix);
        var dataCal = {
          calQuickCreate: pomProms.detailView,
          meetingView: pomProms.meetingView,
          callView: pomProms.callView,
          taskView: pomProms.taskView,
          invitees: pomProms.invitees,
          activeTab: pomProms.activeTab,
          menuOpen: pomProms.menuOpen
        };

        content = (
          <CalWindowContainer
            data={dataCal}
            type="editView"
            module={module}
            prefix={prefix}
          />
        );

        customStyle = { width: "80%", minWidth: "990px" };

        break;

      case "calSettingsForm":
        content = <CalSettingsForm data={popupData.get("setting")} />;
        customClass = "calSettingsForm-popup";
        break;

      case "calQuickCreate":
        //createView
        const prefixC = popupData.get("prefix");
        const pomPromsC = sAction.dataGet(prefixC);
        var dataCal = {
          calQuickCreate: pomPromsC.detailView,
          meetingView: pomPromsC.meetingView,
          callView: pomPromsC.callView,
          taskView: pomPromsC.taskView,
          invitees: pomPromsC.invitees,
          activeTab: pomPromsC.activeTab,
          menuOpen: pomPromsC.menuOpen
        };
        customStyle = { width: "80%", minWidth: "990px" };

        content = (
          <CalWindowContainer
            data={dataCal}
            type="createView"
            prefix={prefixC}
          />
        );
        break;

      case "auditLog":
        content = (
          <AuditLog
            module={popupData.get("module")}
            parentId={popupData.get("parentId")}
          />
        );
        customClass = "auditLog";
        break;

      case "emailAddContact":
        content = <EmailAddContact data={popupData} />;
        break;

      case "emailCompose":
        content = <EmailCompose data={popupData} way="conf/popup/data" />;
        customClass = "email__compose-popup";
        preventClose = true;
        break;

      case "emailSettingsOePopup":
        content = <EmailSettingsOePopup data={popupData} />;
        break;

      case "emailSelectFolderPopup":
        content = <EmailSelectFolderPopup data={popupData} />;
        break;

      case "emailSignaturePopup":
        content = <EmailSignaturePopup data={popupData} />;
        break;

      case "aresChooseAccountPopup":
        content = <AresChooseAccountPopup data={popupData} />;
        break;
      case "finstatChooseAccountPopup":
        content = <FinstatChooseAccountPopup data={popupData} />;
        break;

      case "ChooseAddressPopup":
        content = <ChooseAddressPopup data={popupData} />;
        break;
      case "printOpportunity":
        content = <OppChooseTemplate data={popupData} />;
        break;
      case "closeOpportunity":
        content = <CloseOpportunityPopup data={popupData} />;
        break;
      case "oppDuplicateQuote":
        content = <OppDuplicateQuotePopup data={popupData.get("params")} />;
        break;
      case "oppDuplicate":
        content = <OppDuplicatePopup data={popupData.get("params")} />;
        customClass = "OppDuplicatePopup";
        break;
      case "oppCreateQuoteFromTemplate":
        content = <OppCreateQuoteFromTemplate data={popupData.get("params")} />
        break;
      case "quoteCreateTemplate":
        content = <QuoteCreateTemplatePopup data={popupData.get("params")} />
        break;
      case "closeQuote":
        content = <CloseQuotePopup data={popupData} />;
        break;
      case "SweetSpot":
        content = <SweetSpot data={popupData} />;
        customClass = "sweetSpot-popup";
        break;
      case "fileUpload":
        content = <FileUpload data={popupData} parent={this} />;
        break;
      case "fileDownload":
        content = <FileDownload data={popupData} parent={this} />;
        break;
      case "filePreview":
        content = <FilePreview data={popupData} parent={this} />;
        break;
      case "DynamicEnumPopup":
        content = <DynamicEnumPopup data={popupData} way={way} />
        break;
      case "exportList":
        content = (
          <div className="exportListPopupContent">
            <ExportList
              columns={popupData.get("columns")}
              onCreate={data => this.callback(data)}
              close={() => this.forceClose()}
            />
          </div>
        );
        break;
      case "addGift":
        content = (
          <AddGiftPopup
            data={popupData}
          />
        );
        customClass = "relateListView";
        customStyle = { width: "80%", padding: "0px" };
        break;
      case "activitiesTimeline":
        content = (
          <ActivitiesTimeline
            prefix={"conf/popup/data"}
            data={popupData}
          />
        );
        break;
      case "changePassword":
        content = (<ChangePasswordPopup
          data={popupData}
          close={() => this.forceClose()}
        />);
        break;
      case 'tagAddRelate':
        content = (
          <AddRelate
            data={popupData}
            way={way}
          />
        );
        break;
      case 'TagRelatesList':
        content = (
          <TagRelatesList
            data={popupData}
            way={way}
          />
        );
        break;
      case 'acmDate':
        content = <PopupAcmDate data={popupData} />
        popupContainer = false
        break;
      case 'acmDateTime':
        content = <PopupAcmDateTime data={popupData} />
        popupContainer = false
        break;
      case 'image':
        content = <Image data={popupData} />
        customClass = "image-popup";
       // popupContainer = false
        break;
      case 'createMultipleCalls':
          content = <CreateMultipleCalls
              data={popupData}
              way={way}
          />;
        break;
      case 'QuoteCreateOrderPlasPopup':
        content = <QuoteCreateOrderPlasPopup
            data={popupData}
            way={way}
        />;
        break;
      default:
        if (typeof content === "string") {
          content = content.replace(/\\n/g, "<br>");
          content = (
            <div className="acmPopupContent">
              <div
                className="acmPopupContentWrapper"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          );
        }
      // jinak se vyrenderuje vlozena komponenta tak jak je
    }
    var header = null;
    const headerData = popupData.get("header");
    var classNameColor = popupData.get("color");
    if (headerData != undefined) {
      var className = "acmPopupHeader";
      if (classNameColor != undefined) {
        className += " " + classNameColor;
      }
      header = <div className={className}>{headerData}</div>;
    }

    var buttons = [];
    const buttonData = popupData.get("buttons");
    if (buttonData !== undefined) {
      buttonData.forEach((button, index) => {
        buttons.push(
          <Button key={index} onClick={() => button.get("callback")()}>
            {button.get("label")}
          </Button>
        );
      });
    }

    const paramCustomStyle = popupData.get("customStyle");
    if (customStyle == null && paramCustomStyle != undefined) {
      customStyle = paramCustomStyle.toJS();
    }
    const paramCustomClass = popupData.get("customClass");

    if (customClass == null && paramCustomClass != undefined) {
      customClass = paramCustomClass;
    }

    return (
      <React.Fragment>
        {popupContainer == true ?
          <div
            ref={this.popup}
            onMouseDown={e => this.close(e)}
            className={
              data.show && !preventClose ? "acmPopup popupShow" : "acmPopup"
            }
          >
            <div className={"popupContent " + customClass} style={customStyle}>
              <div className="icon-Close popupCloseIcon" />
              {header}
              {content}
              {buttons.length != 0 && (
                <div className="acmPopupButtons">{buttons}</div>
              )}
            </div>
          </div>
          :
          content
        }
      </React.Fragment>
    );
  }
}

export default Popup;
