import detailDefault from "../../detailDefault";
export default class detailQuotes extends detailDefault{
    load(sAction,data) {
        const id = sAction.dataGet('view/id');
        sAction.load();
        sAction.rest.post('checkOrderEdit', {id: id}, returnData => {
            if (returnData.status) {
                sAction.error(sAction.translate(returnData.message.data.message));
                if (returnData.message.data.message === 'LBL_ORDER_WAS_EDITED_CANT_EDIT') {
                    const buttons = sAction.dataGet('view/buttons');
                    let result = [...buttons.slice(0, 0), ...buttons.slice(1)];
                    result = [...result.slice(0, 0), ...result.slice(1)];
                    sAction.dsClear();
                    sAction.dsAdd('set', 'view/buttons', result);
                    sAction.dsAdd('set', 'view/readonly', true);
                    sAction.dsProcess();
                }
            }
            sAction.unLoad();
        });
    };
    update(sAction,data) {
        if(data.field == "danova_sazba"){
            sAction.dataSet(data.prefix+"/customData/productData/dph", data.value);
        } else if(data.field == "price_cur"){
            sAction.dsClear();
            // TODO pozmenit vsechny produkty
            const rate = data.value;
            var storedData = sAction.dataGet(data.prefix + '/customData/productData/groups').toJS();
            storedData.forEach((group, groupKey) => {
                console.log('DANIEL', group);
                group.lines.forEach((line, lineKey) => {
                    // if(line.currency_id == 'b8326c50-6f19-063b-507d-5e7cc12d5619') {
                    if(line.list_price) {
                        sAction.dsAdd("set", data.prefix + "/customData/productData/groups/" + groupKey + '/lines/' + lineKey + '/czk_price', line.list_price * rate);
                    }
                });
            });
            sAction.dsProcess();
        }
    }

}
