import {calPlanReload} from "../../exportConfig";

export default function calSaveActivityDetailHelper(params, callback){
  const prefix = params.prefix;
  const prefixInv = params.prefixInv;
    const canSave = this.dataGet(prefix+"/canSave");

    var id = this.dataGet(prefix+"/id");
    const module = this.dataGet(prefix+"/module");

    var fields = this.dataGet(prefix+"/fields");

    const editFields = this.dataGet(prefix+"/changes/fields");
    const invitees = this.dataGet(prefixInv+"/relatedInv").toJS();
    const selectedOrderLines = this.dataGet(prefix+"/customData/selectedOrderLines");
    var self = this;
    self.dsClear();

    if (canSave == true) {
        if(!this.custom("beforeSave",{})){
        }
        
        var sendToCrm = true;
        fields.valueSeq().forEach(data => {
          if (data.def.get("required") == true && (data.value == "" || data.value == null)) {
            self.dsAdd("set", prefix+"/fields/"+data.name+"/def/isInvalid", true);
            self.dsAdd("set", prefix+"/fields/"+data.name+"/customClass", "error");
            sendToCrm = false;
          }
          else{
            self.dsAdd("set", prefix+"/fields/"+data.name+"/def/isInvalid", false);
            self.dsAdd("set", prefix+"/fields/"+data.name+"/customClass", "");
          }
        });

        self.dsProcess();

        if (sendToCrm == false) {
            
        } else {
            self.load();

            if(id == undefined){
              id = "";
            }

            var pomEditFields = {fields: editFields.toJS(), invitees: invitees, selectedOrderLines: selectedOrderLines, orderID:this.dataGet(prefix+"/customData/oID")};
           this.rest.post("set/" + module + "/" + id, pomEditFields, function(msg) {
              const data = {
                 state: true,
                 data: msg
              };

              self.popupHide(self.clearCalDetailStore);
              // TTT PRERENDER
              if(prefix.indexOf("calendar") !== -1){
                self.calGetEvents(true, (data) => {
                  // prevent early reload
                });
              }
              else{
                // const pomWay = prefix.substr(0, prefix.indexOf('/data/viewData/'));
                // const attrs = {
                //   action : "refresh",
                //   cssSelector : "#dashFC_"+self.dataGet(pomWay+"/data/viewData/dashletId"),
                //   way: pomWay
                // };
                // self.calActivityDashlet(attrs);
              }

              self.calPlanReload('calendar')

              self.clearCalDetailStore();

              if(callback != undefined){
                callback(data);
              }
            });

            self.unLoad();

        }
      }

}
