import React from 'react';
import PureComponent from '../pure'

import sAction from "sAction";
import CalExtEvent from "./calExtEvent";
import InputText from "../formElements/InputText";
import Text from "../formElements/InputText";

class calExternalEvents extends PureComponent {
  constructor(props) {
    super(props);

    this.input = React.createRef();
    this.state = {
      searchInput: '',
      changeMe: true, // docasna hodnota. Kdyz menim komplet subtabs, tak se nespusti render, takze zatim forcuju takhle
      eventsPayed: [],
      eventsInProcess: [],
      eventsOrdered: [],
      eventsConfirmed: [],
      eventsCompleted: [],
      subtabs: {
        payed: true,
        inProgress: true,
        ordered: true,
        confirmed: true,
        completed: true,
      }
    };
  }


  // calendarRef = React.createRef();
  componentDidMount() {
    this.filterThrough()
  }
  componentDidUpdate(prevProps, ) {
    if (this.props.reload !== prevProps.reload) {
      this.filterThrough()
    }
  }

  filterThrough(){
    // mozna by jsme mohli tahat jen data pro otevrene sekce, ale uvidime jestli bude potreba
    let getEvents = {
      Payed: {
        module: 'orders',
        filter: 'Payed',
        date_start: '',
        date_end: '',
      },
      InProcess: {
        module: 'orders',
        filter: 'InProcess',
        date_start: '',
        date_end: '',
      },
      Ordered: {
        module: 'orders',
        filter: 'Ordered',
        date_start: '',
        date_end: '',
      },
      Confirmed: {
        module: 'orders',
        filter: 'Confirmed',
        date_start: '',
        date_end: '',
      },
      Completed: {
        module: 'orders',
        filter: 'Completed',
        date_start: '',
        date_end: '',
        
      }
    }
    getEvents.forEachObject((value, key) => {
      //TODO udelat na jedno volani a pak to tady na FE roztridit. pujde jednoduse.
      // Tenhle foreach se akorat prenese na BE i s filtrem a tady se to jen podle priznaku roztridi
      sAction.calGetExternalEvents(value.module, value.filter, {
        'date_start': value.date_start,
        'date_end': value.date_end
      },this.state.searchInput, (events) => {
        if (events.status) {
          let name = 'events' + key;
          this.setState({[name]: events.message.data})
        } else {
          sAction.alert(events.errorMessage.text)
        }
      })
    });

    sAction.calPlanReload(); // aby se nastavili jako draggable, tak je treba prenacist kalendare ... muzeme zkusit vymyslet jinak
  }

  render() {
    return (
      <div
        id="external-eventsNew"
        className={"filter-externalEvents"}
        style={{
          // padding: "10px",
          width: "100%",
          height: "auto",
          maxHeight: "-webkit-fill-available"
        }}
      >
        <div className="listViewSearch fillSearch">
          <div
            onClick={() => this.filterThrough()}
            className="icon-search listViewSearchIcon"
          />
          <Text
            myRef={this.input}
            onKeyUp={e => this.searchKeyUp(e.target.value)}
            placeholder={sAction.translate("LBL_SEARCH_CAL")}
            autoFocus={true}
            clearButton={true}
            onClearButton={() => this.searchKeyUp()}
          />
        </div>

        {/*//TODO predelat do komponenty podle typu eventu*/}
        <div className={'externalEventsSubtab'}>
          <div className={'calFiltersRow sectionRow'} onClick={() => this.handleSubtabSelect('payed')}>
            <span style={{'width':'100%'}}>
              <div className={"calEventIcon icon-acm_orders"}/><strong> Zaplacené </strong><div className={"calEventIcon float-right icon-"+(this.state.subtabs.payed?'minus':'plus')}/>
            </span>
          </div>
          {this.state.subtabs.payed && (
            <div className={'filterRowState'}>
              {this.state.eventsPayed.map(event => (
                <CalExtEvent event={event} key={'ee-' + event.id}/>
              ))}
            </div>
          )}
        </div>
        <div className={'externalEventsSubtab'}>
          <div className={'calFiltersRow sectionRow'} onClick={() => this.handleSubtabSelect('inProgress')}>
            <span style={{'width':'100%'}}>
              <div className="calEventIcon icon-acm_orders"/><strong> Rozpracované </strong><div className={"calEventIcon float-right icon-"+(this.state.subtabs.inProgress?'minus':'plus')}/>
            </span>
          </div>
          {this.state.subtabs.inProgress && (
            <div className={'filterRowState'}>
              {this.state.eventsInProcess.map(event => (
                <CalExtEvent event={event} key={'ee-' + event.id}/>
              ))}
            </div>
          )}
        </div>
        <div className={'externalEventsSubtab'}>
          <div className={'calFiltersRow sectionRow'} onClick={() => this.handleSubtabSelect('ordered')}>
            <span style={{'width':'100%'}}>
              <div className="calEventIcon icon-acm_orders"/><strong> Objednáno </strong><div className={"calEventIcon float-right icon-"+(this.state.subtabs.ordered?'minus':'plus')}/>
            </span>
          </div>
          {this.state.subtabs.ordered && (
            <div className={'filterRowState'}>
              {this.state.eventsOrdered.map(event => (
                <CalExtEvent event={event} key={'ee-' + event.id}/>
              ))}
            </div>
          )}
        </div>
        <div className={'externalEventsSubtab'}>
          <div className={'calFiltersRow sectionRow'} onClick={() => this.handleSubtabSelect('confirmed')}>
            <span style={{'width':'100%'}}>
              <div className="calEventIcon icon-acm_orders"/><strong> Potvrzeno </strong><div className={"calEventIcon float-right icon-"+(this.state.subtabs.confirmed?'minus':'plus')}/>
            </span>
          </div>
          {this.state.subtabs.confirmed && (
            <div className={'filterRowState'}>
              {this.state.eventsConfirmed.map(event => (
                <CalExtEvent event={event} key={'ee-' + event.id}/>
              ))}
            </div>
          )}
        </div>
        <div className={'externalEventsSubtab'}>
          <div className={'calFiltersRow sectionRow'} onClick={() => this.handleSubtabSelect('completed')}>
            <span style={{'width':'100%'}}>
              <div className="calEventIcon icon-acm_orders"/><strong> Naskladněno </strong><div className={"calEventIcon float-right icon-"+(this.state.subtabs.completed?'minus':'plus')}/>
            </span>
          </div>
          {this.state.subtabs.completed && (
            <div className={'filterRowState'}>
              {this.state.eventsCompleted.map(event => (
                <CalExtEvent event={event} key={'ee-' + event.id}/>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }

  handleSubtabSelect = (subtabID) => {
    let newState = this.state.subtabs;
    newState[subtabID] = !newState[subtabID]
    this.setState({
      subtabs: newState,
      changeMe: !this.state.changeMe
    }); // TODO najit reseni bez changeMe BS
  }

  searchKeyUp = (value) => {
    if (this.state.searchInput !== value) {
      if (this.interval != null) {
        clearInterval(this.interval);
      }
      var self = this;
      this.interval = setInterval(() => {
        this.setState({searchInput: value})
        this.filterThrough()
        clearInterval(self.interval);
      }, 300);
    }
  }

}

export default calExternalEvents
