export default function orderBy(field, asc, prefix) {

  this.dsClear();
  this.dsAdd("set",prefix+"/orderBy",field);
  this.dsAdd("set",prefix+"/asc",asc)
  this.dsProcess();


  this.reloadList(0, 1, prefix);
}
