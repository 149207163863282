export default function createNewVersion(param) {
    const modul = param.module;
    console.log(param);

    const detail = this.dataGet(param.prefix);

    this.dsClear();
    detail.fields.entrySeq().forEach(e => {
        const name = e[0];
        const field = e[1];
        const value = field.value;

        const type = field.def.get("type");
        if (name === "quote_num") {
            this.dsAdd("set", param.prefix + "/changes/fields/" + name, "");
            this.dsAdd("set", param.prefix + "/fields/" + name + "/value", "");
        } else if (name === "verze_c") {
            this.dsAdd("set", param.prefix + "/changes/fields/" + name, value + 1);
            this.dsAdd("set", param.prefix + "/fields/" + name + "/value", value + 1);
        } else if (type == "relate" || type == "link" || type == "parent") {
            this.dsAdd(
                "set",
                param.prefix + "/changes/fields/" + field.def.get("id_name"),
                field.def.get("id_value")
            );
            if (type == "parent") {
                this.dsAdd(
                    "set",
                    param.prefix + "/changes/fields/parent_type",
                    field.def.get("parent_type_value")
                );
            }
            this.dsAdd("set", param.prefix + "/changes/fields/" + name, value);
        } else {
            this.dsAdd("set", param.prefix + "/changes/fields/" + name, value);
        }
    });
    this.dsAdd("set", param.prefix + "/id", null);
    this.dsProcess();
}
