export default function quoteCreateOrder(data) {
    const zamerovac = this.dataGet(data.prefix + '/fields/zamerovac/value');
    const dph = this.dataGet(data.prefix + '/fields/danova_sazba/value');
    if (!zamerovac) {
        this.error(this.translate('LBL_ZAMEROVAC_NOT_SET'));
        this.dsClear();
        this.dsAdd("set", data.prefix + "/fields/zamerovac/customClass", 'error');
        this.dsProcess();
    } else {
        this.load();
        // Stahne data
        const params = {record: data.id};
        this.rest.post('checkAccAddressFromQuote', {quoteID: data.id}, (returnAddressData) => {
            if (returnAddressData.status === true) {
                this.rest.post("getQuoteEmailData", params, (returnData) => {
                    if (returnData.status === true) {
                        let files = [];
                        // Vytvori definice souboru
                        let email = {
                            module: 'Quotes',
                            id: data.id,
                            name: this.dataGet(data.prefix + "/fields/name/value"),
                        };

                        files.push({
                            ignoreBackupInvoice: 'true',
                            buttonId: 'print_order',
                            module: 'acm_orders',
                            prefix: 'view',
                            type: 'all',
                            id: returnData.messageData.orderID,
                            name: returnData.messageData.orderName + ' objednavka',
                            returnFile: true,
                            action: 'printPdf',
                            filetype: 'pdf',
                            save: true,
                        });

                        files.push({
                            save: true,
                            filetype: 'pdf',
                            action: 'printPdf',
                            // action: 'static',
                            returnFile: true,
                            buttonId: 'vop',
                            module: 'VOP',
                            prefix: 'view',
                            type: 'all',
                            id: data.id,
                            name: 'Podmínky_VOP',
                        });

                        const offerType = this.dataGet(data.prefix + '/fields/offer_type/value');
                        if (offerType === 'reklamace') {
                            // Jedna se o rekladaci  nic dalsiho netisknout, jen poslat maila
                            sendMail(this, files, email, data);
                        } else {
                            // Nejedna se o rekladaci, pokracovat v logice
                            if (!returnAddressData.message || !returnAddressData.message.data || !returnAddressData.message.data.ico) {
                                // https://gitlab.acmark.cz/coripo/plas/-/issues/233
                                if (dph == '12') {
                                    files.push({
                                        save: true,
                                        filetype: 'pdf',
                                        action: 'printPdf',
                                        // action: 'static',
                                        returnFile: true,
                                        buttonId: 'ceste_prohlaseni',
                                        module: 'Prohlaseni',
                                        ord: returnData.messageData.orderID,
                                        prefix: 'view',
                                        type: 'all',
                                        id: data.id,
                                        name: 'cestne_prohlaseni',
                                    });
                                }
                            }
                            if (!returnAddressData.message || !returnAddressData.message.data || !returnAddressData.message.data.dic) {
                                files.push({
                                    save: true,
                                    filetype: 'pdf',
                                    action: 'printPdf',
                                    // action: 'static',
                                    returnFile: true,
                                    buttonId: 'souhlas_s_gdpr',
                                    module: 'GDPR',
                                    prefix: 'view',
                                    type: 'all',
                                    id: data.id,
                                    name: 'souhlas_s_GDPR',
                                });
                            }
                            const reklamacniRad = this.dataGet(data.prefix + '/fields/print_reclamation/value');
                            if (reklamacniRad && reklamacniRad !== '0') {
                                files.push({
                                    save: true,
                                    filetype: 'pdf',
                                    action: 'printPdf',
                                    // action: 'static',
                                    returnFile: true,
                                    buttonId: 'reklamacni_rad',
                                    module: 'ReklamacniRad',
                                    ord: data.record,
                                    prefix: 'view',
                                    type: 'all',
                                    id: data.id,
                                    name: 'Reklamační řád',
                                });
                            }
                            const backupInvoice = this.dataGet(data.prefix + '/fields/backup_invoice/value');
                            if (!backupInvoice || backupInvoice === '0' || !returnData.messageData.invoiceID) {
                                sendMail(this, files, email, data);
                            } else {
                                files.push({
                                    buttonId: 'print_invoice',
                                    module: 'acm_invoices',
                                    prefix: 'view',
                                    type: 'all',
                                    id: returnData.messageData.invoiceID,
                                    name: returnData.messageData.invoiceName + ' zálohová faktura',
                                    returnFile: true,
                                    action: 'printPdf',
                                    filetype: 'pdf',
                                    save: true,
                                });

                                if (returnData.messageData.invoiceTwoID) {
                                    files.push({
                                        buttonId: 'print_invoice',
                                        module: 'acm_invoices',
                                        prefix: 'view',
                                        type: 'all',
                                        id: returnData.messageData.invoiceTwoID,
                                        name: returnData.messageData.invoiceTwoName + ' zálohová faktura 2',
                                        returnFile: true,
                                        action: 'printPdf',
                                        filetype: 'pdf',
                                        save: true,
                                    });
                                    if (returnData.messageData.invoiceThreeID) {
                                        files.push({
                                            buttonId: 'print_invoice',
                                            module: 'acm_invoices',
                                            prefix: 'view',
                                            type: 'all',
                                            id: returnData.messageData.invoiceThreeID,
                                            name: returnData.messageData.invoiceThreeName + ' zálohová faktura 3',
                                            returnFile: true,
                                            action: 'printPdf',
                                            filetype: 'pdf',
                                            save: true,
                                        });
                                    }
                                }

                                sendMail(this, files, email, data);
                            }
                        }
                    } else {
                        this.unLoad();
                        this.alert(this.translate(returnData.errorMessage));
                    }
                });
            } else {
                this.unLoad();
                this.alert(this.translate(returnAddressData.errorMessage));
            }
        });
    }
}

function sendMail(sAction, files, email, params) {
    sAction.getSavedPrintFiles({ files: files, email: email, params: params });
}
