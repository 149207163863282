

export default function addFilter(data){

      const filterGroup = {
        "filters": data.filters,
        "operator": data.operator,
        "field" : data.field,
        
      }

      var akce = {
        type: "ADDTOFILTER",
        listViewPrefix: data.prefix,
        filter: filterGroup,
      }
      this.dataSetCustom(akce)

      if(data.reload == true){
      this.reloadList(0,1,data.prefix);
      }

}