export default function filesNewVersion(paramData) {
  var tempEl = document.createElement("input");
  tempEl.setAttribute("type", "file");
  tempEl.style = "display: none";
  document.body.appendChild(tempEl);

  tempEl.addEventListener("change", e => {
    var formData = {
      files: []
    };
    tempEl.files.forEachObject((file, key) => {
      if (key != "length") {
        formData["files"].push(file);
      }
    });
    formData.id = paramData.id

    this.popup("fileUpload", { percent: 0, loading: true, onClose: () => {
      this.route();
    } });
    this.filesXhr({
      action: "updateFile",
      data: formData,
      success: data => {
        if (data.state == true) {
          this.dataSet("conf/popup/data/loading", false);
        }
      },
      progress: data => {
        this.dataSet("conf/popup/data/percent", data.percent);
      }
    });
  })
  tempEl.click();
}
