import InputField from '../inputFieldClass'
export default class Fullname extends InputField {
    constructor(){
        super();
        this.state = {
          state: "fine",
          reg: /^[a-zA-ZÀ-ž\.\-\/\,\:\?\!\+\@ 0-9]+$/,
        };
      }
}
