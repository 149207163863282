import {calPlanReload} from "../../exportConfig";

export default function calActivityHelper(params){
    var self = this;
    const prefix = params.prefix;


    if(params.type == "DELETE"){
        const id = params.id;
        const module = params.module;

        
        this.rest.delete("delete/"+module+"/"+id, {},() => {
            self.popupHide(self.clearCalDetailStore);

            if(prefix.indexOf("calendar") !== -1){
                self.calGetEvents(true, (data) => {
                });
              }
              else{
                //const pomWay = prefix.substr(0, prefix.indexOf('/data/viewData/'));
                // const attrs = {
                //   action : "refresh",
                //   cssSelector : "#dashFC_"+self.dataGet(prefix+"/../dashletId"),
                //   way: prefix+"/../../.."
                // };
                // self.calActivityDashlet(attrs);
              }

            // musi byt az po navratu z restu, jinak se kalendar prenacte driv nez se provedou nutne zmeny a padne to
            this.calPlanReload();
        });
    }
    else if(params.type == "CANCEL"){
        self.popupHide(self.clearCalDetailStore);
    }
    else if(params.type == "FULLFORM"){
        const id = params.id;
        const module = params.module;

        window.open("#detail/"+module+"/"+id, '_blank');
    }  
    else if(params.type == "COMPLETEANDCREATENEW"){
        console.log("COMPLETEANDCREATENEW", params);

        const pomPrefix = prefix.substr(0, prefix.indexOf('/detailView'));

        self.saveField({way: prefix+"/fields/status", name: "status", value: params.compValue}, true);
        self.calSaveActivityDetailHelper(params, function(data) {
            if (data.state == true) {
                var target = "";
                switch(params.module){
                    case "Meetings":
                        target = "detailMeeting";
                        break;
                    case "Calls":
                        target = "detailCall";
                        break;
                    case "Tasks":
                        target = "detailTask";
                        break;
                }

                self.dataSet(pomPrefix+"/activeTab", target);
   
                self.dsClear();
                let action = {
                    type: "CALENDARINITACTIVITY",
                    content: {invitees : {Users: [], Contacts: [], Leads : []}},
                    prefix: pomPrefix+"/invitees/relatedInv"
                }
                self.dsAddCustom(action);
                self.dsProcess();

                self.popup("calQuickCreate", {onClose : self.clearCalDetailStore, prefix : pomPrefix});
            }else{
              self.error(data.msg);
            }
         });
        
    }
    else if(params.type == "CREATEACTIVITY"){
        let target = "";

        const start = params.date.format("YYYY-MM-DD hh:mm:ss");
        const end = params.date.add(15, 'minutes').format("YYYY-MM-DD hh:mm:ss");

        switch(params.module){
            case "Meetings":
                target = "detailMeeting";
                self.saveField({way: "calendar/meetingView/fields/date_start", name: "date_start", value: start}, true);
                self.saveField({way: "calendar/meetingView/fields/duration_hours", name: "duration_hours", value: "0", valueMinutes: 15, type:"hourseMinutes"}, true);
                break;
            case "Calls":
                target = "detailCall";
                self.saveField({way: "calendar/callView/fields/date_start", name: "date_start", value: start}, true);
                self.saveField({way: "calendar/callView/fields/duration_hours", name: "duration_hours", value: "0", valueMinutes: 15, type:"hourseMinutes"}, true);
                break;
            case "Tasks":
                target = "detailTask";
                self.saveField({way: "calendar/taskView/fields/date_start", name: "date_start", value: start}, true);
                self.saveField({way: "calendar/taskView/fields/date_due", name: "date_due", value: end}, true);
                break;
        }

        self.dataSet("calendar/activeTab", target);
        //const currentUserInv = self.dataGet("calendar/setting/currentUserInv");

        self.dsClear();
        let action = {
            type: "CALENDARINITACTIVITY",
            content: {invitees : {Users: [], Contacts: [], Leads : []}},
            prefix: "calendar/invitees/relatedInv"
        }
        self.dsAddCustom(action);
        self.dsProcess();
        self.popup("calQuickCreate", {onClose : self.clearCalDetailStore, prefix: "calendar"});
    }

}
