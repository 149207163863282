import $ from 'jquery';
import fullCalendar from 'fullcalendar'

import moment from 'moment';

export default function calInit(cssSelector, way, userID, language, reference, from, to, _callback) {
  if (!reference.current){
    return
  }

  this.calEvents.date_range.date_start = (typeof from == 'string')?from:moment().subtract(150, 'days').format('YYYY-MM-DD');
  this.calEvents.date_range.date_end = (typeof to == 'string')?to:moment().add(150, 'days').format('YYYY-MM-DD');

  this.calEvents.cssSelector = cssSelector;
  if (language != undefined) {
    this.calEvents.language = language;
  }

  const params = {
    "start": this.calEvents.date_range.date_start,
    "end": this.calEvents.date_range.date_end,
    "prefix": "calendar",
    "userID": way ? userID : '' // TODO vymyslet to jinak, bez pouziti tohoto parametru a hlavně ten bazmek na BE
  };

  this.calGetInitData(params, (self, force) => {
    displayCalContainer(self, way, userID, force, (data2) => {
      if (_callback) _callback(data2)
    });
  });
}

export function displayCalContainer(self, way, userID, forced, _callback) {
  self.calGetEvents(forced, way, userID, (data) => {
    if (_callback) {
      _callback(data);
    } else {
      this.calPlanReload()
    }
  });
}
