
import $ from "jquery";
import detailDefault from "../../detailDefault";
export default class detailMeetings extends detailDefault {
  load(sAction, data) {
      this.setOrderLinesFilter(sAction, data);
    var prefixInv = data.prefix + "/customData/invitees";
    if(data.view !== "calendar"){
        sAction.dsClear(); // b4832b17-b943-bb1d-b9eb-5ea8a171b2a5
        sAction.dsAdd('set', data.prefix + '/fields/status/def/readonly', false);
        sAction.dsProcess();
      }

      if (data.view == "calendar" || data.view == "home") {
        prefixInv = data.prefix.replace("/detailView", "");
        prefixInv += "/invitees";
      }

      /*
      if(data.view == "calendar"){
        prefixInv = "calendar/invitees"
      }
      */
      this.searchInvitees(sAction, data.prefix, prefixInv);

      if (data.view != "calendar" && data.view != "home") {
        const id = sAction.dataGet(data.prefix + "/id");
        if (!id) {
          const date = new Date();
          const year = date.getFullYear();
          var month = date.getMonth() + 1;
          if (month < 10) {
            month = "0" + month
          }
          var day = date.getDate();
          if (day < 10) {
            day = "0" + day;
          }
          var hours = date.getHours();
          if (hours < 10) {
            hours = "0" + hours;
          }
          var minutes = date.getMinutes();
          if (minutes < 10) {
            minutes = "0" + minutes
          }
          const today = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":00";
          sAction.dsClear();
          sAction.dsAdd("set", data.prefix + "/fields/date_start/value", today)
          sAction.dsAdd("set", data.prefix + "/changes/fields/date_start", today)
          sAction.dsAdd("set", data.prefix + "/fields/duration_hours/value", 0)
          sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/minutesValue", 15)
          sAction.dsAdd("set", data.prefix + "/changes/fields/duration_hours", 0)
          sAction.dsAdd("set", data.prefix + "/changes/fields/duration_minutes", 15)
          sAction.dsProcess();
        }
      }
      this.locationLink(sAction, data.prefix);

      this.setDescriptionRequired(sAction, data);
    }

  update(sAction, data) {
    if (data.field == "parent_id") {
      var prefixInv = data.prefix + "/customData/invitees";
      if (data.view == "calendar" || data.view == "home") {
        var prefixInv = data.prefix.replace("/detailView", "").replace("/meetingView", "");
        prefixInv += "/invitees";
      }
      else if (data.view != "detail") {
        return;
      }
      this.searchInvitees(sAction, data.prefix, prefixInv);
      //checkni prez store(spesl funkce)
    } else if (data.field === 'acm_orders_id') {
        this.setOrderLinesFilter(sAction, data);
    } else if (data.field === 'status') {
      if (data.value === 'Held') {
        if (sAction.dataGet(data.prefix + '/fields/description/def/zamerovac')) {
          sAction.dsClear();
          sAction.dsAdd('set', data.prefix + '/fields/description/def/required', true);
          sAction.dsProcess();
        }
      } else {
        sAction.dsClear();
        sAction.dsAdd('set', data.prefix + '/fields/description/def/required', false);
        sAction.dsProcess();
      }
    }
    this.locationLink(sAction, data.prefix);
  }

  detailViewLoad(sAction, data) {
    console.log("VIEW LOAD", data);
  }
  detailViewUpdate(sAction, data) {
    console.log("update Detail");
  }

  locationLink(sAction, prefix) {

    const location = sAction.dataGet(prefix + "/fields/location");
    const value = location.value;
    if (value != null) {
      if (value && (value.startsWith("http") || value.startsWith("www"))) {
        sAction.dataSet(prefix + "/fields/location/def/type", "url")
      }
    }
  }
  searchInvitees(sAction, prefix, prefixInv) {
    // console.log("PARENT", prefix);
    const parent = sAction.dataGet(prefix + "/fields/parent_name");
    if (parent.def.get("parent_type_value") == "Accounts" && parent.def.get("id_value") != "") {
      sAction.searchInvitees({ parent: parent.toJS() }, prefixInv);
    }
  }

  setDescriptionRequired(sAction, data) {
    const descData = sAction.dataGet(data.prefix + '/fields/description/def/required');
    const status = sAction.dataGet(data.prefix + '/fields/status/value');
    this.descReq = false;
    if (descData === true) {
      this.descReq = true;
      if (status !== 'Held') {
        sAction.dsClear();
        sAction.dsAdd('set', data.prefix + '/fields/description/def/required', false);
        sAction.dsAdd('set', data.prefix + '/fields/description/def/zamerovac', true);
        sAction.dsProcess();
      }
    }
  }

    setOrderLinesFilter(sAction, data) {
        const subpanels = sAction.dataGet(`${data.prefix}/subpanels`);
        if (!subpanels) {
            return;
        }
        let filter;

        const orderID = sAction.dataGet(`${data.prefix}/fields/acm_orders_name/def/id_value`);

        if (!orderID) {
            filter = [];
        } else {
            filter = [{
                operandType: 'relate',
                parentId: orderID,
                value: orderID,
                module: 'acm_orders',
                relationship: ['acm_orders_acm_orders_lines'],
                name: 'account_acm_orders_acm_orders_linesacm_orders_ida',
                relName: 'acm_orders_acm_orders_lines',
                type: 'eq',
            }];
        }

        subpanels.forEach((panel, index) => {
            if (panel.module === 'acm_orders_lines') {
                sAction.dsClear();
                sAction.dsAdd('set', `${data.prefix}/subpanels/${index}/rows/filter`, filter);
                sAction.dsProcess();
            }
        });
    }
}
