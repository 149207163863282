export default function updateProductData(data, way, rate, type) {
    this.load();
    this.rest.post('getProductPrice', data, returnData => {
        let doUnload = true;
        if (returnData['status']) {
            this.dsClear();
            if (type == 'czk') {
                this.dsAdd('set', way + '/list_price', 0);
                this.dsAdd('set', way + '/czk_price', returnData['price']);
            } else {
                this.dsAdd('set', way + '/list_price', returnData['price']);
                this.dsAdd('set', way + '/czk_price', returnData['price'] * rate);
            }
            this.dsAdd('set', way + '/amount_rafter', returnData['amount_rafter']);
            this.dsAdd("set", way + '/forceUpdate', false);
            this.dsProcess();
            if (way.split('groups')[1] === '/0/lines/0') {
                let groupsWay = way.split('groups')[0] + 'groups';
                let groups = this.dataGet(groupsWay, null, true);
                this.dsClear();
                let productsToUpdate = [];
                groups.forEach ((group, groupKey) => {
                    group.lines.forEach((line, key) => {
                        let area = 0;
                        // Umytí pergoly
                        if (line.product_template_id === 'c270c064-5e77-5623-4a1f-5ece4d100a38' || line.product_template_id === 'c29ba95b-4379-ef23-483f-5ece4dac262b') {
                            area = Math.ceil(parseFloat(data['x_axis']) * parseFloat(data['y_axis']) * 2 / 1000000 * 2) / 2;
                            if (isNaN(area)) {
                                area = 0;
                            }
                            this.dsAdd("set", groupsWay + '/' + groupKey + '/lines/' + key + '/quantity', area);
                        }
                        // Sklo
                        if (line.product_template_id === '37e88357-5fd5-724d-addd-5ed9f7373f89') {
                            area = Math.ceil(parseFloat(data['x_axis']) * parseFloat(data['y_axis']) / 1000000 * 2) / 2;
                            if (isNaN(area)) {
                                area = 0;
                            }
                            this.dsAdd("set", groupsWay + '/' + groupKey + '/lines/' + key + '/quantity', area);
                        }
                        // Pro P40 systémový plech mezi pergolou a fasádou
                        if (line.product_template_id === '6a0aefa9-d8ef-02f6-9627-5ed4e447630e' && (groups[0].lines[0].product_template_id === '5491f6f1-9139-9f42-02f4-5ed4e4254f84' || groups[0].lines[0].product_template_id === '77dd4f20-968c-e152-3a9d-5ed4e4b654ee')) {
                            area = Math.ceil(parseFloat(data['y_axis']) / 1000 * 2 ) / 2;
                            if (isNaN(area)) {
                                area = 0;
                            }
                            this.dsAdd("set", groupsWay + '/' + groupKey + '/lines/' + key + '/quantity', area);
                        }
                        // Plosne nastaveni X a Y
                        // if (groupKey !== 0 || key !== 0) {
                        //     if (line.product_type === 'text' || line.product_type === 'y-only' || line.product_type === 'x-only') {
                        //         productsToUpdate.push({
                        //             type: line.product_type,
                        //             template_id: line.product_template_id,
                        //             x_axis: data['x_axis'],
                        //             y_axis: data['y_axis'],
                        //             way: groupsWay + '/' + groupKey + '/lines/' + key,
                        //             product: line,
                        //         });
                        //     }
                        // }
                    });
                });
                this.dsProcess();
                if (productsToUpdate && Array.isArray(productsToUpdate) && productsToUpdate.length) {
                    this.updateProductsPrices(productsToUpdate, way, rate, type);
                    doUnload = false;
                }
            }
            if (returnData['bound']) {
                returnData['bound'].forEach((item) => {
                    let addProduct = true;
                    let linesWay = way.split('lines')[0] + 'lines';
                    let lines = this.dataGet(linesWay, null, true);
                    lines.forEach((line, key) => {
                        if (line.product_template_id === item.id) {
                            addProduct = false;
                        }
                    });

                    item.product_template_id = item.id;
                    item.id = '';
                    if (item.currency_id !== 'b8326c50-6f19-063b-507d-5e7cc12d5619') {
                        item.czk_price = item.list_price;
                        item.list_price = 0;
                    } else {
                        item.czk_price = item.list_price * rate;
                    }
                    if (addProduct) {
                        this.updateDetailCustom("addProdLine", {way: way.split('lines')[0] + 'lines', item: item});
                    }
                });
            }
            if (way.split('groups')[1].includes('/0/lines/0')) {
                let groupsWay = way.split('groups')[0] + 'groups';
                let groups = this.dataGet(groupsWay, null, true);
                this.dsClear();
                groups.forEach ((group, groupKey) => {
                    group.lines.forEach((line, key) => {
                        if (line.product_type === 'percentMain' || (line.product_type === 'percentAbove' && groupKey == 0 && key == 1)) {
                            this.dsAdd("set", groupsWay + '/' + groupKey + '/lines/' + key + '/percents', 0);
                            this.dsAdd("set", groupsWay + '/' + groupKey + '/lines/' + key + '/percents', line.percents);
                        }
                    });
                });
                this.dsProcess();
            } else {
                let lineWay = way.split('lines/');
                lineWay = lineWay[0] + 'lines/' + (parseInt(lineWay[1].split('/')[0], 10) + 1);
                let line = this.dataGet(lineWay);
                if (line && line.id && line.product_type === 'percentAbove') {
                    this.dsClear();
                    this.dsAdd("set", lineWay + '/percents', 0);
                    this.dsAdd("set", lineWay + '/percents', line.percents);
                    this.dsProcess();
                }
            }
        }
        if (doUnload) {
            this.unLoad();
        }
    });
}