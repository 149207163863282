export default function reportSelectMainModule(module) {
  var self = this;
  this.load();
  this.rest.get("defs/" + module, data => {
    var fields = {
      fields: [],
      relate: []
    };
    data.forEachObject((field, key) => {
      if(field != null){
        if (field.type == "relate" || field.type == "link") {
          if (field.searchable == true) {
            fields.relate.push(field);
          }
        } else {
          fields.fields.push(field);
        }
      }
    });

    fields["module"] = module;
    self.dsClear();
    self.dsAdd("set", "reportWindow/metadata",{},"reportWindowMetadata");
    self.dsAdd("set", "reportWindow/module", module);
    self.dsAdd("set", "reportWindow/fields", fields.fields);
    self.dsAdd("set", "reportWindow/relate", fields.relate);
    self.dsAdd("set", "reportWindow/group", {}, "reportWindowGroup");
    self.dsAdd("set", "conf/load", false);

    data = {
      limit: 0,
      offset: 0,
      order: [
        {field:"date_entered",sort:"asc",module:module}
      ],
      filter: {}
    };
    self.dsAdd("set", "reportWindow/columns", {}, "reportWindowColumns");
    self.dsAdd("set", "reportWindow/activeRelate", [
      { main: true, module: module }
    ]);
    self.rest.post("list/" + module, data, data => {
      data.rows.forEachObject((row, key) => {
        const k = key.toLowerCase();
        var def = data.def[k];
        if (def != undefined) {
          def["width"] = row.width;
          def["default"] = row.default;
          def["show"] = true;
          def["fieldModule"] = module;

          if (row.default == true) {
            self.dsAdd(
              "add",
              "reportWindow/columns/active",
              { name: k, def: def },
              "reportWindowColumn"
            );
          }
        }
      });
     self.dsProcess();

     this.dsClear();
     this.reportWindowColumnsModuleSet(data,true,0);
    
    });
  });

  this.rest.get("search/" + module, function(data) {
    self.dataSet("reportWindow/savedSearch", data.data);
  });
}
