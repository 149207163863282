export default function sendMassEmail(params) {
  const list = this.dataGet(params.prefix);
  var selected = list.selected;
  this.load();
  this.rest.post("email/getEmailByBeanId", { bean_id: selected, module: params.module }, returnData => {
    this.unLoad();
    if (!returnData) {
      // Nepodařilo se spojit se serverem nebo získat data, opakujte prosím akci.
      this.error(this.translate("ERR_SEND_MASS_EMAIL_NO_DATA", "Emails"));
    } else if (typeof returnData !== "object" || !("emails" in returnData)) {
      // Data nebyla vrácena ve správném formátu. Výstup:
      this.error(this.translate("ERR_SEND_MASS_EMAIL_FORMAT", "Emails") + returnData);
    } else if (!returnData.emails.length) {
      // U vybraných firem nebyly nalezeny žádné e-maily.
      this.error(this.translate("ERR_SEND_MASS_EMAIL_NO_EMAILS", "Emails"));
    } else {
      if (returnData.not_found.length) {
        var links = "";

        returnData.not_found.forEach(f => {
          f = f.split("|");
          links += '<a target="_blank" href="#detail/Accounts/' + f[1] + '">' + (f[0] || "___") + "</a><br>";
        });

        this.confrim(
          // Následující firmy nemají vyplněn žádný e-mail, chcete pokračovat?
          this.translate("ERR_SEND_MASS_EMAIL_MISSING_EMAILS", "Emails") + "<br><br>" + links,
          () => {
            this.popupHide();
            this.load();
            // zeptame se znovu restu na ty chybejici emaily... je mozne, ze uz byly dovyplneny
            this.rest.post("email/getEmailByBeanId", { bean_id: returnData.not_found }, returnData2 => {
              this.unLoad();
              var data = {
                to_addrs: [...returnData.emails, ...returnData2.emails].join(", "),
                //send_individually: true,
              };

              this.openEmailPopup(data);
            });
          }
        );
      } else {
        var data = {
          to_addrs: returnData.emails.join(", "),
          //send_individually: true,
        };
        this.openEmailPopup(data);
      }
    }
  });
}
