import React, { Component } from 'react'
import PureComponent from '../../pure'

import Detail from '../../detailView/detail'
import DetailTimeline from '../../detailView/DetailTimeline'
import InviteesContainer from './inviteesContainer'
import CalDetailOrderLines from './calDetailOrderLines'

import  sAction  from "sAction";
class CalDetailContainer extends PureComponent{

    render(){
        let className = "";
        let detailClass = "";
        let invClass = "";
        //let activeTab={activeTab}

        const type = this.props.type;
        const activeModule = this.props.activeModule;
        const activeTab = this.props.activeTab;
        const detailKey = this.props.detailKey;
        const prefix = this.props.way;
        const prefixInv =this.props.prefixInv;

        const orderLines = sAction.dataGet('calendar/meetingView/orderLines');
        let orderLinesRender = [];
        let orderLinesCompletedRender = [];
        if (orderLines){
            orderLines.forEach((val) => {
                // if (val.get('isPlanned') == '1'){
                    orderLinesCompletedRender.push(
                      <CalDetailOrderLines data={val} prefix={prefix} type={type}/>
                    )
                // }
                // else{
                //     orderLinesRender.push(
                //       <CalDetailOrderLines data={val} prefix={prefix} type={type}/>
                //     )
                // }
            })
        }

        if(type == "createView"){
            if(activeModule != detailKey){
                className = "calTabNoDisplay";
            }
        }

        if(activeTab == 0){
            invClass = "calTabNoDisplay";
        }
        else if(activeTab == 1){
            detailClass = "calTabNoDisplay";
        }
        return(
            <div className={className}>
                <div className={detailClass}>
                    <Detail 
                        hideHeader={true} 
                        key={detailKey} 
                        data={this.props.data} 
                        way={prefix}
                        view="calendar"
                        orderLines = {orderLinesRender}
                        orderLinesCompleted = {orderLinesCompletedRender}
                    />
                </div>
                <div className={invClass}>
                    <InviteesContainer 
                        data={this.props.invitees.toJS()} 
                        prefix={prefixInv}
                        pomClass="calendarView" 
                    />
                </div>
            </div>
        );
    }
}
export default CalDetailContainer
