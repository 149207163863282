import $ from "jquery";
import detailDefault from "../../detailDefault";

export default class detailTasks extends detailDefault {
    load(sAction, data) {
        if (sAction.dataGet(data.prefix + '/type') === 'popup' && sAction.dataGet('view/module') === 'Accounts') {
            let fieldData = sAction.dataGet('view/fields');
            const shipping_address_street = fieldData.getIn(['shipping_address_street', "value"]);
            const shipping_address_postalcode = fieldData.getIn(['shipping_address_postalcode', "value"]);
            const shipping_address_city = fieldData.getIn(['shipping_address_city', "value"]);
            if (shipping_address_city && shipping_address_street && shipping_address_postalcode) {
                const address =  'Adresa realizace: \n' + shipping_address_street + ', ' + shipping_address_city + ' ' + shipping_address_postalcode;
                sAction.dsClear();
                sAction.dsAdd("set", data.prefix + "/fields/address/value", address);
                sAction.dsAdd("set", data.prefix + "/changes/fields/address", address);
                sAction.dsProcess();
            } else {
                const billing_address_street = fieldData.getIn(['billing_address_street', "value"]);
                const billing_address_postalcode = fieldData.getIn(['billing_address_postalcode', "value"]);
                const billing_address_city = fieldData.getIn(['billing_address_city', "value"]);
                if (billing_address_street && billing_address_city && billing_address_postalcode) {
                    const address =  'Fakturační adresa: \n' + billing_address_street + ', ' + billing_address_city + ' ' + billing_address_postalcode;
                    sAction.dsClear();
                    sAction.dsAdd("set", data.prefix + "/fields/address/value", address);
                    sAction.dsAdd("set", data.prefix + "/changes/fields/address", address);
                    sAction.dsProcess();
                }
            }
        }
    }

    update(sAction, data) {}
}
