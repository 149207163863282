export default function uploadImagesFile(file, callback){
    var formData = {
        files:file,
      };
      this.popup("fileUpload", { percent: 0, loading: true, onClose: () => {
        
      } });
      this.filesXhr({
        action: "uploadImageFile",
        data: formData,
        success: data => {
            if(data.state == true){
              this.dataSet("conf/popup/data/loading", false);
              callback(data);
            }
        },
        progress: data => {
          this.dataSet("conf/popup/data/percent",data.percent);
        }
      });
}