export default function displayQuickEdit(params){
    
  const module = params.module;
  const record = params.record;
  const prefix = params.prefix;
  const orderLines = params.orderLines;

  var self = this;

  this.rest.get("quickform/" + module + "/" + record,
      function(data) {

        const invitees = data.invitees;

        let actionButtons = [];


        var content = {
          id: record,
          module: module,
          actionButtons : actionButtons
        };

        self.detailContent(data, content);
        content.subpanels = [];

        var action = {
          type: "PAGELOADDETAIL",
          content: content,
          prefix: prefix+"/detailView",
          function: self
        };

        self.dsClear(); // nemuze byt jeden nahore a jeden dole, jinak chybuje edit popup TODO zjistit proc a opravit
        self.dsAddCustom(action);
        self.dsProcess();

        content = {
          invitees : invitees
        }

        action = {
          type : "CALENDARLOADQUICKFORM",
          content: content,
          prefix: prefix+"/invitees"
        }

        self.dsClear();
        self.dsAddCustom(action);
        self.dsProcess(); // nemuze byt jeden nahore a jeden dole, jinak chybuje edit popup TODO zjistit proc a opravit

        // on close odstraneno, protoze cistilo hlavne orderlines, ale ted se pri kazdem volani rvou nova data i kdyz prazdna,
        // stejna vec i v displayQuickEdit.js
        self.popup("calQuickEdit", {module : module, /*onClose : self.clearCalDetailStore,*/ prefix : prefix, orderLines:orderLines});

      }
    );
}

