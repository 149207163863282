import React from "react";
export default function NameCallback(props) {
  var lineRender;
  lineRender = (
    <div className="hrefEffect" onClick={() => props.callback()}>
      <a className="strong" title={props.value}>
        {props.value}
      </a>
    </div>
  );

  return (
    <div className={"newListViewLine " + props.extraClass}>{lineRender}</div>
  );
}
