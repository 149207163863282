export default function calPlanReload(target = 'calendar'){
  this.dsClear();
  //TODO nejak lepe to vymyslet, protoze to prenacita i calPageContainer
  if (target === 'calendar' || target === 'all') {
    this.dsAdd("set", 'calendar/reload', true);
  }
  if (target === 'external' || target === 'all') {
    this.dsAdd("set", 'calendar/filters/reloadExternal', true);
  }
  this.dsProcess();
}
