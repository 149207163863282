import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { parse } from 'date-fns';
import * as languages from '@mui/x-date-pickers/locales';
import * as languagesMonthsAndDays from  'date-fns/locale';

import sAction from "sAction"

export default class AcmDate extends React.Component {
    constructor(props) {
        super()
        this.state = {
            value: this.entryFormat(props.value),
            open:true,
        }
        this.input = React.createRef();
        this.dateFormat = sAction.getDateFormat("datepicker");
    }
    componentDidMount(props){
        if(this.props.autoOpen == true){
        this.input.current.click()
        }
    }
    entryFormat(date){
        if(date == undefined){
            return null
        }
        return date
    }
    onChange(date) {
        const dateObj = new Date(date)
        const month = dateObj.getMonth() < 9 ? "0" + (dateObj.getMonth() + 1) : dateObj.getMonth() + 1
        const day = dateObj.getDate() < 10 ? "0" + dateObj.getDate() : dateObj.getDate()

        const returnDate = dateObj.getFullYear() + "-" + month + "-" + day;
        this.setState({ value: date,open:false })
        if (date === null) {
            this.setState({
              value: null,
              open: false,
            });
            if (this.props.onChange !== undefined) {
              this.props.onChange(this.props.value);
            }
        } else {
            if (this.props.onChange != undefined) {
                this.props.onChange(returnDate)
            }
        }
    }
    formDate(inputDate) {
        let date, month, year;
        date = inputDate.getDate();
        month = inputDate.getMonth() + 1;
        year = inputDate.getFullYear();
        date = date.toString().padStart(2, '0');
        month = month.toString().padStart(2, '0');

        const formatedDate = `${date}.${month}.${year}`;
        if (formatedDate === '01.01.1970') {
            return parse(this.entryFormat(this.props.value), this.dateFormat, new Date());
        }
        return parse(formatedDate, this.dateFormat, new Date());
      }
    open(){
    }
    render() {
        // const dateObject = parse(this.formDate(new Date(this.state.value)), this.dateFormat, new Date());
        const currentLanguage = sAction.dataGet('conf/language');

        return (
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                localeText={languages[currentLanguage.replace('_','')].components.MuiLocalizationProvider.defaultProps.localeText}
                adapterLocale={languagesMonthsAndDays[currentLanguage.slice(0, 2)]}
                >
                <MobileDatePicker
                    className={"AcmDate " + this.props.className || null}
                    autoOk={true}
                    clearable
                    value={this.formDate(new Date(this.state.value))}
                    placeholder={this.props.placeholder ?? "10.10.2018"}
                    autoFocus={this.props.autoFocus ?? false}
                    onChange={date => this.onChange(date)}
                    onClose={() => this.props.onClose != undefined ? this.props.onClose() : {}}
                    format={this.dateFormat}
                    inputRef={this.input}
                    displayWeekNumber={true}
                    slotProps={{
                        actionBar: { actions: ["clear", "today", "cancel", "accept"] },
                    }}
                />
            </LocalizationProvider>
        )
    }
}
