export default function sendOrderToPohoda(params) {
    this.rest.post('sendToPohoda', {orderID: params.id}, returnData => {
        console.log('DANIEL', returnData);
        if (returnData && returnData.status === true && returnData.message && returnData.message.data && returnData.message.data.status === true) {
            const data = {
                header: this.translate('LBL_ORDER_SENT_TO_POHODA'),
                buttons: [{ label: "OK", callback: () => this.popupHide() }],
            };

            this.popup(this.translate('LBL_ORDER_SENT_TO_POHODA'), data);
        } else {
            this.error(this.translate(returnData.message.message));
        }
    });
}
