export default function getSavedPrintFiles(data) {
    this.load();
    this.filesXhr({
        action: "generateFilesRequest",
        data: { files: data.files },
        success: ret => {
            this.unLoad();

            if (!ret) {
                this.error(this.translate("ERR_SEND_MASS_EMAIL_NO_DATA", "Emails"));
            } else {
                this.load();
                var attachments = {};
                ret.forEach(file => {
                    attachments[file.id] = {
                        id: file.id,
                        name: file.filename,
                        size: file.size,
                        source: "cache",
                        type: (file.filetype || "pdf")
                    }

                });
                this.rest.post(
                    "email/getAccountEmail",
                    { module: data.email.module, id: data.email.id },
                    email => {
                        const templates = {
                            create_order: 'b8f380d6-0d9b-b0f2-c501-5ec7d0778597',
                        };
                        this.unLoad();

                        const emailData = {
                            parent_type: data.email.module,
                            parent_id: data.email.id,
                            parent_name: data.email.name,

                            to_addrs: email.emails || "",
                            // Issue https://gitlab.acmark.cz/coripo/plas/-/issues/115
                            cc_addrs: 'plas@plas.cz, uctarna@plas.cz',
                            ccExpanded: true,

                            attachments: attachments,

                            recordModule: data.email.module,
                            recordId: data.email.id,
                            keepSelf: true,
                            emailTemplate: templates[data.params.buttonId],
                        };

                        this.openEmailPopup(emailData);
                    }
                );
            }
        },
        error: message => {
            this.unLoad();
            this.error(this.translate(message));
        }
    });
}
