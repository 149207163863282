import React from "react"
import InfoTable from "./views/InfoTable"
export default function CustomDataTop(props){
    const data = props.data
    var component = null
    if(data.get("infoTable") != undefined){
        component = <InfoTable data={data.get("infoTable")} />
    }

    return(
        component
    )
}