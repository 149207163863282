import { Map, List } from "immutable";
import { DataConstructors } from "../../../DataConstructors";

import createListViewTree from "./createListViewTree";

export default function listCustom(state, action) {
  var allState = state;

  var listViewPrefix = ["view"];
  if (action.listViewPrefix != undefined) {
    listViewPrefix = action.listViewPrefix.split("/");
  }
  state = state.getIn(listViewPrefix);
  if (state === undefined) {
    console.log("špatný listview prefix");
    return allState;
  }
  switch (action.type) {
    case "PAGELOADLIST":
    case "RELOADLIST":
      const def = action.content.def;
      var customData = {};
      if (action.content.category == "list" || action.content.category == null) {
        
        //___________ NEW
        const rows = action.content.rows;
        var records = action.content.records;
        const columns = action.content.columns;
       
        var columnsRecord = {};
        var newData = [];

        if (columns === undefined) {
          rows.forEachObject((row, rowKey) => {
            
            columnsRecord[rowKey] = [];
            var fieldName = rowKey.toLowerCase();

            var k = rowKey.toLowerCase();
            var fieldDef = def[k];
            records.forEach((record, key) => {
              if (fieldDef !== undefined) {
                const fieldData = {
                  value: record[rowKey.toLowerCase()],
                  actEdit: false,
                };
                //___________________________ CUSTOM DATA K FIELDU
                if (fieldName == "name" || fieldName == "document_name") {
                  fieldData.id = record.id;
                  fieldData.module = record.module;
                }
                if (fieldDef.type == "currency") {
                  fieldData.currency_id = record.currency_id;
                }
                if (fieldDef.type == "parent") {
                  fieldData.id = record.parent_id;
                  fieldData.module = record.parent_type;
                }
                if(fieldDef.type == "relate"){
                  fieldData.module = fieldDef.module;
                  if(fieldDef.link){
                    fieldData.id = record[fieldDef.link+"|id"];
                  }
                  else{
                    fieldData.id = record[fieldDef.name+"|id"];
                  }
                }
                //___________________________ CUSTOM DATA K FIELDU
                columnsRecord[rowKey].push(new Map(fieldData));
              }
            });
            if (fieldDef != undefined && row.default == true) {
              fieldDef["fieldModule"] = action.page;
              newData.push(
                new DataConstructors["ListViewRow"]({
                  key: rowKey,
                  label: row.label,
                  width: row.width,
                  def: Map(fieldDef),
                  records: List(columnsRecord[rowKey]),
                  sortable:row.sortable,
                })
              );
            }
          });
        } else {
          columns.forEachObject(column => {
            const modul = column.module;
            var rowName = column.name;
            if(rowName == undefined){
              rowName = column.field
            }
            rowName = rowName.toUpperCase();
          
            var fieldName = column.name;
            if(fieldName == undefined){
              fieldName = column.field;
            }
            fieldName = fieldName.toLowerCase();

            if (column.relationship !== undefined) {
              var relPrefix = column.relationship.join("|");
              rowName = relPrefix.toUpperCase() + "|" + rowName;
              fieldName = relPrefix + "|" + fieldName;
            }
            var row = rows[rowName];
            columnsRecord[rowName] = [];
            var k = fieldName;
            var fieldDef = def[k];
            
            records.forEach((record, key) => {
              if (fieldDef != undefined) {
              const fieldData = {
                value: record[fieldName],
                actEdit: false
              };
              //___________________________ CUSTOM DATA K FIELDU
              if (fieldName == "name" || fieldName == "document_name") {
                fieldData.id = record.id;
                fieldData.module = record.module;
              }
              if (fieldName == modul + "|name") {
                fieldData.id = record[modul + "|id"];
                fieldData.module = record.module;
              }
              if (fieldDef.type == "currency") {
                fieldData.currency_id = record.currency_id;
              }
              if (fieldDef.type == "parent") {
                fieldData.id = record.parent_id;
                fieldData.module = record.parent_type;
              }
              if(fieldDef.type == "relate"){
               
                fieldData.module = fieldDef.module;
                if(fieldDef.link){
                  fieldData.id = record[fieldDef.link+"|id"];
                }
                else{
                  fieldData.id = record[fieldDef.name+"|id"];
                }
              }
              //___________________________ CUSTOM DATA K FIELDU

              columnsRecord[rowName].push(new Map(fieldData));
            }else if(row.type == "icon"){
              var fieldData = {
                module: record.module
              }
              columnsRecord[rowName].push(new Map(fieldData));
            }
            });
            
            if (fieldDef != undefined) {
              fieldDef["fieldModule"] = column.module;

              var label = row.label;
              if (label == "" || label == null) {
                label = fieldDef.vname;
              }
              newData.push(
                new DataConstructors["ListViewRow"]({
                  key: rowName,
                  label: label,
                  width: column.width,
                  def: Map(fieldDef),
                  records: List(columnsRecord[rowName]),
                  sortable:row.sortable,
                })
              );
            }else if(row.type == "icon"){
              newData.push(
                new DataConstructors["ListViewRow"]({
                  key: rowName,
                  label: row.vname,
                  width: row.width,
                  def: Map({fieldModule:modul,type:"icon",name:"module"}),
                  records: List(columnsRecord[rowName]),
                  sortable: row.sortable,
                })
              );
            }
          });
        }

        var type = "";
        if (state != null) {
          type = state.type;
        }

        var listRecords = [];
        records.forEach(function(record) {
          if(type == "subpanel"){
            listRecords.push({record:record.id,module:record.module});
          }else{
            listRecords.push(record.id);
          }
        });

        var origFilter = new List();
        if (action.filter != undefined) {
          origFilter = action.filter;
        }

        var actFiltering = false;
        if (action.actFiltering != undefined) {
          actFiltering = action.actFiltering;
        }

        var selectedActive = true;
        if (action.selectedActive != undefined) {
          selectedActive = action.selectedActive;
        }
      } else if (action.content.category == "customReport") {
        customData["reportId"] = action.content.reportId
      }

      //_____________________________________________________ MENU BUTTONS
      var listToRender = [];
      if (action.buttons != undefined) {
        action.buttons.forEach(button => {
          if (button.params != undefined) {
            var paramsList = [];
            button.params.forEach(param => {
              paramsList.push(new Map(param));
            });
            button.params = List(paramsList);
          }

          const b = {
            id: button.id,
            def: new Map(button)
          };
          listToRender.push(new DataConstructors["DetailButton"](b));
        });
        listToRender = List(listToRender);
      } else {
        listToRender = state.getIn(["buttons"]);
      }

      //_____________________________________________________ MENU BUTTONS END
      var defaultFilter = {};
      // ______________________________________ LISTVIEW TREE
      if (action.content.listViewTree != null) {
        var tree = createListViewTree(action.content.listViewTree.tree, true);
        var treeData = {
          module: action.content.listViewTree.module,
          name: action.content.listViewTree.name,
          field: action.content.listViewTree.field,
          relationship: action.content.listViewTree.relationship,
          tree: List([tree])
        };
        if (state !== null) {
          treeData["activeCategory"] = state.getIn([
            "listViewTree",
            "activeCategory"
          ]);
        }
        defaultFilter.tree = {
          operandType:"relate",
          type:"contArray",
          module: action.content.listViewTree.module,
          name: action.content.listViewTree.name,
          field: action.content.listViewTree.field,
          relationship: action.content.listViewTree.relationship,
        }
        
      }
      // ______________________________________ LISTVIEW TREE

      // ______________________________________ COLORS
      if (
        action.content.colors !== undefined &&
        action.content.colors !== null
      ) {
        var listColors = {};
        action.content.colors.forEachObject((colors, key) => {
          listColors[key] = new Map(colors);
        });
      }
      // ______________________________________ COLORS END

      var origSelected = List();
      if (action.selected != undefined) {
        origSelected = action.selected;
      }

      var actPage = 1;
      if (action.actPagination != undefined) {
        actPage = action.actPagination;
      }
      var origFieldFunction = new Map();
      if (action.fieldFunction != undefined) {
        origFieldFunction = action.fieldFunction;
      }

      
      if(action.defaultFilter != undefined){
        defaultFilter = action.defaultFilter;
      }else{
        defaultFilter = new Map(defaultFilter);
      }

      var savedSearch = [];
      action.content.savedSearch.forEach(search => {
        savedSearch.push(new Map(search));
      });

      var orderBy = "date_entered";
      var asc = 1;
      if (state != null) {
        orderBy = state.orderBy;
        asc = state.asc;
      } 

      state = new DataConstructors["ListView"]({
        modul: action.page,
        rows: List(newData),
        records: List(listRecords),
        orderBy: orderBy,
        asc: asc,
        filter: origFilter,
        defaultFilter: defaultFilter,
        actFiltering: actFiltering,
        fieldFunction: new Map({
          parameters: origFieldFunction
        }),
        colors: new Map(listColors),
        listViewTree: new DataConstructors.listViewTree(treeData),
        buttons: listToRender,
        selected: origSelected,
        selectedActive: selectedActive,
        offset: action.content.offset,
        limit: action.content.limit,
        rowCount: action.content.rowCount,
        page: actPage,
        type: type,
        savedSearch: List(savedSearch),
        actSavedSearch: action.savedSearch,
        prefix: action.content.prefix,
        category: action.content.category,
        customData:new Map(customData),
      });
      return allState.setIn(listViewPrefix, state);

    case "SETORDER":
      var order = action.order;
      var asc = action.asc;

      if (order == null) {
        order = "date_entered";
      }
      if (asc == null) {
        asc = 1;
      }

      state = state.setIn(["orderBy"], order).setIn(["asc"], asc);

      return allState.setIn(listViewPrefix, state);
    case "SETFILTER":
      var filter = action.filter;
      if (filter != null) {
        var filterList = [];
        filter.operands.forEach(el => {
          var filter = {};
          if (el.field != undefined) {
            filterList.push(new DataConstructors["filterParameter"](el));
          }
        });

        const group = new DataConstructors["filterGroup"]({
          field: filter.field,
          operator: filter.operator,
          filters: List(filterList)
        });

        const list = List([group]);
        state = state.setIn(["filter"], list);
      } else {
        state = state.setIn(["filter"], List());
      }

      return allState.setIn(listViewPrefix, state);
    case "ADDTOFILTER":
      //console.log(state.filter);
      var newFilter = action.filter;
      //  console.log(newFilter);
      var newState = state;
      var add = true;
      state.filter.forEach((filterGroup, index) => {
        if (filterGroup.field != undefined) {
          if (filterGroup.field == newFilter.field) {
            add = false;

            var filters = new Array();
            newFilter.filters.forEach((filter, key) => {
              filters.push(new DataConstructors["filterParameter"](filter));
            });
            newState = newState.setIn(
              ["filter", index, "filters"],
              List(filters)
            );
            newState = newState.setIn(
              ["filter", index, "operator"],
              newFilter.operator
            );
          }
        }
      });
      if (add == true) {
        var filters = new Array();
        newFilter.filters.forEach((filter, key) => {
          filters.push(new DataConstructors["filterParameter"](filter));
        });

        var list = newState.filter.push(
          new DataConstructors["filterGroup"]({
            field: newFilter.field,
            operator: newFilter.operator,
            filters: List(filters)
          })
        );
        newState = newState.setIn(["filter"], list);
      }
      return allState.setIn(listViewPrefix, newState);
    case "REMOVEFROMFILTER":
      var field = action.filter.field;
      filter = state.getIn(["filter"]);
      list = [];
      filter.forEach(row => {
        if (row.field != field) {
          list.push(row);
        }
      });
      state = state.setIn(["filter"], List(list));
      return allState.setIn(listViewPrefix, state);

    case "ACTIVATEFILTERING":
      state = state.setIn(["actFiltering"], true);
      return allState.setIn(listViewPrefix, state);

    case "OPENFILTER":
      newFilter = action.filter;

      if (newFilter == state.getIn(["openFilter"])) {
        state = state.setIn(["openFilter"], null);
      } else {
        state = state.setIn(["openFilter"], newFilter);
      }

      return allState.setIn(listViewPrefix, state);
    case "TOGGLELINE":
      var stav = action.state;
      var id = action.id;

      if (stav) {
        var list = state.selected.push(id);

        state = state.setIn(["selected"], list);
      } else {
        var list = state.selected;
        list = list.filter(item => {
          return item != id;
        });
        state = state.setIn(["selected"], list);
      }
      return allState.setIn(listViewPrefix, state);
    case "TOGGLEALL":
      var stav = action.state;
      var id = action.id;
      var selectedList = [];
      if (stav) {
        var selectedList = [];
        state.records.forEach(record => {
          var find = undefined;
          if (state.selected.size != 0) {
            find = state.selected.filter(id => {
              return id === record;
            });
          }
          if (find == undefined || find.size == 0) {
            selectedList.push(record);
          }
        });

        state = state.setIn(
          ["selected"],
          state.selected.concat(List(selectedList))
        );
      } else {
        var list = state.selected;
        state.records.forEach(record => {
          list = list.filter(item => {
            return item != record;
          });
        });
        state = state.setIn(["selected"], list);
      }

      return allState.setIn(listViewPrefix, state);

    case "ROUTEGLOBALSEARCH":
      console.log("ROUTEGLOB", action.content);
      var searchData = {
        lists: new List(action.content.lists)
      };

      /*action.content.lists.forEach(list => {
        list.push
      });
      */

      return allState.setIn(["globalSearch"], new Map(searchData));
    case "GSRESULTSALLOCATION":
      const prefix = action.prefix.split("/");
      var lists = [];
      for (let i = 0; i < action.content.length; i++) {
        lists.push(new DataConstructors["ListView"]());
      }
      return allState.setIn(
        prefix,
        new DataConstructors["globalSearch"]({
          searchString: action.content.params.str,
          results: List(lists)
        })
      );
    case "SWEETSPOTSEARCH":
      let prefixSweet = action.prefix.split("/");
      let dataState = allState.getIn(prefixSweet);
      dataState = dataState.setIn(["data"], action.content.data.data);
      dataState = dataState.setIn(["length"], action.content.data.length);
      return allState.setIn(prefixSweet, dataState);
    case "MASSUPDATEFIELDS":
      const muPrefix = action.prefix.split("/");
      const data = action.content;
      var fields = {};

      const banned = [
        "date_modified",
        "date_entered",
        "created_by",
        "modified_user_id",
        "deleted",
        "modified_by_name"
      ];
      //account_name
      const allowedTypes = [
        "relate",
        "parent",
        "int",
        "contact_id",
        "assigned_user_name",
        "account_id",
        "account_name",
        "bool",
        "enum",
        "multienum",
        "radioenum",
        "datetimecombo",
        "datetime",
        "date"
      ];
      var procVals = [];

      data.fields.forEachObject(field => {
        if (banned.includes(field.name)) {
          return;
        }
        if (
          field.massupdate !== undefined &&
          (field.massupdate !== true && field.massupdate !== "1")
        ) {
          return;
        }
        if (!allowedTypes.includes(field.type)) {
          return;
        }

        if (field.type == "int" && field.massupdate == undefined) {
          return;
        }

        if (field.type == "account_name") {
          field.type = "relate";
          field.id_name = "account_name|" + field.name;
          field.module = "Accounts";
        }

        let fName = field.name;
        if (field.type === "relate") {
          if (field.module == undefined) {
            return;
          }
          fName = field.id_name;
        }
        if (procVals.includes(fName)) {
          return;
        }
        procVals.push(fName);

        const event = new DataConstructors["Event"]({
          key: "onClick",
          functionName: "fieldToEdit"
        });

        var newField = new DataConstructors["Field"]({
          name: field.name,
          value: "",
          //tab: tabs.length,
          //row: tabRows.length,
          def: Map(field),
          events: List([event])
        });

        fields[field.name] = newField;
      });
      var muData = new DataConstructors["massUpdateData"]({
        fields: new Map(fields),
        selected: data.selected,
        module: data.module,
        prefix: action.prefix
      });
      return allState.setIn(muPrefix, muData);
    default:
      return allState.set(listViewPrefix, state);
  }
}
