export default function checkNewNotifications(){
    this.notificationInterval = setInterval(() => {
        this.rest.get("getNotifyCount",data => {
            var count = this.dataGet("notifications/notice");
            if(count != data){
            const number = parseInt(data)
            this.dataSet("notifications/notice",number);
            
            var toastLangMap = {
                1: this.translate("LBL_NOTIFY_TOAST"),
                2: this.translate("LBL_NOTIFY_TOAST"),
                3: this.translate("LBL_NOTIFY_TOAST"),
                4: this.translate("LBL_NOTIFY_TOAST"),
                5: this.translate("LBL_NOTIFY_TOAST_MORE"),
            }
            var desc = toastLangMap[number];
            if(desc == undefined){
                desc = toastLangMap[5];
            }
            desc = desc.replace("{number}",number);

            this.toast({
                name:"Nová upozornění",
                description:desc,
                sAction: "openNotBase",
            })
            }
        })
    },15000);
}