export default function mergeInit(params){

    const list = this.dataGet(params.prefix);
    const selected = list.selected.toJS();

    const param= {
        records: selected,
        module: params.module,
    }

    window.location.hash = "merge";
    this.rest.post("mergeInit",param,data => {
        this.dsClear();
        this.dsAdd("set","conf/load",false);
        this.dsAdd("set",params.prefix,{},"mergeRecords");

        data.def.forEachObject((def,key) => {
            this.dsAdd("set",params.prefix+"/def/"+key,def);
        });
        var index = 0;
        data.records.forEachObject(record => {
            this.dsAdd("set",params.prefix+"/records/"+index,{});
            record.forEachObject((value,field) => {
                this.dsAdd("set",params.prefix+"/records/"+index+"/"+field,value);
            });
            index += 1;
        })
        this.dsAdd("set",params.prefix+"/module",params.module);
        this.dsProcess();
    });
}