export default function updateProductsPrices(data, way, rate, type) {
    this.load();
    this.rest.post('getProductsPrices', data, returnData => {
        if (returnData['status']) {
            this.dsClear();
            returnData.products.forEach((product, key) => {
                let line = product.data;
                if ((line.type === 'text' || line.type === 'x-only') && (line.product.inherit === 'both' || line.product.inherit === 'x-only')) {
                this.dsAdd("set", line.way + '/x_axis', line['x_axis']);
                }

                if ((line.type === 'text' || line.type === 'y-only') && (line.product.inherit === 'both' || line.product.inherit === 'y-only')) {
                    this.dsAdd("set", line.way + '/y_axis', line['y_axis']);
                }

                if (
                    (line.type === 'text' && line.product.inherit === 'both') ||
                    (line.type === 'x-only' && (line.product.inherit === 'x-only' || line.product.inherit === 'both')) ||
                    (line.type === 'y-only' && (line.product.inherit === 'y-only' || line.product.inherit === 'both'))
                ) {
                    if (line.product.currency_id !== 'b8326c50-6f19-063b-507d-5e7cc12d5619') {
                        this.dsAdd('set', line.way + '/list_price', 0);
                        this.dsAdd('set', line.way + '/czk_price', product['price']);
                    } else {
                        this.dsAdd('set', line.way + '/list_price', product['price']);
                        this.dsAdd('set', line.way + '/czk_price', product['price'] * rate);
                    }
                    this.dsAdd('set', way + '/amount_rafter', returnData['amount_rafter']);
                }
            });
            this.dsProcess();
        }
        this.unLoad();
    });
}