import sAction from "../index";

export default function deleteRecord(paramData){
    var self = this;
    const module = paramData.module;

    // promazani zmen v local storage
    sAction.removeChangesFromLS(module,sAction.dataGet('conf/user/id'))

    this.dsClear();
    this.dsAdd("set","conf/load",true);
    this.dsAdd("set","conf/popup/show",false);
    this.dsProcess();

    this.rest.delete("delete/"+module+"/"+paramData.id,null,() => {
        self.dataSet(paramData.prefix+"/changes", {fields: [], files: []})
        window.location.href = "#list/"+module;
    });
}
