import React, { Component } from 'react'
import PureComponent from '../../pure'
import  sAction  from "sAction";
import TickBox from "../../formElements/TickBox";

export default class CalDetailOrderLines extends PureComponent{
    constructor(props){
        super(props);
        this.state = {
            inUse:false,
        };
    }

    handleRowSelect = (value, data) => {
        this.setState({inUse: value})
        sAction.dsClear();
            sAction.dsAdd("set",this.props.prefix + "/customData/selectedOrderLines/"+data.get('olID'),value)
        sAction.dsProcess();
    }
    render(){
      const data = this.props.data;
      let tickDiv =  ''
      let statusDiv =
        <div className={"fc-popup-table-cell "} style={{"textAlign":"center"}}>
          <div className={'fc-popup-table-status'} style={{'backgroundColor':data.get('olStavColor')}}>
            {data.get('olStav')}
          </div>
        </div>
      let meetDay = (data.get('meetDay') && data.get('meetDay') != '01.01.1970')?'('+data.get('meetDay')+') ' :'';
      let meetDesc = data.get('meetDesc')
        ?data.get('meetDesc')
        :sAction.translate("LBL_MEETING_REASON_NOT_DISCLOSED", "Calendar");
      let nameDiv = <div className={"fc-popup-table-cell"}> {meetDay}{data.get('olName')} {data.get('meetDesc')
        ? <a target={"_blank"} href={'#detail/Meetings/'+data.get("relToMeet")}> - {meetDesc}</a>
        :''}</div>
      if (this.props.type !== 'createView'){
        tickDiv = ''
      }
      // else if (data.get("isPlanned") !== '0'){
      //   tickDiv = <div className={"fc-popup-table-cell"} style={{"textAlign":"center"}}>
      //     <a target={"_blank"} href={'#detail/Meetings/'+data.get("relToMeet")}>
      //       <div className="icon-fc-calPopup calActMenuIcon icon-arrowRight"> </div>
      //     </a>
      //   </div>
      // }
      else{
        tickDiv = <div className={"fc-popup-table-cell"} style={{"textAlign":"center"}}>
          <TickBox
            className="calFiltersStateTick"
            checked={this.state.inUse}
            onChange={(value) => this.handleRowSelect(value, data)}
          />
        </div>
      }

      return(
        <>
          <div className={"fc-popup-table-row"} data-id={data.get('olID')}>
            {tickDiv}
            {statusDiv}
            {nameDiv}
          </div>
        </>
      )
    }
}
