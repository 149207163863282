import React from 'react';
import PureComponent from '../pure'

import  sAction  from "sAction";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin  from "@fullcalendar/list";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import moment from "moment";
import AcmDate from "../formElements/AcmDate";
import classActType from "../../action/calendar/design/calGetClassName";
import {calClassActType, fromJSON} from "../../action/exportConfig";


/**
 * Uitečné podpůrné funkce pro práci s kalendářem:
 * sAction.clearCalDetailStore() - procisti data co se zobrazuji v popupu
 * sAction.calPlanReload('all') - donuti prenacist kalendar / externi eventy / vse ('all' / 'calendar' / 'external')
 */

/**
 * TODO - optimalizace:
 * stahovat nastaveni kalendare uz drive (calInit)
 * omezit pocet renderu - set state v renderu
 *
 * */

class CalBody extends PureComponent{
  constructor(props){
    super(props);
    this['calRef'+[props.userID]] = React.createRef();

    this.state = {
      calViewType : this.props.data.calViewType??'dayGridMonth', //this.props.data.calViewType
      rerender : true,
      openPicker : false,
      userID : props.userID,
      calRef : 'calRef'+[props.userID],
      prefix : 'calendar',
      calendarEvents: [],
      events: [],
      ready: false,
    };

    this.getMonthsBefore = 0
    this.getMonthsAfter = 0
    this.getDaysBefore = 40
    this.getDaysAfter = 40

    this.updateTimeout = null
  }

  // calendarRef = React.createRef();
  componentDidMount() {

    const calendarApi = this[this.state.calRef].current.getApi()
    const currDate = calendarApi.getDate()
    const from = moment(currDate).subtract(this.getDaysBefore, 'days').format('YYYY-MM-DD')
    const to = moment(currDate).add(this.getDaysAfter, 'days').format('YYYY-MM-DD')

    sAction.calInit(this.props.cssSelector,this.props.way,this.state.userID, this.props.language, this['calRef'+[this.props.userID]], from, to, (data) => {
      // this.props.way existuje u widgetu ... az tady v callbacku, jinak tu neni vse potrebne a nefunguji tlacitka prepinani pohledu
      let view = this.props.way?sAction.dataGet(this.props.way+'/data/options/initView'):this.props.data.setting.get('initView')

      this.setState({
        calendarEvents: data,
        ready: true,
        loadedDatesInit: {
          from: from,
          to: to
        },
        loadedDates: {
          from: from,
          to: to
        },
      }) // kvuli widgetum
      this.changeView(view) // Do not judge me

      sAction.unLoad() // teprve nyni je vse nacteno podle nastaveni uzivatele
    });
  }

  calendarUpdateEvents(from, to) { // jede pres API, takze nevynucuje prerenderovani
    from = moment(from).subtract(this.getDaysBefore, 'days').format('YYYY-MM-DD')
    to = moment(to).add(this.getDaysAfter, 'days').format('YYYY-MM-DD')
    setTimeout(() => {
      sAction.calInit(this.props.cssSelector, this.props.way, this.props.userID, this.props.language, this['calRef'+[this.props.userID]], from, to, (data) => {
        const calendarApi = this[this.state.calRef].current.getApi()
        // calendarApi.removeAllEvents()

        // pridat jenom nove eventy co jesete nejsou nactene a nemazat cely kalendar
        data.forEachObject(val => {
          setTimeout(() => {
            if (!calendarApi.getEventById(val.id)) {
              calendarApi.addEvent(val)
            }
          }, 0)
        })

        sAction.unLoad() // teprve nyni je vse nacteno podle nastaveni uzivatele
        setTimeout(() => {
          this.setState({
            loaded: true,
            loadedDates: {
              from: moment(from) > moment(this.state.loadedDates.from) ? from : this.state.loadedDatesInit.from,
              to: moment(to) < moment(this.state.loadedDates.to) ? to : this.state.loadedDatesInit.to
            }
          })
        }, 250)
      })
    }, 0)
    // sAction.calInit(this.props.cssSelector, this.props.way, this.props.userID, this.props.language, this['calRef'+[this.props.userID]], from, to, (data) => {
    //
    //   const calendarApi = this[this.state.calRef].current.getApi()
    //   calendarApi.removeAllEvents()
    //   data.forEachObject(val => {
    //     calendarApi.addEvent(val)
    //   })
    //   sAction.unLoad() // teprve nyni je vse nacteno podle nastaveni uzivatele
    // })
  }

  // TODO: pouzit neco na tento zpusob misto volani call initu v renderu
  //  Pozor na sdileny kalendar a aktualizovani sdilenych dat mezi kalendari
  //  ... GL HF
  // componentDidUpdate(prevProps, prevState) {
  //   let reload = sAction.dataGet('calendar/reload')
  //   console.log('---calBody of userID '+this.state.userID+'----componentDidUpdate()', reload) // TODO odmazat
  //   if (reload || this.state.rerender) {
  //     sAction.load()
  //     sAction.calInit(this.props.cssSelector,this.props.way,this.props.userID, this.props.language, this['calRef'+[this.props.user]], (data) => {
  //       this.setState({calendarEvents: data, rerender: false})
  //       sAction.unLoad() // teprve nyni je vse nacteno podle nastaveni uzivatele
  //     });
  //     if (this.state.rerender){
  //       this.setState({rerender: false})
  //     }
  //
  //     sAction.dataSet('calendar/reload', false)
  //     this.setExternalEvents()
  //   }
  // }

  render() {
    let self = this;
    let reload = sAction.dataGet('calendar/reload')
    const calendarApi = this[this.state.calRef]?.current?.getApi()

    if (reload && calendarApi) {
      calendarApi.removeAllEvents()
    }
    if (reload || this.state.rerender) {
      sAction.load()

      let from = null;
      let to = null;

      if (calendarApi){
        const currDate = calendarApi.getDate()
        from = moment(currDate).add(-1 * this.getMonthsBefore, 'months').add(-1 * this.getDaysBefore, 'days').format('YYYY-MM-DD')
        to = moment(currDate).add(this.getMonthsAfter, 'months').endOf('month').add(this.getDaysAfter, 'days').format('YYYY-MM-DD')
      }
      sAction.calInit(this.props.cssSelector,this.props.way,this.props.userID, this.props.language, this['calRef'+[this.props.userID]],from, to, (data) => {
        this.setState({calendarEvents: data, rerender: false})
        sAction.unLoad() // teprve nyni je vse nacteno podle nastaveni uzivatele
      });
      if (this.state.rerender){
        this.setState({rerender: false})
      }

      this.setExternalEvents()
    }
    const userDateFormat = sAction.getDateFormat("moment");

    let weeDateFormat = "ddd D.M";

    switch (userDateFormat){
      case "Y-M-D":
      case "M-D-Y":
        weeDateFormat = "ddd M-D";
        break;
      case "D-M-Y":
        weeDateFormat = "ddd D-M";
        break;
      case "Y/M/D":
      case "M/D/Y":
        weeDateFormat = "ddd M/D";
        break;
      case "D/M/Y":
        weeDateFormat = "ddd D/M";
        break;
      case "Y.M.D":
      case "M.D.Y":
        weeDateFormat = "ddd M.D.";
        break;
      case "D.M.Y":
        weeDateFormat = "ddd D.M.";
        break;
    }



    if(this?.props?.data?.calViewType){
      this.changeView(this.props?.data.calViewType)
    }
    var picker = <div className={'hidden'} id={'clickMeIfYouDare'+this.state.userID}><AcmDate
      autoOpen={false}
      value={new Date()}
      onChange={(d) => this.DatePickerHandle(d)}
    /></div>

    // let height = this.props.viewsNumber?'calc((95vh - 150px) / ' + this.props.viewsNumber+')':''; // pro alternativni zobrazeni sdileneho kalendare
    let height = 'auto';
    let overflow = 'auto';
    if (this.props.viewsNumber == 1){
      height = this.props.viewsNumber?'calc((100vh - 130px) / ' + this.props.viewsNumber+')':'';
      overflow = 'unset';
    }

    let userName = this.props.userName
    return(
      <div className={'calBodyContainer'} style={{ height: height, overflow:overflow}}/* kvuli sdilenemu pohledu*/>
        {(userName && this.props.sharedView) && (
          <div className={'fcCalendarTitle'}>{userName}</div>
        )}
      <div id={this.props.cssSelector}
           className={" calBoard" + this.props.classBoard + " " + this.props.cssSelector }
           style={{overflow:'auto' }}
      >
        {picker}
        <FullCalendar
          ref={this[this.state.calRef]}
          locale="cs"
          initialView={this.props?.data?.settings?.get('initView')??'dayGridMonth'}

          headerToolbar={{
            left: 'today',
            center: 'prev,title,next',
            right: 'datepicker',
          }}
          customButtons = {{
            datepicker: {
              text: 'Datum',
              click: function() {
                self.pickADate()
              }
            },
            userName: {
              text: sAction.dataGet('calendar/settings/users/'+this.state.userID),
            }
            //...other buttons
          }}

          buttonText={{
            today : 'Dnes',
          }}
          buttonIcons={{
            datepicker : 'i icon-calendar-month calHeaderIcon',
          }}
          views= {{
            dayGridMonth: {
              displayEventTime: false,
              columnHeaderFormat: 'dddd',
            },
            month: { // kvuli starym instancim
              type: 'dayGridMonth',
              displayEventTime: false,
              columnHeaderFormat: 'dddd',
            },
            timeGridWeek: {
              columnFormat: weeDateFormat,
              slotDuration: "00:15:00",
              slotLabelInterval: "01:00",
              displayEventTime: true,

              // TODO jak upravit a pak i pro den... v locale to vyplnene je
              // weekNumberFormat: { week:"short"},
              // weekText: "T"
            },
            agendaWeek:{ // kvuli starym instancim
              type: 'timeGridWeek',
              columnFormat: weeDateFormat,
              slotDuration: "00:15:00",
              slotLabelInterval: "01:00",
              displayEventTime: true,
            },
            timeGridDay: {
              // columnFormat: weeDateFormat,
              // titleFormat: "D.MMMM YYYY",
              slotDuration: "00:15:00",
              slotLabelInterval: "01:00",
            },
            agendaDay:{ // kvuli starym instancim
              type: 'timeGridDay',
              slotDuration: "00:15:00",
              slotLabelInterval: "01:00",
            },
            listACM: { // je potreba list plugin
              displayEventTime: true,
              type: 'listMonth',
              duration: {days: 31},
              buttonText: 'Agenda'
            },
          }}

          editable={true}
          allDaySlot={false}
          eventStartEditable={true}
          eventDurationEditable={true}
          displayEventTime={false}
          nowIndicator={true}
          eventTextColor={'#000000'}
          eventBorderColor={'#555555'}
          timeFormat={'H:mm'}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: true,
            // meridiem: 'short' // am / pm
          }}
          minTime={"00:00:00"}
          maxTime={"24:00:00"}
          weekNumberFormat= {{ week:"numeric" }}

          plugins={[dayGridPlugin,timeGridPlugin, interactionPlugin,listPlugin ]}

          events={this.state.calendarEvents??''}


          dateClick={this.handleDayClick}
          eventDidMount = {this.handleEventDidMount}
          eventClick = {this.handleEventClick}
          eventResize = {this.handleEventUpdate}// aktualizace pri roztahnuti eventu po kalendari
          eventDrop = {this.handleEventUpdate} // aktualizace pri presunu eventu po kalendari
          eventReceive = {this.handleEventReceive} // pokud pritahnu novy externi event
          eventAdd = {this.handleEventAdd} // pokud pritahnu novy externi event
          datesSet={this.handleDatesSet} // pokud pritahnu novy externi event
        />
      </div>
      </div>
    )
  }

  DatePickerHandle(date){
    let calendarApi = this[this.state.calRef].current.getApi()
    calendarApi.gotoDate( moment(date).format('YYYY-MM-DD'))
  }

  pickADate = (arg) =>{
    // TODO tohle taky neni zrovna fajn reseni
    document.getElementById("clickMeIfYouDare"+this.state.userID).querySelector('.AcmDate').click()
  }

  handleEventAdd = (arg) => {
    this.handleDayClick();
  }

  handleEventReceive = (arg) => {
    arg.event.remove() // aby v kalendari nezustal vyset jinak nastylovany event, dokud ho nepotvrdi nebo i potom

    this.setEventFieldsBeforeReceive(arg.event, this.state,(setData) => {
      sAction.popup("calQuickCreate", {onClose : sAction.clearCalDetailStore, prefix : this.state.prefix/*, event : arg.event._def*/});
    })
  }

  setEventFieldsBeforeReceive = (event,state, cb) => {
    let calendarApi = this[this.state.calRef].current.getApi()
    let eventStart = '';
    const defAssemplyCrew = "^ba57a0e0-a71b-6145-b16d-62276218c9a5^,^925f5b46-1970-123b-da4a-5eff1562d487^,^6ca9ccd2-4f51-77b5-27ed-622761a0441f^";

    if(sAction.dataGet('calendar/calViewType') === "dayGridMonth"
      || //TODO zjistit, jestli by nestacilo jen jedno z toho, nebo neco jineho podle ceho to poznavat
      calendarApi.currentDataManager.state.currentViewType === "dayGridMonth"){
      eventStart = moment(event.start).format('YYYY-MM-DD');
      eventStart = eventStart + ' ' + moment().format('HH:mm:ss') // aby jsme v mesicnim pohledu nevytvareli eventy na pulnoc
    }
    else{
      eventStart = moment(event.start).format('YYYY-MM-DD HH:mm:ss');
    }

    let meetingType = 'montaz'
    let durationHours = '1'
    let durationMinutes = '0'

    // neodradkovavat, pridalo by to tam \n
    let address = ''
    address += event.extendedProps.address.street??''
    address += event.extendedProps.address.city?`, ${event.extendedProps.address.city}`:''
    address += event.extendedProps.address.postalCode?`, ${event.extendedProps.address.postalCode}`:''
    address += event.extendedProps.address.state?`, ${event.extendedProps.address.state}`:''

    // kdyz by jsme chteli prvni radek jako nazev
    // let title = (event.extendedProps?.orderLines && event.extendedProps.orderLines[0]?.olName)
    //   ?'[M] '+event._def.title + ' - ' +event.extendedProps.orderLines[0].olName
    //   :'[M] '+event._def.title
    let title = '[M] '+event._def.title + ' - ' +event.extendedProps.aName

    sAction.dsClear();
      /**
       * Musi se nastavovat pro kazdy pohled zvlast. Jsou tam ruzna pole a ruzne podminky
       * - Mohlo by se trosku predelat, ale to by trvalo priliz dlouho... az bude cas...
       * */

      // Meetings
      sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/name/value", title)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/name", title)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/duration_hours/value", durationHours)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/duration_hours", durationHours)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/meeting_type/value", meetingType)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/meeting_type", meetingType)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/duration_hours/def/minutesValue", durationMinutes)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/duration_minutes", durationMinutes)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/date_start/value",eventStart)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/date_start",eventStart)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/location/value",address)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/location",address)

      sAction.dsAdd("set",this.state.prefix + "/meetingView/customData/oID",event.extendedProps.id)

      sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/description",event.extendedProps.aContact)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/description/value",event.extendedProps.aContact)

      sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/acm_orders_id", event.extendedProps.oID)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/acm_orders_name/def/id_value", event.extendedProps.oID)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/acm_orders_name/value", event.extendedProps.oName)

      sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/assembly_group",defAssemplyCrew)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/assembly_group/value",defAssemplyCrew)

      sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/assigned_user_name/value",this.props.userName)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/assigned_user_name/def/id_value",state.userID)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/relate/assigned_user_name",this.props.userName)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/assigned_user_id",state.userID)

      sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/parent_name/value",event.extendedProps.aName)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/parent_name/def/parent_type_value",'Accounts')
      sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/parent_name/def/id_value",event.extendedProps.aID)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/parent_type",'Accounts')
      sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/parent_id",event.extendedProps.aID)
      sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/relate/parent_name",event.extendedProps.aName)

      sAction.dsAdd("set",this.state.prefix + "/meetingView/orderLines",event.extendedProps.orderLines)


      // Calls
      sAction.dsAdd("set",this.state.prefix + "/callView/fields/name/value", title)
      sAction.dsAdd("set",this.state.prefix + "/callView/fields/name/value", title)
      sAction.dsAdd("set",this.state.prefix + "/callView/fields/duration_hours/value", durationHours)
      sAction.dsAdd("set",this.state.prefix + "/callView/fields/duration_hours/value", durationHours)
      sAction.dsAdd("set",this.state.prefix + "/callView/fields/duration_hours/def/minutesValue", durationMinutes)
      sAction.dsAdd("set",this.state.prefix + "/callView/fields/duration_hours/def/minutesValue", durationMinutes)
      sAction.dsAdd("set",this.state.prefix + "/callView/fields/date_start/value",eventStart)
      sAction.dsAdd("set",this.state.prefix + "/callView/fields/date_start/value",eventStart)

      sAction.dsAdd("set",this.state.prefix + "/callView/fields/assigned_user_name/value",this.props.userName)
      sAction.dsAdd("set",this.state.prefix + "/callView/fields/assigned_user_name/def/id_value",state.userID)
      sAction.dsAdd("set",this.state.prefix + "/callView/changes/fields/relate/assigned_user_name",this.props.userName)
      sAction.dsAdd("set",this.state.prefix + "/callView/changes/fields/assigned_user_id",state.userID)

      sAction.dsAdd("set",this.state.prefix + "/callView/fields/parent_name/value",event.extendedProps.aName)
      sAction.dsAdd("set",this.state.prefix + "/callView/fields/parent_name/def/parent_type_value",'Accounts')
      sAction.dsAdd("set",this.state.prefix + "/callView/fields/parent_name/def/id_value",event.extendedProps.aID)
      sAction.dsAdd("set",this.state.prefix + "/callView/changes/fields/parent_type",'Accounts')
      sAction.dsAdd("set",this.state.prefix + "/callView/changes/fields/parent_id",event.extendedProps.aID)
      sAction.dsAdd("set",this.state.prefix + "/callView/changes/fields/relate/parent_name",event.extendedProps.aName)


      // Tasks
      sAction.dsAdd("set",this.state.prefix + "/taskView/fields/name/value", title)
      sAction.dsAdd("set",this.state.prefix + "/taskView/fields/name/value", title)
      sAction.dsAdd("set",this.state.prefix + "/taskView/fields/duration_hours/value", durationHours)
      sAction.dsAdd("set",this.state.prefix + "/taskView/fields/duration_hours/value", durationHours)
      sAction.dsAdd("set",this.state.prefix + "/taskView/fields/duration_hours/def/minutesValue", durationMinutes)
      sAction.dsAdd("set",this.state.prefix + "/taskView/fields/duration_hours/def/minutesValue", durationMinutes)
      sAction.dsAdd("set",this.state.prefix + "/taskView/fields/date_start/value",eventStart)
      sAction.dsAdd("set",this.state.prefix + "/taskView/fields/date_start/value",eventStart)

      sAction.dsAdd("set",this.state.prefix + "/taskView/fields/assigned_user_name/value",this.props.userName)
      sAction.dsAdd("set",this.state.prefix + "/taskView/fields/assigned_user_name/def/id_value",state.userID)
      sAction.dsAdd("set",this.state.prefix + "/taskView/changes/fields/relate/assigned_user_name",this.props.userName)
      sAction.dsAdd("set",this.state.prefix + "/taskView/changes/fields/assigned_user_id",state.userID)

      sAction.dsAdd("set",this.state.prefix + "/taskView/fields/parent_name/value",event.extendedProps.aName)
      sAction.dsAdd("set",this.state.prefix + "/taskView/fields/parent_name/def/parent_type_value",'Accounts')
      sAction.dsAdd("set",this.state.prefix + "/taskView/fields/parent_name/def/id_value",event.extendedProps.aID)
      sAction.dsAdd("set",this.state.prefix + "/taskView/changes/fields/parent_type",'Accounts')
      sAction.dsAdd("set",this.state.prefix + "/taskView/changes/fields/parent_id",event.extendedProps.aID)
      sAction.dsAdd("set",this.state.prefix + "/taskView/changes/fields/relate/parent_name",event.extendedProps.aName)

    sAction.dsProcess();
    cb(true)
  }

  handleEventUpdate = (arg) => {
    // sAction.load()
    let event = arg.event

    var fields = {
      "date_start" : moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
      "date_end" : moment(event.end).format('YYYY-MM-DD HH:mm:ss'),
    };
    var params = {
      "module" : event.extendedProps.act_type,
      "checkInvCollision" : true
    }

    sAction.calSaveActivity(event.id, fields, params, this.reschedCallBack/*, revertFunc*/);
  }

  handleEventClick = (arg) => {
    let event = arg.event

    if (event.extendedProps.orderLines) {
      sAction.dsClear();
      sAction.dsAdd("set", this.state.prefix + "/meetingView/orderLines", event.extendedProps.orderLines)
      sAction.dsProcess();
    }

    // aaaaaand open! (Claptrap) - otevřít popup s detailem
    sAction.displayQuickEdit({module : event.extendedProps.act_type, record : event._def.publicId, prefix : this.state.prefix});
  }

  handleEventDidMount = (arg) => {
    let event = arg.event
    let element = arg.el
    let view = arg.view
    // mesicni pohled proste nechce brat v potaz nastaveni pozadi primo na eventu TODO: zjistit proc

    //get secondary collor

    let primaryCollor = event.extendedProps?.eventBackgroundColor;
    let statusCollor = '#1D1DCC' // TODO posilat taky rovnou z BE
    switch (event.extendedProps?.status){
      case 'Held':
        statusCollor = event.extendedProps.act_type === 'Calls'?'#4ACC00':'#FFC30B'
        break;
      case 'Not Held':
      case 'NotHeld':
      case 'Failed':
      case 'Deferred':
        statusCollor = '#E92222'
        break;
      case 'Confirmed':
      case 'Success':
      case 'Completed':
        statusCollor = '#4ACC00'
        break;
      case 'Planned':
      case 'Progress':
        statusCollor = '#1D1DCC'
        break;
      default:
    }

    // if(view.type = "dayGridMonth") {
      element.style.background = 'linear-gradient(135deg, '+statusCollor+' 0%, '+statusCollor+'CC 17%, '+primaryCollor+' 33%)';
    // }

    if(event.extendedProps.act_type == "Meetings"){
      element.classList.add('eventMeeting');
      element.querySelector('.fc-event-title')
        ?.insertAdjacentHTML('beforebegin',"<div class='calEventIcon icon-Meetings' />");
    }
    else if(event.extendedProps.act_type == "Calls"){
      element.classList.add('eventCall');
      element.querySelector('.fc-event-title')
        ?.insertAdjacentHTML('beforebegin',"<div class='calEventIcon icon-Calls' />");
    }
    else if(event.extendedProps.act_type == "Tasks"){
      element.classList.add('eventTask');
      element.querySelector('.fc-event-title')
        ?.insertAdjacentHTML('beforebegin',"<div class='calEventIcon icon-Tasks' />");
    }
    if (event.extendedProps.assigned_user_id && sAction.dataGet("conf/user/orders_planner") === '1'){
      element.querySelector('.fc-event-title')
        ?.insertAdjacentHTML('afterend',
          '<div class="fc-event-assignedUser">' +
          '<div class="hr-line-solid-no-margin"></div>' +
          '<div class=\'calEventIcon icon-Users\'></div><span style="font-size: 0.9em">'
          + event.extendedProps.assigned_user_name +
          '</span>' +
          '</div>' +
          '</div>');
    }
    if (/*view.type !== "month" &&*/ event.extendedProps.description){
      element.querySelector('.fc-event-title')
        ?.insertAdjacentHTML('afterend','<div class="hr-line-solid-no-margin"></div>' +
          '<span class="eventDescriptionText" style="font-size: 0.9em">' + event.extendedProps.description + '</span></div>');
    }

    /** !!! Ocicko skryto, ale u nekterych zakazniku se jeste pouziva a u nekterzch budoucich se pouzivat bude, takze nemazat !!!  */
    // var adicon = $('<div />', {class: "icon-Eye", style: "width:15px; height: 15px; float:right; position:relative; z-index:10; margin-right: -2px; margin-top:4px;"})
    //
    // adicon.click(function(ev) {
    //     ev.stopPropagation();
    //     $("#adicon_box").remove();
    //     self.calGetAdditionalInfo(ev, event);
    // });
    const calendarApi = this[this.state.calRef].current?.getApi()
    if (!calendarApi) {
      this.setState({rerender: true})
      return
    }

    if(calendarApi.getCurrentData().currentViewType === "listACM"){
      if(event.extendedProps.act_type === "Meetings"){
        element.querySelector("a").insertAdjacentHTML('afterbegin',"<div class='calEventIcon agendaEvent icon-Meetings' />");
      }
      else if(event.extendedProps.act_type === "Calls"){
        element.querySelector("a").insertAdjacentHTML('afterbegin',"<div class='calEventIcon agendaEvent icon-Calls' />");
      }
      else if(event.extendedProps.act_type === "Tasks"){
        element.querySelector("a").insertAdjacentHTML('afterbegin',"<div class='calEventIcon agendaEvent icon-Tasks' />");
      }
    }

    element.style.minHeight = "20px";
    if(view.type === "agendaDay" && !event.allDay){
      element.style.maxWidth = "450px";
    }
    element.classList.add("calNoBorder");
    // ziskat tridy podle stavu
    element.classList.add(sAction.calGetClassName(event.extendedProps.act_type, event.extendedProps.status));
  }

  handleDatesSet = (dateInfo) => {
    if (!this.state.ready) return //jinak se tato funkce volala asi 3x při initu
    this.calendarUpdateEvents(dateInfo.start, dateInfo.end)
  }
  handleDayClick = (events) => {
    if (!events) return
    let date = moment(events.date)
    const params = {date : date, prefix: this.state.prefix};

    if(events.view.type == "dayGridMonth"){
      const now = moment();
      date.set({hour: now.get('hour'), minute: now.get('minute')});
    }

    this.setActivityInitData(sAction, params);

    // on close odstraneno, protoze cistilo hlavne orderlines, ale ted se pri kazdem volani rvou nova data i kdyz prazdna,
    // stejna vec i v displayQuickEdit.js
    sAction.popup("calQuickCreate", {/*onClose : sAction.clearCalDetailStore,*/ prefix : this.state.prefix});
  }

  setExternalEvents = data => {
    let draggableEl = document?.getElementById("external-eventsNew");
    if (draggableEl) { // [INF] pokud jsme na jine zalozce nez kde ty external eventy, tak jsou external skryte
      new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          let eventData = sAction.fromJSON(eventEl.getAttribute("data-calendar"));
          return {
            title: eventData.title,
            id: eventData.id,
            extendedProps: eventData
          };
        }
      });

      // aby se eventy neoznacili jako draggable 2x, pak pri presunuti do calendare vyskocili dva popupy
      // kdyz probehne zmena, tak se prerenderuji a budou mit znovu .external-eventsNew
      draggableEl.id = 'external-events';
    }
  }
  changeView = view => {
    let way =  this.props.way?this.props.way+'/data/options':"calendar/setting" // ?widgety:norm
    let calendarApi = this[this.state.calRef]?.current?.getApi()
    let params = sAction.dataGet(way);

    if (!this[this.state.calRef]?.current){
      sAction.href('/#home') // TODO vyresit proc na dashboardu, kdyz nemame v url home nefunguje reference
    }
    this['calRef'+[this.props.userID]] = React.createRef();
    calendarApi.changeView(view)
    calendarApi.scrollToTime(params.get("startIntervalHours") + ":" + params.get("startIntervalMinutes"))
    calendarApi.setOption('businessHours', {
      dow: [1, 2, 3, 4, 5], // daysOfWeek
      startTime: params.get("startIntervalHours") + ":" + params.get("startIntervalMinutes"), // start time
      endTime: params.get("endIntervalHours") + ":" + params.get("endIntervalMinutes") // end time
    })
    calendarApi.setOption('firstDay', params.get("fdow") ?? 1)
    calendarApi.setOption('weekNumbers', params.get("weekNumbers"))
    calendarApi.setOption('weekNumberTitle', sAction.translate("FC_WEEK_NUMBERS", "Calendar"))
    calendarApi.setOption('allDayText', sAction.translate("FC_ALL_DAY", "Calendar"))
    calendarApi.setOption('weekends', params.get("showWeekends"))
  }

  reschedCallBack(){
    // Zatim nemazat, nevime jestli u specif. zmen se nebude hodit
    // sAction.unLoad()
    // sAction.calGetEvents(true, (data) => {
      // this.setState({calendarEvents:data})// TODO proverit jestli neni potreba - mozna kvuli agende
    // });
  }

  setActivityInitData(self, params){
    const start = params.date.format("YYYY-MM-DD HH:mm:ss");
    const end = params.date.add(15, 'minutes').format("YYYY-MM-DD HH:mm:ss");

    const defAssemplyCrew = "^ba57a0e0-a71b-6145-b16d-62276218c9a5^,^925f5b46-1970-123b-da4a-5eff1562d487^,^6ca9ccd2-4f51-77b5-27ed-622761a0441f^";

    self.dsClear();
    // priprava dat do popupu
    // automaticke pridavani prirazeneho uzivatele v predefinedFields v kalendari zruseno, kvuli sdilenym kalendarum
    sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/assigned_user_name/value",this.props.userName)
    sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/assigned_user_name/def/id_value",this.state.userID)
    sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/relate/assigned_user_name",this.props.userName)
    sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/assigned_user_id",this.state.userID)
    sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/date_start/value",start)
    sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/date_start",start)
    sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/duration_hours/value",'0')
    sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/duration_hours/def/minutesValue",'15')
    sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/duration_minutes",'15')
    sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/duration_hours",'0')
    sAction.dsAdd("set",this.state.prefix + "/meetingView/orderLines",[])

    sAction.dsAdd("set",this.state.prefix + "/meetingView/changes/fields/assembly_group",defAssemplyCrew)
    sAction.dsAdd("set",this.state.prefix + "/meetingView/fields/assembly_group/value",defAssemplyCrew)

    sAction.dsAdd("set",this.state.prefix + "/callView/fields/assigned_user_name/value",this.props.userName)
    sAction.dsAdd("set",this.state.prefix + "/callView/fields/assigned_user_name/def/id_value",this.state.userID)
    sAction.dsAdd("set",this.state.prefix + "/callView/changes/fields/relate/assigned_user_name",this.props.userName)
    sAction.dsAdd("set",this.state.prefix + "/callView/changes/fields/assigned_user_id",this.state.userID)
    sAction.dsAdd("set",this.state.prefix + "/callView/fields/date_start/value",start)
    sAction.dsAdd("set",this.state.prefix + "/callView/changes/fields/date_start",start)
    sAction.dsAdd("set",this.state.prefix + "/callView/fields/duration_hours/value",'0')
    sAction.dsAdd("set",this.state.prefix + "/callView/fields/duration_hours/def/minutesValue",'15')
    sAction.dsAdd("set",this.state.prefix + "/callView/changes/fields/duration_minutes",'15')
    sAction.dsAdd("set",this.state.prefix + "/callView/changes/fields/duration_hours",'0')

    sAction.dsAdd("set",this.state.prefix + "/taskView/fields/assigned_user_name/value",this.props.userName)
    sAction.dsAdd("set",this.state.prefix + "/taskView/fields/assigned_user_name/def/id_value",this.state.userID)
    sAction.dsAdd("set",this.state.prefix + "/taskView/changes/fields/relate/assigned_user_name",this.props.userName)
    sAction.dsAdd("set",this.state.prefix + "/taskView/changes/fields/assigned_user_id",this.state.userID)
    sAction.dsAdd("set",this.state.prefix + "/taskView/fields/date_start/value",start)
    sAction.dsAdd("set",this.state.prefix + "/taskView/fields/date_due/value",end)
    sAction.dsAdd("set",this.state.prefix + "/taskView/changes/fields/date_start",start)
    sAction.dsAdd("set",this.state.prefix + "/taskView/changes/fields/date_due",end)

    let action = {
      type: "CALENDARINITACTIVITY",
      content: {invitees : {Users: [], Contacts: [], Leads : []}},
      prefix: params.prefix+"/invitees/relatedInv"
    }
    self.dsAddCustom(action);
    self.dsProcess();
  }
}

export default CalBody
