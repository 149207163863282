import React, { Component } from 'react'
import PureComponent from '../../pure'
import TextField from "@material-ui/core/TextField";

import  sAction  from "sAction";
import confProd from "./productLineConfig";
import Button from "../../formElements/Button";

export default class QTotal extends PureComponent{
    constructor(props) {
        super(props);
        this.state = {percent: 0};
      }
    
    valChange(way, ev){
        let newVal = sAction.parseNumber(ev.target.value);
        sAction.updateDetailCustom("field", {way:way, value: newVal});
        //sAction.dataSet(way, newVal);
    }

    applyIncrease(way, data) {
        sAction.dsClear();
        // Navysim vsechny cisla o x procent
        var percent = this.state.percent;
        data.groups.forEach((group, key) => {
            group.lines.forEach((line, lineKey) => {
                var newPrice = line.list_price * (1 + (percent / 100));
                sAction.dsAdd("set", way + '/groups/' + key + '/lines/' + lineKey + '/list_price', newPrice);
                const rate = this.props.rate;

                if (line.list_price) {
                    sAction.dsAdd("set", way + '/groups/' + key + '/lines/' + lineKey + '/czk_price', newPrice * rate);
                } else {
                    newPrice = line.czk_price * (1 + (percent / 100));
                    sAction.dsAdd("set", way + '/groups/' + key + '/lines/' + lineKey + '/czk_price', newPrice);
                }
            // if(line.currency_id == 'b8326c50-6f19-063b-507d-5e7cc12d5619') {
                //     sAction.dsAdd("set", way + '/groups/' + key + '/lines/' + lineKey + '/czk_price', newPrice * rate);
                // } else {
                //     sAction.dsAdd("set", way + '/groups/' + key + '/lines/' + lineKey + '/czk_price', newPrice);
                // }
            });
        });
        sAction.dsProcess();
    }

    render(){
    var data = this.props.data;
    let total_list_price = 0;
    let total_discount_amount = 0;
    const way = this.props.way;
    const keyPre = Date.now();
    const readonly = this.props.readonly;

    const sums = sAction.calcSums(way, {type : "total", data: data});
    const conf = confProd.sums;

    var renderComp = [];
    var firstFiller = null;

    var readonlyClass = "";
    
    if(!readonly){
        renderComp.push(
            <Button 
                className="acmAddGroupBtn"
                key={"addBtnGr"}
                variant="text" 
                onClick={() => sAction.updateDetailCustom("addProdGroup", {way:way+"/groups"})}>
                <div className="buttonIcon icon-addGroupIcon" />
                {sAction.translate("LBL_ADD_GROUP", "Quotes")}
            </Button>
        );
        firstFiller = <div key="firstFiller" style={{width:"34px"}} />
    }
    else{
        readonlyClass = " readOnly";
        firstFiller = <div key="firstFiller" style={{width:"14px"}} />
    }
    
    renderComp.push(
        <div key="secondFiller" style={{width:"51%"}} />
    );

    var self = this;


    /*
    conf.forEach( function(field){ 
        if(!field.visible){
            return;
        }
        if(field.type == "price"){
            renderComp.push(
                <div style={{width:field.width}} className="amountsContainer" key={"sum_"+field.name}>
                    <div>{sAction.translate(field.lbl, field.lblMod)}</div>
                    <div>{sAction.formatNumber(sums[field.name])}</div>
                </div>
            );
        }
        else if(field.type == "input"){
            renderComp.push(
                <div style={{width:field.width}} className="amountsContainer" key={"sum_"+field.name}>
                    <div>{sAction.translate(field.lbl, field.lblMod)}</div>
                    <TextField
                    className="priceInput"
                    margin="normal"
                    variant="outlined"
                    id={field.name}
                    type="text"
                    key={keyPre+"_"+field.name}
                    defaultValue={sAction.formatNumber(data[field.name])}
                    onBlur={(ev) => self.valChange(way+"/"+field.name, ev)}
                    />
                </div>
            );

        }
        else if(field.type == "addBtn"){

        }   

    });*/
        /*
        {
            name: "shipping",
            type: "input",
            lbl: "LBL_SHIPPING",
            lblMod: "Quotes",
            visible: true,
            width: "15%"
        },
        {renderComp}
        */
    return <div className="QTotalContainer">
        <div className="QTotalContainerTopBorder" />
        <div className="QTotalContainerFirstRow QTotalContainerRow">
            {!readonly &&
                <Button 
                    className="acmAddGroupBtn"
                    key={"addBtnGr"}
                    variant="text" 
                    onClick={() => sAction.updateDetailCustom("addProdGroup", {way:way+"/groups"})}>
                    <div className="buttonIcon icon-addGroupIcon" />
                    {sAction.translate("LBL_ADD_GROUP", "Quotes")}
                </Button>
            }
            {firstFiller}
            <div key="secondFiller" style={{width:"51%"}} />
            <div style={{width: "14%"}} className="amountsContainer" key={"sum_total_list_price"}>
                <div>{sAction.translate("LBL_SUBTOTAL", "Quotes")}</div>
                <div>{sAction.formatNumber(sums["total_list_price"], 2)}</div>
            </div>
            <div style={{width: "12%"}} className="amountsContainer" key={"sum_total_discount_amount"}>
                <div>{sAction.translate("LBL_LIST_DEAL_TOT", "Quotes")}</div>
                <div>{sAction.formatNumber(sums["total_discount_amount"], 2)}</div>
            </div>
            <div className={"QTotalContainerRowSumLbl SumsDiscouPriceContainer"+readonlyClass} style={{width:"12%"}}>{sAction.translate("LBL_NEW_SUB", "Quotes")}</div>
            <div style={{width: "7%"}} className="amountsContainer" key={"sum_total_discount_price_before_global"}>
                <div className="SumsDiscouPriceContainer">{sAction.formatNumber(sums["total_discount_price_before_global"], 2)}</div>
            </div>
        </div>
        <div className="QTotalContainerRow">
            <div className={"QTotalContainerRowSumLbl"+readonlyClass} >{sAction.translate("LBL_TAX", "Quotes")}</div>
            <div style={{width: "12%"}} className="amountsContainer" key={"sum_total_tax_before_global"}>
                <div>{sAction.formatNumber(sums["total_tax_before_global"], 2)}</div>
            </div>
        </div>
        <div className="QTotalContainerRow">
            <div className="QTotalContainerRowSumLbl" >{sAction.translate("LBL_TOTAL", "Quotes")}</div>
            <div style={{width: "12%"}} className="amountsContainer" key={"sum_total_before_global"}>
                <div>{sAction.formatNumber(sums["total_before_global"], 2)}</div>
            </div>
        </div>
        <div className="QTotalContainerRow">
            <div className={"QTotalContainerRowSumLbl"+readonlyClass} >{sAction.translate("LBL_SHIPPING", "Quotes")}</div>
            <div style={{width:"12%"}} className="amountsContainer sumRow" key={"sum_shipping"}>
                <TextField
                className="priceInput"
                margin="normal"
                variant="outlined"
                id="shipping"
                type="text"
                key={keyPre+"_shipping"}
                defaultValue={sAction.formatNumber(data["shipping"], 2)}
                onBlur={(ev) => self.valChange(way+"/shipping", ev)}
                disabled={readonly}
                />
            </div>
        </div>
        <div className="QTotalContainerRow">
            <div className="QTotalContainerRowSumLbl" >{sAction.translate("LBL_GLOBAL_DISCOUNT", "Quotes")}</div>
            <div style={{width:"12%"}} className="amountsContainer sumRow" key={"sum_globalDiscount"}>
                <TextField
                className="priceInput"
                margin="normal"
                variant="outlined"
                id="globalDiscount"
                type="text"
                key={keyPre+"_globalDiscount"}
                defaultValue={sAction.formatNumber(data["globalDiscount"], 2)}
                onBlur={(ev) => self.valChange(way+"/globalDiscount", ev)}
                disabled={readonly}
                />
            </div>
        </div>
        <div className="QTotalContainerRow">
            <div className="QTotalContainerRowSumLbl">{sAction.translate("LBL_PRICE_INCREASE", "Quotes")}</div>
            <div style={{width:"calc(12% - 29px)"}} className="amountsContainer sumRow" key={"sum_priceIncrease"}>
                <TextField
                className="priceInput"
                margin="normal"
                variant="outlined"
                id="priceIncrease"
                type="text"
                key={keyPre+"_priceIncrease"}
                defaultValue={sAction.formatNumber(this.state.percent, 2)}
                onChange={(ev) => this.state.percent = sAction.parseNumber(ev.target.value)}
                disabled={readonly}
                />
            </div>
            <div style={{width:"25px", maxWidth: '25px', minWidth: '0px', marginLeft: '4px', marginTop: '-2px'}} className="amountsContainer sumRow" key={"sum_globalDiscount"}>
                <Button
                    title={sAction.translate("LBL_APPLY_PRICE_INCREASE", "Quotes")}
                    className='acmAddGroupBtn'
                    onClick={e => this.applyIncrease(way, data)}
                    style={{width:"25px", minWidth: '25px', maxWidth: '25px', marginTop: '3px', maxHeight: '25px', minHeight: '25px'}}
                >
                    <div className="buttonIcon icon-pencil" style={{marginRight:"0px"}}/>
                    {/* {sAction.translate("LBL_APPLY_PRICE_INCREASE", "Quotes")} */}
                </Button>
            </div>
        </div>
        <div className="QTotalContainerRow">
            <div className="QTotalContainerRowSumLbl" >{sAction.translate("LBL_NEW_SUB_AFTER_GLOBAL", "Quotes")}</div>
            <div style={{width: "12%"}} className="amountsContainer" key={"sum_total_discount_price"}>
                <div>{sAction.formatNumber(sums["total_discount_price"], 2)}</div>
            </div>
        </div>
        <div className="QTotalContainerRow">
            <div className="QTotalContainerRowSumLbl" >{sAction.translate("LBL_TAX_AFTER_GLOBAL", "Quotes")}</div>
            <div style={{width: "12%"}} className="amountsContainer" key={"sum_total_tax"}>
                <div>{sAction.formatNumber(sums["total_tax"], 2)}</div>
            </div>
        </div>
        <div className="QTotalContainerRow">
            <div className="QTotalContainerRowSumLbl" >{sAction.translate("LBL_TOTAL_AFTER_GLOBAL", "Quotes")}</div>
            <div style={{width: "12%"}} className="amountsContainer" key={"sum_total"}>
                <div>{sAction.formatNumber(sums["total"], 2)}</div>
            </div>
        </div>

    </div>;
    }
}
