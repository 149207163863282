
import detailDefault from "../../detailDefault";
export default class detailacm_orders extends detailDefault {
    load(sAction, data) {
        let accID = sAction.dataGet(data.prefix + '/fields/acm_orders_accounts_name/def/id_value');
        this.checkAccERP(sAction, data, accID);
        if (!sAction.dataGet('conf/user/is_admin')) {
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/edit/def/visible', false);
            sAction.dsProcess();
        }

    }

    update(sAction, data) {
        if (data.field === 'acm_orders_accountsaccounts_ida') {
            this.checkAccERP(sAction, data, data.value.id);
        }
    }

    checkAccERP(sAction, data, accID) {
        if (accID) {
            // zeptat se na rest
            sAction.rest.post('getAccERP', {accID: accID}, returnData => {
                sAction.dsClear();
                if (returnData.status && returnData.message && returnData.message.data && returnData.message.data.status) {
                    sAction.dsAdd('set', data.prefix + '/fields/acm_orders_accounts_name/customClass', 'validERP');
                } else {
                    sAction.dsAdd('set', data.prefix + '/fields/acm_orders_accounts_name/customClass', 'invalidERP');
                }
                sAction.dsProcess();
            });
        } else {
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/acm_orders_accounts_name/customClass', 'invalidERP');
            sAction.dsProcess();
        }
    }
}
