export default function quoteCreateOrderPopupPlas(data) {
    const zamerovac = this.dataGet(data.prefix + '/fields/zamerovac/value');
    const accountId = this.dataGet(data.prefix + '/fields/billing_account_name/def/id_value');
    if (!zamerovac) {
        this.error(this.translate('LBL_ZAMEROVAC_NOT_SET'));
        this.dsClear();
        this.dsAdd("set",data.prefix + "/fields/zamerovac/customClass",'error');
        this.dsProcess();
    } else {
        const backupInvoice = this.dataGet(data.prefix + '/fields/backup_invoice/value');
        if (!backupInvoice || backupInvoice === '0') {
            this.quoteCreateOrderPlas(data);
        } else {
            this.rest.post('isQuoteInvoice', {quoteID: this.dataGet(data.prefix + '/id'), accountId: accountId}, (returnData) => {
                if (returnData.errorMessage?.text === 'LBL_NO_REPRESENTED') {
                    return this.error(this.translate(returnData.errorMessage.text));
                }
                if (returnData.status === true) {
                    this.popup('QuoteCreateOrderPlasPopup', {params: data});
                } else {
                    this.quoteCreateOrderPlas(data);
                }
            });
        }
    }
}
