import React from "react";
import PureComponent from "../../pure";
import  sAction  from "sAction";
import Button from "../../formElements/Button";
import TickBox from "../../formElements/TickBox";

export default class QuoteCreateOrderPlasPopup extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            overwriteInvoice: true,
            invoices: [],
        };
    }

    componentDidMount() {
        this.getInvoices();
    }

    createOrder(e) {
        const invoiceIds = [];
        this.state.invoices.forEach((item) => {
            item.checked ? invoiceIds.push(item.id) : null;
        })
        let data = this.props.data?.get('params')?.toJS();
        data.ignoreBackupInvoice = !this.state.overwriteInvoice
        sAction.quoteCreateOrderPlas(data, invoiceIds);
        sAction.popupHide();
    }

    getInvoices() {
        const data = this.props.data?.get('params')?.toJS();
        sAction.rest.post('getInvoices', {quoteID: data.id}, (resData) => {
            if (resData.status) {
                resData.message.data.forEach((element) => {
                    element.checked = true;
                });
                this.setState({ invoices: resData.message.data })
            }
        })
    }

    renderInvocies() {
        const invoices = this.state.invoices.map((item, index) => {
            return (
                <div className="invoicesList-item" key={index}>
                    <a href={window.location.href.split('/')[0]+`/#detail/acm_invoices/${item.id}`}>{item.name}</a>
                    <div>
                            {item.stav_uhrazeni === 'uhrazeno' ?
                            sAction.translate('LBL_PAID', 'Quotes') :
                            sAction.translate('LBL_UNPAID', 'Quotes')}
                    </div>
                     <TickBox
                        className="calFiltersStateTick"
                        checked={item.checked}
                        onChange={(value) => {
                            const items = [...this.state.invoices];
                            items[index].checked = !item.checked;
                            this.setState({invoices: items})}
                        }
                    />
                </div>
            )
        })
        return invoices;
    }

    render() {
        console.log(this.state.invoices);
        return (
            <>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_CREATE_ORDER')}</div>
                </div>
                <div className="">
                    <div className="multipleCallsTable">
                        <div className="">
                            <div className="mcCell">{sAction.translate('LBL_UPDATE_BACKUP_INVOICE')}</div>
                            <div className="invoicesList">
                                {this.renderInvocies()}
                            </div>
                        </div>
                        <div className="mcRow">
                            <div className="mcCell mcButtons viewActionPanelButtons">
                                <Button
                                    className='popupCloseButton'
                                    onClick={e => this.closePopup()}
                                >
                                    <div className="actionPanelButtonIcon icon-closeIconLight" />
                                    {sAction.translate('LBL_CLOSE_POPUP')}
                                </Button>
                                <Button
                                    onClick={e => this.createOrder(e)}
                                >
                                    <div className="actionPanelButtonIcon icon-addIcon" />
                                    {sAction.translate('LBL_CREATE_ORDER')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
