import $ from "jquery";

export default class rest {
  constructor(parent) {
    this.url = parent.param.server;
    this.token = null;
    this.parent = parent;
  }

  post(action, data, complete) {
    this.call(action, "POST", data, function(msg) {
      complete(msg);
    });
  }
  get(action, complete) {
    this.call(action, "GET", null, function(msg) {
      complete(msg);
    });
  }
  delete(action, data, complete) {
    this.call(action, "DELETE", data, function(msg) {
      complete(msg);
    });
  }

  call(action, method, data, complete) {
    var auth = this.getAuth();
    var self = this;

    $.ajax({
      method: method,
      contentType: "application/json",
      url: self.url + "/" + action,
      // url: self.url + "/" + action+"?XDEBUG_SESSION_START=1",
      data: JSON.stringify(data),
      headers: auth,
      success: data => {
        //zobrazeni fatalnejsich chyb, ktere mohou prerusit routovani atd.
        if (typeof data === "object" && data.status === "error") {
          if (data.reason != undefined) {
            self.parent.dsClear();
            if(self.parent.dataGet("rightPanel/show") == true){
              self.parent.dsAdd("set", "rightPanel/content", "error");
              self.parent.dsAdd("set", "conf/load", false);
              self.parent.dsAdd("set", "rightPanel/data", {
                state: false,
                type: data.reason
              });
            }else{
              self.parent.dsAdd("set", "conf/view", "error");
              self.parent.dsAdd("set", "conf/load", false);
              self.parent.dsAdd("set", "view", {
                state: false,
                type: data.reason
              });
            }

            self.parent.dsProcess();
          } else {
            self.parent.unLoad();
            self.parent.error(self.parent.translate(data.msg || data.message));
          }
        }

        complete(data);

        //zobrazeni dalsich chybovych hlasek
        if (typeof data === "object" && data.status === "warning") {
          setTimeout(function () {
            self.parent.error(self.parent.translate(data.msg || data.message));
          }, 2500);
        }

      },
      error: (XMLHttpRequest, textStatus, errorThrown) => {
        if (XMLHttpRequest.status == "401" || XMLHttpRequest.status == 0) {
          if (window.location.hash != "#login") {
            window.location.hash = "#login";

            if (XMLHttpRequest.status == "401") {
              this.get("trns", data => {
                self.parent.app_strings = data.lang;
                self.parent.renderReact();
              });
            } else {
              self.parent.renderReact();
            }
          } else {
            clearInterval(this.parent.notificationInterval);
            self.parent.unLoad();
          }
        }
      }
    });
  }
  getAuth() {
    var token = this.getCookie("sID");
    var auth = {
      sID: token
    };

    if(this.parent.deviceType != undefined){
      auth["device"] = this.parent.deviceType
    }

    return auth;
  }
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
  }
  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1);
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return null;
  }
}
