export default { 
    fields: [
        {
            name: "quantity",
            type: "number",
            lbl: "LBL_LIST_QUANTITY",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 4
        },
        {
            name: "name",
            type: "relateProd",
            lbl: "LBL_LIST_PRODUCT_NAME",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 28,
        },
        {
            name: "mj",
            type: "text",
            lbl: "LBL_MJ",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 4,
        },
        {
            name: "x_axis",
            type: "axis",
            lbl: "LBL_X_AXIS",
            lblMod : "Products",
            visible: true,
            disabled: false,
            width: 5,
        },
        {
            name: "y_axis",
            type: "axis",
            lbl: "LBL_Y_AXIS",
            lblMod : "Products",
            visible: true,
            disabled: false,
            width: 5,
        },
        {
            name: "tax_class", 
            type: "tax_class",
            lbl: "LBL_LIST_TAXCLASS",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 6
        },
        {
            name: "list_price",
            type: "price",
            lbl: "LBL_LIST_LIST_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 7
        },
        {
            name: "czk_price",
            type: "price",
            lbl: "LBL_CZK_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 7
        },
        {
            name: "percents",
            type: "percents",
            lbl: "LBL_PERCENTS",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 5,
        },
        {
            name: "discount_amount",
            type: "price",
            lbl: "LBL_LIST_DEAL_TOT",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 7,
        },
        {
            name: "discount_select",
            type: "discount_select",
            lbl: "",
            lblMod : "Products",
            visible: true,
            disabled: false,
            width: 5
        },
        {
            name: "discount_price",
            type: "price",
            lbl: "LBL_LIST_DISCOUNT_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 7
        },
        {
            name: "total_price",
            type: "price",
            lbl: "LBL_LIST_TOTAL_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 7
        },
        {
            name: "amount_rafter",
            type: "infoBox",
            lbl: "",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 2,
        },
        {
            name: "opce_c",
            type: "checkbox",
            lbl: "LBL_LIST_OPCE",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 5
        },
    ],
    sums : [
        {
            type: "addBtn",
            visible: true,
            width: "63%",
        },
        {
            type: "space",
            visible: true,
            width: "20px",
        },
        {
            name: "total_list_price",
            type: "price",
            lbl: "LBL_SUBTOTAL",
            lblMod: "Quotes",
            visible: true,
            width: "14%"
        },
        {
            name: "total_discount_amount",
            type: "price",
            lbl: "LBL_LIST_DEAL_TOT",
            lblMod: "Quotes",
            visible: true,
            width: "12%"
        },
        {
            name: "total_discount_price",
            type: "price",
            lbl: "LBL_NEW_SUB",
            lblMod: "Quotes",
            visible: true,
            width: "10%"
        }
    ]
};
