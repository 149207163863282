import React from 'react';
import ListviewAddNewProspectList from "../../components/popup/listview/ListviewAddNewProspectList";

export default function addToProspectLists(params, selectedBefore = null) {
  var selected = [];
  if (!selectedBefore) {
  const list = this.dataGet(params.prefix);
    selected = list.selected;
  } else {
    selected = params.selected;
  }

  const data = {
    header: this.translate("LBL_ADD_TO_PROSPECT_LIST_BUTTON_LABEL"),
    //  color:"red",
    buttons: [
      {
        // Vytvořit
        label: this.translate("LBL_CREATE","ProspectLists"),
        callback: () => {
          this.popupHide();
          this.popup(<ListviewAddNewProspectList prefix={params.prefix} module={params.module} selected={selected} />);
          //window.open("#detail/ProspectLists", "_blank");
        },
      },
      {
        // Vybrat existující
        label: this.translate("LBL_CHOOSE_EXISTING","ProspectLists"),
        callback: () => {
          this.popupHide();
          const relateData = {
            module: "ProspectLists",
          };
          this.openRelatePopup(relateData, (returnData) => {
            this.popupHide();
            var sendRestData = {
              uids: selected,
              prospect_list_id: typeof returnData.id === "string" ? [returnData.id] : returnData.id,
              module: params.module,
            };
            this.load();
            this.rest.post("addToProspectLists", sendRestData, (returnRestData) => {
              this.unLoad();
              if (returnRestData) {
                this.alert("OK");
                this.dataSet(params.prefix + "/selected", []);
              } else {
                // nastala chyba
              }
            });
          });
        },
      },
    ],
  };

  // "Vybranou položku nebo položky můžete přiřadit k existujícímu seznamu nebo vytvořit nový."
  this.popup(this.translate("LBL_CREATE_OR_CHOOSE_EXISTING","ProspectLists"), data);
}


