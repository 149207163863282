import React from "react";
import PureComponent from "../pure";

import WaveEffect from "../formElements/WaveEffect";
import MoreOptions from "../formElements/MoreOptions";
import  sAction  from "sAction";
import NewActivityButton from "./NewActivityButton";

export default class SubpanelHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.header = React.createRef();
  }
  pagination(type) {
    const data = this.props.data;
    const listData = data.rows;
    const way = this.props.way;
    const paginationData = {
      offset: listData.offset,
      limit: listData.limit,
      rowCount: listData.rowCount,
      page: listData.page,
      prefix: way + "/rows",
      type: type
    };
    sAction.listPagination(paginationData);
  }
  subpanelMaxSize() {
    const way = this.props.way;
    var container = this.header.current.closest(".subpanelContainer");
    if (container.classList.contains("maxSize")) {
      container.classList.remove("maxSize");
      sAction.dataSet(way + "/rows/limit", 10);
      sAction.reloadList(0, 1, way + "/rows");
    } else {
      container.classList.add("maxSize");
      sAction.dataSet(way + "/rows/limit", 100);
      sAction.reloadList(0, 1, way + "/rows");
    }
  }
  openRelateListView() {
    const data = this.props.data;

      const paramData = {
          module: data.module,
          defaultFilter: data.rows.filter?.toJS(),
      };
    sAction.openRelatePopup(paramData, returnData => {
      sAction.subppanelChangeRelate(
        returnData,
        data,
        this.props.prefix,
        this.props.way,
        0
      );
    });
  }
  openExportMenu() {
    const prefix = this.props.way + "/rows";
    const data = this.props.data;
    const params = {
      prefix: prefix,
      module: data.module
    };
    sAction.exportList(params);
  }

  render() {
    const data = this.props.data;
    const prefix = this.props.prefix;
    const way = this.props.way;
    const records = this.props.records;
    const pageNumber = data.rows.page;
    const open = data.def.get("open");
    const readonly = data.def.get("readonly");

    var subpanelIconClass = "icon-subpanelClose";
    if (open == false) {
      subpanelIconClass = "icon-subpanelOpen";
    }
    let showActivities;
    if (data.module == "Activities" || data.module == "History") {
      showActivities = <div className="subpanelHeaderRightButton icon-Activities"
        onClick={e => sAction.showActivitiesPanel(prefix)}
      />;
    }
    let newRecordButton;
    if(readonly){
      newRecordButton = null;
    }
    else if (data.module == "Activities") {
      newRecordButton = (
        <NewActivityButton
          data={data}
          prefix={prefix}
          way={way}
          activities={[
            { module: "Meetings" },
            { module: "Calls" },
            { module: "Tasks" },
            {
              label: sAction.translate("LBL_COMPOSE_EMAIL_BUTTON_LABEL"),
              module: "Emails",
              icon: "icon-Emails"
            }
          ]}
        />
      );
    } else if (data.module == "History") {
      newRecordButton = (
        <NewActivityButton
          data={data}
          prefix={prefix}
          way={way}
          activities={[{ module: "Notes" }]}
        />
      );
    } else {
      if(sAction.hasAccess(data.module,"edit") == true){
        newRecordButton = (
          <div
            onClick={() =>
              sAction.subpanelNewRecord({ paramData: data, way, prefix })
            }
            className="subpnaelheaderNewRecord subpanelHeaderRightButton icon-addIcon"
          >
            <WaveEffect className="dark" />
          </div>
        );
      }
    }

    var options = [];
    if (data.module == "Activities" || data.module == "History") {
      options = [
        {
          label: sAction.translate("LBL_EXPORT_LIST"),
          value: "openExportMenu"
        }
      ];
    } else {
      if(!readonly){
        options.push({
          label: sAction.translate("LBL_ASSIGN"),
          value: "openRelateListView"
        });
      }

      options.push({
          label: sAction.translate("LBL_EXPORT_LIST"),
          value: "openExportMenu"
      });
    }

    const parentModule = data.def.getIn(["rel","module"]) ?? "";
    const relName = data.def.getIn(["rel","relName"]) ?? "";
    const subpanelNameKey1 = "LBL_"+data.module.toUpperCase()+"_SUBPANEL_TITLE"
    const subpanelNameKey2 = "LBL_"+relName.toUpperCase()+"_FROM_"+data.module.toUpperCase()+"_TITLE"
    var subpanelName = sAction.translate(subpanelNameKey2,parentModule);
    if(subpanelName == subpanelNameKey2){
      subpanelName = sAction.translate(subpanelNameKey1,parentModule);
    }
    if(subpanelName == subpanelNameKey1){
      subpanelName = sAction.app_strings.moduleList[data.module] || data.module
    }


    return (
      <div className="subpanelHeader" ref={this.header}>
        <div>
          <span className="moveHolder icon-Sortable" />
          <h3 onClick={() => sAction.toogleSubpanel(data, way, prefix)}>
            <div className={subpanelIconClass} />
            {subpanelName}
          </h3>
        </div>
        <div>
          {data.def.get("open") === true && records !== 0 && (
            <React.Fragment>
              <div className="subpanelPagination">
                <div
                  onClick={() => this.pagination("start")}
                  className="arrow iconPageStart"
                />
                <div
                  onClick={() => this.pagination("back")}
                  className="arrow iconPageBack"
                />
                <div className="pageCount">{pageNumber}</div>
                <div
                  onClick={() => this.pagination("next")}
                  className="arrow iconPageNext"
                />
              </div>

              <div
                onClick={() => this.subpanelMaxSize()}
                className="subpanelHeaderRightButton icon-MaxSize"
              >
                <WaveEffect className="dark" />
              </div>
            </React.Fragment>
          )}
          {newRecordButton}
          {showActivities}
          <MoreOptions
            options={options}
            onRight={true}
            fromRight={70}
            onClick={(e, val) => this[val](e)}
          >
            <div className="subpanelHeaderRightButton icon-More" />
          </MoreOptions>
        </div>
      </div>
    );
  }
}
