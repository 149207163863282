export default function HomeWidgetAdd(paramData) {
  console.log(paramData);
  const self = this;
  this.dsClear();
  const viewId = self.dataGet("view/activeId");
  var way = "view/tabs/" + viewId + "/cols/0/rows";
  if (paramData.type == "customReport") {

      self.dsAdd(
        "add",
        way,
        {
          name:paramData.name,
          def:{type:"customReport",show:true},
          data: {
            viewId: paramData.id,
            module: null,
            viewData: null
          }
        },
        "HomeColumnsRows"
      );
      self.dsProcess();
      self.homeLayoutSave();

  } 
  else {
    this.rest.post("home/getwidget", paramData, data => {
      if (data["widgetType"] == "listView") {
        self.dsAdd(
          "add",
          way,
          {
            name: paramData.name,
            def: { type: "view", show: true },
            data: {
              viewId: paramData.id,
              module: paramData.search_module,
              viewData: ""
            }
          },
          "HomeColumnsRows"
        );

        var size = self.dataGet(way).size;
        var content = self.listContent(data);
        var akce = {
          type: "PAGELOADLIST",
          page: paramData.search_module,
          content: content,
          listViewPrefix: way + "/" + size + "/data/viewData",
          function: self,
          savedSearch: data.saved_search_id,
        };
        self.dsAdd("set", akce.listViewPrefix, {});
        self.dsAddCustom(akce);
        self.dsProcess();
        self.homeLayoutSave();
      }
      else if(paramData.search_module == "Calendar"){
        var size = self.dataGet(way).size;
        self.dsAdd(
          "add",
          way,
          {
            name:paramData.name,
            def:{type:"calendar",show:true},
            data: {
              viewId: paramData.id,
              module: "Calendar",
              viewData: null
            }
          },
          "HomeColumnsRows"
        );
        

        let prefix = way+"/"+size+"/data"; 
        self.routeCalendarDahlet(prefix, data, viewId+"_0_"+size);
        self.dsProcess();
        //const params = {"start" : this.calEvents.date_range.date_start, "end" : this.calEvents.date_range.date_end, "prefix" : way+"/"+size+"/data/viewData"};//TODO TTT
        //self.calGetInitData(params, (function(){self.displayFcCal(self, "dashFC", "parent")}));
        self.homeLayoutSave();
      }
    });
  }
}
