export default function calGetExternalEvents(module, filter, dateRange, filterInput, _callback){
    /**
     * TODO
     * Zavolat na BE a ziskat udaje z pozadovaneho modulu s urcitym filtrem
     * vracene udaje dat do pozadovane podoby a rozdelit podle zdroje(modulu) a filtru,
     * protože se to následně bude vkládat do statu
     */

    let params = {"user_id" : "", "filter" : filter, "module":module, "date_range" : dateRange, "filterInput" : filterInput};
    this.rest.post("calExternalEvents", params, function(data){
        _callback(data);
        // $("#"+self.calEvents.cssSelector).fullCalendar("render");
        //
        // data.eventData.calendars.forEach((cal) => {
        //     let pomEvents = {"Meetings" : [], "Calls" : [], "Tasks" : [], "Events" : []};
        //
        //     cal.Meetings.forEach((act) => {
        //         pomEvents.Meetings.push(act);
        //     });
        //
        //     cal.Calls.forEach((act) => {
        //         pomEvents.Calls.push(act);
        //     });
        //
        //     cal.Tasks.forEach((act) => {
        //         pomEvents.Tasks.push(act);
        //     });
        //
        //     self.calEvents.calendars[cal.id] = {id : cal.id, events: pomEvents};
    });
}
