import React from "react";
import PureComponent from "../pure";

import Text from "../formElements/InputText";

import sAction from "sAction";

class ListViewSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }
  search() {
    const val = this.input.current.value;
    const prefix = this.props.prefix;
    sAction.listGlobalSearch(val, prefix);
  }
  newRecord() {
    const params = {
      module: this.props.module,
      way:this.props.prefix
    }
    sAction.detailNewRecord(params)
  }

  render() {
    const data = this.props.data;
    var defaultValue = "";

    if (data.length > 0) {
      data.forEach((filter, index) => {
        if (filter.field === "*") {
          defaultValue = filter.value;
        }
      });
    }
    return (
      <div className="flexInLine flexVerticalCenter">
        <div className="listViewSearch">
          <div
            onClick={() => this.search()}
            className="icon-search listViewSearchIcon"
            defaultValue={defaultValue}
          />
          <Text
            myRef={this.input}
            onKeyUp={event => event.keyCode == 13 && this.search()}
            placeholder={sAction.translate("LBL_LIST_SEARCH_GLOBAL_DESC")}
            autoFocus={true}
            clearButton={true}
            onClearButton={() => this.search()}
          />
          <div className="listViewSearchButton" onClick={() => this.search()}>
            {sAction.translate("LBL_LIST_SEARCH_GLOBAL")}
          </div>

        </div>
        <div className="listViewSearchButtonAdd" onClick={() => this.newRecord()}>
          <div className="icon-addIcon">

          </div>
        </div>
      </div>
    );
  }
}
export default ListViewSearch;
