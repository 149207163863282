import detailDefault from "../../detailDefault";

export default class detailAccounts extends detailDefault {
  load(sAction, data) {
    this.updateDetailApiVisibility(sAction, data);
  }

  update(sAction, data) {
    switch (data.field) {
      case "billing_address_country":
        this.updateDetailApiVisibility(sAction, data)
        break;
    }
  }

  //fce skryva/odkryva detail fieldy podle zeme, jestli je zeme CZECH REPUBLIC
  updateDetailApiVisibility(sAction, data) {
    let address;
    if (data.value) {
      address = data.value;
    } else {
      address = sAction.dataGet(data.prefix + "/fields/billing_address_country/value");
    }

    sAction.dsClear();
    if (!address || address === "CZECH REPUBLIC") {
      //zobrazi ikony
      sAction.dsAdd("set", data.prefix + "/fields/name/def/showDetailApi", "ApiName");
      sAction.dsAdd("set", data.prefix + "/fields/account_name/def/showDetailApi", "ApiName");
      sAction.dsAdd("set", data.prefix + "/fields/ticker_symbol/def/showDetailApi", "ApiICO");
      sAction.dsAdd("set", data.prefix + "/fields/sic_code/def/showDetailApi", "ApiDIC");
      sAction.dsAdd("set", data.prefix + "/fields/billing_address_street/def/showDetailApi", "ApiStreet");
      sAction.dsAdd("set", data.prefix + "/fields/shipping_address_street/def/showDetailApi", "ApiStreet");
      sAction.dsAdd("set", data.prefix + "/fields/billing_address_postalcode/def/showDetailApi", "ApiPSC");
      sAction.dsAdd("set", data.prefix + "/fields/shipping_address_postalcode/def/showDetailApi", "ApiPSC");
    }
    else if(address === "SLOVAKIA"){
      //skryje ikony
      sAction.dsAdd("set", data.prefix + "/fields/name/def/showDetailApi", "ApiNameSK");
      sAction.dsAdd("set", data.prefix + "/fields/account_name/def/showDetailApi", "ApiNameSK");
      sAction.dsAdd("set", data.prefix + "/fields/ticker_symbol/def/showDetailApi", "ApiICOSlovakia");
      sAction.dsAdd("set", data.prefix + "/fields/sic_code/def/showDetailApi", "");
      sAction.dsAdd("set", data.prefix + "/fields/billing_address_street/def/showDetailApi", "ApiStreet");
      sAction.dsAdd("set", data.prefix + "/fields/shipping_address_street/def/showDetailApi", "ApiStreet");
      sAction.dsAdd("set", data.prefix + "/fields/billing_address_postalcode/def/showDetailApi", "ApiPSC");
      sAction.dsAdd("set", data.prefix + "/fields/shipping_address_postalcode/def/showDetailApi", "ApiPSC");
    }
    else{
      //skryje ikony
      sAction.dsAdd("set", data.prefix + "/fields/name/def/showDetailApi", "ApiNameOthers");
      sAction.dsAdd("set", data.prefix + "/fields/account_name/def/showDetailApi", "");
      sAction.dsAdd("set", data.prefix + "/fields/ticker_symbol/def/showDetailApi", "");
      sAction.dsAdd("set", data.prefix + "/fields/sic_code/def/showDetailApi", "");
      sAction.dsAdd("set", data.prefix + "/fields/billing_address_street/def/showDetailApi", "");
      sAction.dsAdd("set", data.prefix + "/fields/shipping_address_street/def/showDetailApi", "");
      sAction.dsAdd("set", data.prefix + "/fields/billing_address_postalcode/def/showDetailApi", "");
      sAction.dsAdd("set", data.prefix + "/fields/shipping_address_postalcode/def/showDetailApi", "");
    }

    //Skryvani/odkryvani tabu Finstat kdyz je nebo neni zeme Slovensko
    const tabs = sAction.dataGet(data.prefix + "/tabs");
    const finstatIndex = tabs.findIndex(i => i.name == "LBL_DETAILVIEW_PANEL_FINSTAT");
    if (address != "SLOVAKIA") {
      sAction.dsAdd("set", data.prefix + "/tabs/"+finstatIndex+"/hidden", true);
    } else {
      sAction.dsAdd("set", data.prefix + "/tabs/"+finstatIndex+"/hidden", false);
    }
    sAction.dsProcess();
  }
}
