import React from "react";
import PureComponent from "../../pure";

import Select from "../../formElements/Select";
import TabSelect from "../../formElements/TabSelect";
import InputText from "../../formElements/InputText"
import MultiSelect from "../../formElements/MultiSelect";
import AcmDate from "../../formElements/AcmDate";
import CheckBox from "../../formElements/CheckBox";

import  sAction  from "sAction";
import { endOfQuarter } from "date-fns";

class ReportWindowContentFieldLine extends PureComponent {
  constructor(props){
    super(props)

    this.input = React.createRef();
    this.select = [];
  }
  selectField(e) {
    const select = e;
    const fields = this.props.fields;
    const relate = this.props.relate;
    const way = this.props.way;
    const index = this.props.index;
    var field = null;
    // Pokud se vybere {MOJE}, pak se nahrajou celý data rovnou
    if (select == sAction.translate('LBL_USER_ID_PLACEHODLER')) {
      const data = {
        name: 'id',
        select: select,
        operator: 'eq',
        value: '{%LOGIN_USER%}',
        def: {
          name: 'id',
          vname: 'LBL_USER_ID_PLACEHODLER',
          type: 'placeholder',
          len: 100,
        }
      };
      sAction.reportWindowSetLineData(way + "/" + index, data);

      return;
    }
    // Pokud ne, klasický postup
    fields.forEach((lfield, index) => {
      if (lfield.get("name") == select) {
        field = lfield;
      }
    });
    relate.forEach((lfield, index) => {
      if (lfield.get("name") == select) {
        field = lfield;
      }
    });
    const data = {
      name: field.get("name"),
      select: select,
      def: field.toJS()
    };
    sAction.reportWindowSetLineData(way + "/" + index, data);
  }
  setFieldData(type, value) {
    const data = this.props.data;
    const way = this.props.way;
    const index = this.props.index;
    var fieldData = {};
    fieldData[type] = value;

    const fieldType = data.def.get("type");
    if (
      fieldType == "date" ||
      fieldType == "datetime" ||
      fieldType == "datetimecombo"
    ) {
      if (type == "operator") {
        if (
          value != "eq" &&
          value != "neq" &&
          value != "moreThan" &&
          value != "lessThan"
        ) {
          fieldData["value"] = null;
        }
      }
    }
    if (fieldType == "bool") {
      fieldData["operator"] = "eq";
    }

    sAction.reportWindowSetLineData(way + "/" + index, fieldData);
  }
  deleteLine() {
    const way = this.props.way;
    const index = this.props.index;
    sAction.reportWindowDeleteLine(way, index);
  }

  render() {
    var data = this.props.data;
    var fields = this.props.fields;
    var relate = this.props.relate;
    var actModule = this.props.module;
    var fieldsRender = [];
    var renderData = [];
    fields.forEach((field, index) => {
      var preklad = field.get("vname");
      if (sAction.moduleTranslate[actModule] != undefined) {
        preklad = sAction.moduleTranslate[actModule][field.get("vname")];
      }
      if (preklad == null || preklad == "" || preklad == undefined) {
        preklad = sAction.app_strings[field.get("vname")];
      }
      renderData.push({ value: field.get("name"), label: preklad });
    });
    if (this.props.module === 'Users') {
      renderData.unshift({ value: sAction.translate('LBL_USER_ID_PLACEHODLER'), label: sAction.translate('LBL_USER_ID_PLACEHODLER') });
    }
    fieldsRender[sAction.translate('LBL_REPORT_SEARCH_FIELDS')] = renderData;
    renderData = [];
    relate.forEach((field, index) => {
      var preklad = null;
      if (sAction.moduleTranslate[actModule] != undefined) {
        preklad = sAction.moduleTranslate[actModule][field.get("vname")];
      }
      if (preklad == null || preklad == "" || preklad == undefined) {
        preklad = sAction.app_strings[field.get("vname")];
      }
      renderData.push({ value: field.get("name"), label: preklad });
    });
    fieldsRender[sAction.translate('LBL_REPORT_SEARCH_RELATE')] = renderData;

    var selectOption = null;
    var valueInput = null;
    if (data.def != null) {
      const type = data.def.get("type");
      if (type == "date" || type == "datetime" || type == "datetimecombo") {
        const dateOptions = sAction.getDateFilterOptions();
        selectOption = (
          <Select
            containerClassName="withBorder"
            options={dateOptions}
            defaultValue={data.operator}
            onChange={
              (e) => {
                this.setFieldData("operator", e.target.value)
                this.select = e.target.value
              }
            }
          />
        );

        var disabledOptions = [];
        dateOptions.forEach((value) => {
            disabledOptions[value.value] = value.disabledInput;
        });
        var disabled = disabledOptions[this.select];

        valueInput = (
          <AcmDate
            disabled={disabled}
            value={data.value}
            onChange={value => this.setFieldData("value", value)}
          />
        );
      } else if (type == "enum") {
        const options = data.def.get("options");
        if (options != null && options != "") {
          const optionData = sAction.app_strings[options];
          var optionsToSelect = [];
          optionData.forEachObject((value, key) => {
            optionsToSelect.push({ value: key, label: value });
          });

          var operatorOptions = [
            { value: "contArray", label: sAction.translate("LBL_CONTAINS") },
            { value: "ncontArray", label: sAction.translate("LBL_NOT_CONTAINS") }
          ];

          selectOption = (
            <Select
              containerClassName="withBorder"
              options={operatorOptions}
              defaultValue={data.operator}
              onChange={e => this.setFieldData("operator", e.target.value)}
            />
          );

          var multiValue = data.value
          if(typeof multiValue === "object" && multiValue !== null){
            multiValue = multiValue.toJS();
          }
          var valueInput = (
            <MultiSelect
              id="multiSelect"
              myRef={this.input}
              options={optionsToSelect}
              defaultValue={multiValue}
              open={false}
              onChange={value => this.setFieldData("value", value)}
              onBlur={values => this.setFieldData("value", values)}
            />
          );
        }
      } else if (type === "multienum") {
        const options = data.def.get("options");
        if (options != null && options != "") {
          const optionData = sAction.app_strings[options];
          let multiEnumOptions = [];
          optionData.forEachObject((value, key) => {
            multiEnumOptions.push({ value: key, label: value });
          });

          var operatorEOptions = [
            { value: "contLikeArray", label: sAction.translate("LBL_CONTAINS") },
            { value: "ncontLikeArray", label: sAction.translate("LBL_NOT_CONTAINS") }
          ];

          selectOption = (
              <Select
                  containerClassName="withBorder"
                  options={operatorEOptions}
                  defaultValue={data.operator}
                  onChange={e => this.setFieldData("operator", e.target.value)}
              />
          );

          var multiEnumValue = data.value;
          if(typeof multiEnumValue === "object" && multiEnumValue !== null){
            multiEnumValue = multiEnumValue.toJS();
          }
          var valueInput = (
              <MultiSelect
                  id="multiSelect"
                  myRef={this.input}
                  options={multiEnumOptions}
                  defaultValue={multiEnumValue}
                  open={false}
                  onChange={value => this.setFieldData("value", value)}
                  onBlur={values => this.setFieldData("value", values)}
              />
          );
        }
      } else if (type == "bool") {
        var defaultValue = data.value;
        if (defaultValue == null || defaultValue == false) {
          defaultValue = 0;
        }else if(defaultValue == true){
          defaultValue = 1;
        }
        selectOption = (
          <CheckBox
            defaultValue={defaultValue}
            autoSetValue={true}
            onChange={value => this.setFieldData("value", value == false ? 0 : 1)}
          />
        );
      } else if (
        type == "int" ||
        type == "decimal" ||
        type == "currency" ||
        type == "float"
      ) {
        var options = sAction.getNumberFilterOptions();
        selectOption = (
          <Select
            containerClassName="withBorder"
            options={options}
            defaultValue={data.operator}
            onChange={e => this.setFieldData("operator", e.target.value)}
          />
        );

        disabled = false;
        if (data.operator == null) {
          disabled = true;
        } else {
          var disabledOptions = [];
          options.forEach((value) => {
            disabledOptions[value.value] = value.disabledInput;
          });
          disabled = disabledOptions[data.operator];
        }

        valueInput = (
          <InputText
            defaultValue={data.value}
            disabled={disabled}
            myRef={this.input}
            onBlur={() => this.setFieldData("value", this.input.current.value)}
            type="text"
          />
        );
      } else if (type == 'placeholder') {
        // Netřeba nic vykreslit
      } else {
        const stringOptions = sAction.getVarcharFilterOptions();
        selectOption = (
          <Select
            containerClassName="withBorder"
            options={stringOptions}
            defaultValue={data.operator}
            onChange={e => this.setFieldData("operator", e.target.value)}
          />
        );

        disabled = false;
        if (data.operator == null) {
          disabled = true;
        } else {
          var disabledOptions = [];
          stringOptions.forEach((value) => {
            disabledOptions[value.value] = value.disabledInput;
          });
          disabled = disabledOptions[data.operator];
        }

        valueInput = (
          <InputText
            defaultValue={data.value}
            disabled={disabled}
            myRef={this.input}
            onBlur={() => this.setFieldData("value", this.input.current.value)}
            type="text"
          />
        );
      }
    }

    return (
      <div className="reportWindowFieldLine" ref="line">
        <div className="reportWindowHorizontalLine" />
        <div className="reportWindowFieldLineContainer">
          <div className="reportWindowDeleteLineContaner">
            <div
              className="reportWindowDeleteLine iconCancel"
              onClick={() => this.deleteLine()}
            />
          </div>
          <div className="reportWindowFieldSelectContainer">
            <TabSelect
              containerClassName="withBorder"
              defaultValue={data.select}
              search={true}
              options={fieldsRender}
              onChange={e => this.selectField(e)}
            />
          </div>
          <div
            className="reportWindowFieldOperatorSelectContainer"
            style={data.name == null ? { display: "none" } : {}}
          >
            {selectOption}
          </div>
          <div
            className="reportWindowFieldValueContainer"
            style={data.name == null ? { display: "none" } : {}}
          >
            {valueInput}
          </div>
        </div>
      </div>
    );
  }
}

export default ReportWindowContentFieldLine;
