export default function rightPanelMassUpdate(data){
    this.openRightPanel("load");
    this.dsClear();
    var self = this;

    this.rest.get("defs/" + data.module, resp => {

        const prefix = "rightPanel/data";

        var akceRP = {
            type: 'MASSUPDATEFIELDS',
            content: {fields: resp, module: data.module, selected: data.selected},
            prefix: prefix
        }

        self.dsAddCustom(akceRP);

        self.dsAdd("set","rightPanel/content","massUpdateView");
        
        self.dsProcess();
    });
 
}