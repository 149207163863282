import sAction from "../index";

export default function saveRecordBase(paramData, callback) {
  this.doRoute = true;
  const module = paramData.module;
  var fields = this.dataGet(paramData.prefix + "/fields");
  var wrongFields = [];

  // promazani zmen v local storage
  sAction.removeChangesFromLS(module,sAction.dataGet('conf/user/id'))

  // _______________________________________________ONLY QUOTES
  if(module == "Meetings" || module == "Calls"){
    this.dataSet(paramData.prefix + "/changes/invitees", this.dataGet(paramData.prefix + "/customData/invitees/relatedInv"));
  }
  else if (module == "Quotes") {
    //handeling product lines on Quotes
    let quoteData = this.dataGet(paramData.prefix + "/customData");
    var prodData = quoteData.productData;
    const sums = this.calcSums(paramData.prefix + "/customData/productData", {
      type: "total",
      data: prodData
    });
    const duplicateId = quoteData.duplicateId;
    const type = quoteData.type;
    var customData = { productData: prodData,  total_tax : sums.total_tax, total_discount : sums.total_discount, total: sums.total, duplicateId, type };
    this.dataSet(paramData.prefix + "/changes/customData", customData);
  }
  else if(module == "Opportunities"){
    this.dataSet(paramData.prefix + "/changes/customData/productData", this.dataGet(paramData.prefix + "/customData/productData"));
  }
  // _______________________________________________ONLY QUOTES END
    // _______________________________________________CUSTOM LINES
    let customLinesData = this.dataGet(paramData.prefix + '/customData');
    if (customLinesData && customLinesData.get && customLinesData.get('customLines') && customLinesData.get('customLines').get('lines')) {
        // CONVERT TO CHANGES
        this.dataSet(paramData.prefix + "/changes/customData/customLines", customLinesData.get('customLines').get('lines'));
        // CHECK IF REQUIRED
        let requiredFields = [];
        // Ziska povinna pole
        customLinesData.get('customLines').get('def').get('fields').toJS().forEachObject((field, key) => {
            if (customLinesData.get('customLines').get('moduleData').get(field).get('required') === true) {
                requiredFields.push(field);
            }
        });
        // Zjisti jeslti nejake povinne pole je prazdne
        if (Array.isArray(requiredFields) && requiredFields.length) {
            customLinesData.get('customLines').get('lines').toJS().forEachObject((line, key) => {
                requiredFields.forEach((field) => {
                    if (!line[field] && line['deleted'] !== 1 && line['deleted'] !== '1') {
                        this.dsClear();
                        this.dsAdd('set', paramData.prefix + '/customData/customLines/showError', true);
                        this.dsProcess();
                        sendToCrm = false;
                    }
                });
            });
        }
    }
    // _______________________________________________CUSTOM LINES END
    var editFields = this.dataGet(paramData.prefix + "/changes");


  const emails = this.customFieldEmail(paramData.prefix);
  if (emails) {
    editFields = editFields.setIn(["fields", "email1"], emails);
  }
    if (paramData.canSave === true) {
      if (this.custom("beforeSave", {})) {
        var sendToCrm = true;
        fields.valueSeq().forEach(data => {
          if (!data.def) return;
          if (
            data.def.get("required") === true &&
            (data.value === "" || data.value === null)
          ) {
            wrongFields.push({name:data.name,class:"error"})
            sendToCrm = false;
          }else if(
            data.def.get("required") === true && 
            (data.value != "" || data.value != undefined) && 
            data.customClass == "error"
          ){

            wrongFields.push({name:data.name,class:""})
          }
        });
        if (sendToCrm === false) {
          this.dsClear();
          wrongFields.forEach(fieldName => {
            this.dsAdd("set",paramData.prefix+"/fields/"+fieldName.name+"/customClass",fieldName.class);
          });
          this.dsProcess();
          const data = {
            state: false,
            msg: "Nejsou vyplněny všechny povinné údaje"
          };
          callback(data);
        } else {
          const module = paramData.module;
          var src = module+"/";
          var id = paramData.id;
          if (id !== undefined && id !== null) {
            src += id
          }
          this.load();
          var self = this;

          if (module == 'Users' && id && id == this.dataGet('conf/user/id') && editFields.get('fields')) {
            editFields.get('fields').toJS().forEachObject((value, key) => {
              if (key == 'records_per_page') {
                this.dsClear();
                this.dsAdd("set",'conf/user/records_per_page', value);
                this.dsProcess();
              }
            });
          }
          this.rest.post("set/" + src, editFields, msg => {
            if(module == "Quotes"){
              self.rest.post("recalcTopRecent", {}, function(resp){
              });
            }

            const data = {
              state: true,
              data: msg
            };
            callback(data);
          });
        }
      }
    } else {
      const data = {
        state: false,
        msg: "Záznam momentálně nelze uložit"
      };
      callback(data);
    }
  }
