export default function quoteCreateContract(params, hidePopup = false) {
  if (this.getViewName() === "detail") {
    this.rest.post('isQuoteOrder', {quoteID: params.id}, returnData => {
      if (returnData.status === true) {
        this.printFileAndUpload({...params, name: 'Smlouva o dílo', filename: 'Smlouva o dílo', module: 'smlouvaDilo'});
      } else {
        this.error(this.translate('LBL_QUOTE_MISSING_ORDER'));
      }
    });
  } else if (this.getViewName() === "list") {
    const list = this.dataGet(params.prefix);
    var selected = list.selected;

    selected.forEach((id) => {
      // ziskame jmeno
    //   var name = list.rows.find((r) => r.get("key") === "NAME");
    //   name = name.records.find((r) => r.get("id") === id).get("value");
    var name = 'Smlouva o dílo';

    this.printFileAndUpload({ ...params, name, id, filetype: "pdf", filename: 'Smlouva o dílo', module: 'smlouvaDilo' });
    });
  }

  if (hidePopup) this.popupHide();
}
