import React, {Component} from 'react'
import PureComponent from '../../pure'


import sAction from "sAction";

import TextField from "@material-ui/core/TextField";
import InputBase from '@material-ui/core/InputBase';


import Relate from "../../formElements/Relate"
import CheckBox from "../../formElements/CheckBox"
import Text from "../../formElements/Text"
import Select from '../../formElements/Select'
import confProd from "./productLineConfig";
import Currency_id from './../../detailViewFields/Currency_id/Currency_id';
import {Draggable} from "react-beautiful-dnd";


class QProduct extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            descFocused: false
        }
    }


    valChange(way, val) {
        sAction.updateDetailCustom("field", {way: way, value: val});
        if (way.split('groups')[1].includes('/0/lines/0')) {
            let groupsWay = way.split('groups')[0] + 'groups';
            let groups = sAction.dataGet(groupsWay, null, true);
            sAction.dsClear();
            groups.forEach((group, groupKey) => {
                group.lines.forEach((line, key) => {
                    if (line.product_type === 'percentMain' || (line.product_type === 'percentAbove' && groupKey == 0 && key == 1)) {
                        sAction.dsAdd("set", groupsWay + '/' + groupKey + '/lines/' + key + '/percents', 0);
                        sAction.dsAdd("set", groupsWay + '/' + groupKey + '/lines/' + key + '/percents', line.percents);
                    }
                });
            });
            sAction.dsProcess();
        } else {
            let lineWay = way.split('lines/');
            lineWay = lineWay[0] + 'lines/' + (parseInt(lineWay[1].split('/')[0], 10) + 1);
            let line = sAction.dataGet(lineWay);
            if (line && line.product_type === 'percentAbove') {
                sAction.dsClear();
                sAction.dsAdd("set", lineWay + '/percents', 0);
                sAction.dsAdd("set", lineWay + '/percents', line.percents);
                sAction.dsProcess();
            }
        }
    }

    valChangeListPrice(way, rate, val) {
        sAction.updateDetailCustom("field", {way: way + '/list_price', value: val});
        sAction.updateDetailCustom("field", {way: way + '/czk_price', value: val * rate});

        if (way.split('groups')[1].includes('/0/lines/0')) {
            let groupsWay = way.split('groups')[0] + 'groups';
            let groups = sAction.dataGet(groupsWay, null, true);
            sAction.dsClear();
            groups.forEach((group, groupKey) => {
                group.lines.forEach((line, key) => {
                    if (line.product_type === 'percentMain' || (line.product_type === 'percentAbove' && groupKey == 0 && key == 1)) {
                        sAction.dsAdd("set", groupsWay + '/' + groupKey + '/lines/' + key + '/percents', 0);
                        sAction.dsAdd("set", groupsWay + '/' + groupKey + '/lines/' + key + '/percents', line.percents);
                    }
                });
            });
            sAction.dsProcess();
        } else {
            let lineWay = way.split('lines/');
            lineWay = lineWay[0] + 'lines/' + (parseInt(lineWay[1].split('/')[0], 10) + 1);
            let line = sAction.dataGet(lineWay);
            if (line && line.product_type === 'percentAbove') {
                sAction.dsClear();
                sAction.dsAdd("set", lineWay + '/percents', 0);
                sAction.dsAdd("set", lineWay + '/percents', line.percents);
                sAction.dsProcess();
            }
        }
    }

    setAxis(way, fieldName, val) {
        var rate = this.props.rate;
        // if (this.props.data.currency_id != 'b8326c50-6f19-063b-507d-5e7cc12d5619') {
        //     rate = 1;
        // }

        const requiredFields = {
            text: [
                'x_axis',
                'y_axis',
            ],
            ['x-only']: [
                'x_axis',
            ],
            ['y-only']: [
                'y_axis',
            ],
        };
        var product = sAction.dataGet(way);
        if ((product.get('product_template_id') == '' || product.get('product_template_id') == undefined || product.get('product_template_id') == null) || product.get('product_type') === 'classic') {
            this.valChange(way + '/' + fieldName, val);
            return;
        } else {
            var data = {};
            var valid = true;
            requiredFields[product.get('product_type')].forEach((field, key) => {
                var axis = fieldName === field ? val : product.get(field);
                if ((axis == undefined || axis == '' || axis == null || axis === 0 || axis === '0')) {
                    this.valChange(way + '/' + fieldName, val);
                    valid = false;
                    return;
                }
                data[field] = axis;
            });
            data['type'] = this.props.data['product_type'];
            data['template_id'] = this.props.data['product_template_id'];
            if (valid) {
                var type = 'czk';
                if (this.props.data.currency_id == 'b8326c50-6f19-063b-507d-5e7cc12d5619') {
                    type = 'eur';
                }
                // RAMOVE STENY
                if (this.props.data['product_template_id'] === '66b31174-f8af-640f-2d12-5fd772a568bd') {
                    let price = 7335 * (data.x_axis * data.y_axis / 1000000);
                    sAction.dsClear();
                    sAction.dsAdd('set', way + '/list_price', 0);
                    sAction.dsAdd('set', way + '/czk_price', price);
                    sAction.dsAdd("set", way + '/forceUpdate', false);
                    sAction.dsProcess();
                } else {
                    sAction.updateProductData(data, way, rate, type);
                }

                this.valChange(way + '/' + fieldName, val);
            }
        }
    }

    productCallback(way, key, item) {

        if (item.id != undefined && item.id != "") {
            //product template has been selected
            sAction.getProductTemplate(item.id, 'prodName', way, key);
        } else {
            sAction.updateDetailCustom("prodName", {way: way + '/' + key, item: item, template: false});
        }
    }

    prodInfo(params) {
        window.open("#detail/Products/" + params.id, '_blank')
    }

    blurDescription(way, val) {
        this.setState({descFocused: false});
        this.valChange(way, val);
    }

    setDescription(productIndex) {
        let line = sAction.dataGet(`${this.props.way}/${productIndex}`)
        line = line.set("descFocused", !line.descFocused)
        sAction.dataSet(`${this.props.way}/${productIndex}`, line)
    }

    render() {
        const rate = this.props.rate;
        const curr_id = this.props.data.currency_id;
        const conf = confProd.fields;

        var data = this.props.data;

        const way = this.props.way;
        const ind = this.props.ind;
        const itway = way + "/" + ind;
        const readonly = this.props.readonly;

        const dataProd = {
            value: data.name,
            id_value: data.id,
            searchFields: ["name", "mft_part_num", "mft_part_num_int"]
        };
        const module = "ProductTemplates";

        var taxClassOptions = [];
        sAction.app_strings.tax_class_dom.forEachObject((lbl, key) => {
            taxClassOptions.push({value: key, label: lbl});
        });

        var curId = this.props.currency_id;
        if (!curId) {
            curId = "-99";
        }
        const discountOptions = [{value: "0", label: sAction.currencies[curId].symbol}, {value: "1", label: "%"}];
        const discountVal = data.discount_select;


        let buttons = [];
        if (data.id != "" && !readonly) {
            buttons.push({
                id: "fullForm",
                className: "icon-expandIcon qProductProdNameIcon",
                onClick: this.prodInfo,
                params: {id: data.id}
            });
        }

        let descClass = "productLineDescription";
        const keyPre = Date.now();

        var renderComp = [];

        var self = this;

        var relateClass = "qProductProdNameField";

        var arrowsContainer = null;
        if (readonly) {
            relateClass += " readonlyRel";
        }

        conf.forEach(function (field) {
            if (!field.visible) {
                return;
            }

            let val = 0;

            switch (field.type) {
                case 'infoBox':
                    renderComp.push(
                        <div style={{width: field.width + "%"}} key={keyPre + "_" + field.name}>
                            <div style={{marginTop: '4px', marginLeft: '-4px'}}>
                                {data[field.name] ? '(' + data[field.name] + ')' : ''}
                            </div>
                        </div>
                    );
                    break;
                case 'axis':
                    var disabledAxis = field.disabled || readonly;
                    if ((data['product_type'] !== 'text' && data['product_type'] !== 'y-only' && data['product_type'] !== 'x-only') || (data['product_type'] == 'x-only' && field.name == 'y_axis') || (data['product_type'] == 'y-only' && field.name == 'x_axis')) {
                        disabledAxis = true;
                    }
                    renderComp.push(
                        <div style={{width: field.width + "%"}} key={keyPre + "_" + field.name}>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                id={field.name}
                                type="number"
                                defaultValue={data[field.name]}
                                autoComplete="of"
                                disabled={disabledAxis}
                                key={keyPre + "_" + field.name}
                                className="quoteProductField quoteNumberInput"
                                onBlur={(ev) => {
                                    self.setAxis(itway, field.name, ev.target.value);
                                }}
                            />
                        </div>
                    );
                    break;
                case "number":
                    renderComp.push(
                        <div style={{width: field.width + "%"}} key={keyPre + "_" + field.name}>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                id={field.name}
                                type="number"
                                defaultValue={data[field.name]}
                                autoComplete="of"
                                disabled={field.disabled || readonly}
                                key={keyPre + "_" + field.name}
                                className="quoteProductField quoteNumberInput"
                                onBlur={(ev) => self.valChange(itway + "/" + field.name, ev.target.value)}
                            />
                        </div>
                    );
                    break;
                case "percents":
                    let disabled = field.disabled || readonly;
                    if (data['product_type'] !== 'percentMain' && data['product_type'] !== 'percentAbove') {
                        disabled = true;
                    }
                    renderComp.push(
                        <div style={{width: field.width + "%"}} key={keyPre + "_" + field.name}>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                id={field.name}
                                type="number"
                                defaultValue={data[field.name]}
                                autoComplete="of"
                                disabled={disabled}
                                key={keyPre + "_" + field.name}
                                className="quoteProductField quoteNumberInput"
                                onBlur={(ev) => self.valChange(itway + "/" + field.name, ev.target.value)}
                            />
                        </div>
                    );
                    break;
                case "relateProd":
                    renderComp.push(
                        <div className={relateClass} style={{width: field.width + "%"}} key={keyPre + "_" + field.name}>
                            <Relate
                                newRecord={false}
                                data={dataProd}
                                module={module}
                                buttons={buttons}
                                key={keyPre + "_" + field.name}
                                InputProps={{readOnly: readonly}}
                                callback={(item) => self.productCallback(way, ind, item)}
                            />
                        </div>
                    );
                    break;
                case "text":
                    renderComp.push(
                        <div style={{width: field.width + "%"}} key={keyPre + "_" + field.name}>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                className="quoteProductField"
                                id={field.name}
                                type="text"
                                autoComplete="of"
                                disabled={field.disabled || readonly}
                                defaultValue={data[field.name]}
                                key={keyPre + "_" + data[field.name]}
                                onBlur={(ev) => self.valChange(itway + "/" + field.name, ev.target.value)}
                            />
                        </div>
                    );
                    break;
                case "tax_class":
                    renderComp.push(
                        <div style={{width: field.width + "%"}} key={keyPre + "_" + field.name}>
                            <Select
                                open={false}
                                name={field.name}
                                options={taxClassOptions}
                                defaultValue={data[field.name]}
                                disabled={field.disabled || readonly}
                                key={keyPre + "_" + field.name}
                                onChange={(ev) => self.valChange(itway + "/" + field.name, ev.target.value)}
                            />
                        </div>
                    );
                    break;
                case "discount_select":
                    renderComp.push(
                        <div style={{width: field.width + "%"}} key={keyPre + "_" + field.name}>
                            <Select
                                open={false}
                                name={field.name}
                                options={discountOptions}
                                defaultValue={discountVal}
                                disabled={field.disabled || readonly}
                                key={keyPre + "_" + field.name}
                                onChange={(ev) => self.valChange(itway + "/" + field.name, +ev.target.value)}
                            />
                        </div>
                    );
                    break;
                case "price":
                    disabled = field.disabled || readonly;
                    if (field.name == "discount_price") {
                        let price = data.czk_price;
                        if ((data['product_type'] === 'percentMain' || data['product_type'] === 'percentAbove')) {
                            let mainProductPrice = 0;
                            let path = '';
                            if (data['product_type'] === 'percentMain') {
                                path = way.split('/groups/')[0] + '/groups/0/lines/0';
                            } else if (data['product_type'] === 'percentAbove') {
                                path = way + '/' + (ind > 0 ? ind - 1 : 0);
                            }
                            let productPrice = sAction.dataGet(path);
                            let productDiscount = sAction.parseNumber(productPrice.discount_amount);
                            if (productPrice.discount_select) {
                                productDiscount = sAction.parseNumber(productPrice.czk_price) * productPrice.discount_amount / 100;
                            }
                            mainProductPrice = sAction.parseNumber(productPrice.czk_price) - sAction.parseNumber(productDiscount);
                            price = data.percents / 100 * mainProductPrice;
                            price = Math.round(price);
                        }
                        let discount = sAction.parseNumber(data.discount_amount);
                        if (data.discount_select) {
                            discount = (sAction.parseNumber(price) / 100) * discount;
                        }

                        val = price - discount;
                        val = sAction.parseNumber(val);
                    } else if (field.name == "total_price") {
                        let price = data.czk_price;
                        if ((data['product_type'] === 'percentMain' || data['product_type'] === 'percentAbove')) {
                            let mainProductPrice = 0;
                            let path = '';
                            if (data['product_type'] === 'percentMain') {
                                path = way.split('/groups/')[0] + '/groups/0/lines/0';
                            } else if (data['product_type'] === 'percentAbove') {
                                path = way + '/' + (ind > 0 ? ind - 1 : 0);
                            }
                            let productPrice = sAction.dataGet(path);
                            let productDiscount = sAction.parseNumber(productPrice.discount_amount);
                            if (productPrice.discount_select) {
                                productDiscount = sAction.parseNumber(productPrice.czk_price) * productPrice.discount_amount / 100;
                            }
                            mainProductPrice = sAction.parseNumber(productPrice.czk_price) - sAction.parseNumber(productDiscount);
                            price = data.percents / 100 * mainProductPrice * productPrice.quantity;
                            price = Math.round(price);
                        }

                        let discount = sAction.parseNumber(data.discount_amount);
                        if (data.discount_select) {
                            discount = (sAction.parseNumber(price) / 100) * discount;
                        }

                        val = (price - discount) * data.quantity;
                        val = (sAction.parseNumber(val));
                    } else {
                        val = sAction.parseNumber(data[field.name]);
                        if ((data['product_type'] === 'percentMain' || data['product_type'] === 'percentAbove') && field.name !== 'discount_amount') {
                            disabled = true;
                            val = 0;
                        }
                    }

                    // val = Math.ceil(val);
                    renderComp.push(
                        <div style={{width: field.width + "%"}} key={keyPre + "_" + field.name}>
                            <TextField
                                className="priceInput quoteProductField"
                                margin="normal"
                                //b8326c50-6f19-063b-507d-5e7cc12d5619
                                variant="outlined"
                                id={field.name}
                                type="text"
                                key={keyPre + "_" + field.name}
                                autoComplete="of"
                                disabled={disabled}
                                defaultValue={sAction.formatNumber(val, 2)}
                                onBlur={
                                    (ev) => {
                                        if (field.name == 'list_price') {
                                            // if (curr_id == 'b8326c50-6f19-063b-507d-5e7cc12d5619') {
                                            // if () {
                                            self.valChangeListPrice(itway, rate, sAction.parseNumber(ev.target.value))
                                            // } else {
                                            //     self.valChangeListPrice(itway, 1, sAction.parseNumber(ev.target.value))
                                            // }
                                        } else {
                                            self.valChange(itway + "/" + field.name, sAction.parseNumber(ev.target.value))
                                        }
                                    }
                                }
                            />
                        </div>
                    );
                    break;
                case "checkbox":
                    renderComp.push(
                        <div style={{width: field.width + "%"}} key={keyPre + "_" + field.name}>
                            <CheckBox
                                name={field.name}
                                defaultValue={data[field.name]}
                                disabled={field.disabled || readonly}
                                onChange={(value) => self.valChange(itway + "/" + field.name, +value)}
                            />
                        </div>
                    );
                    break;
            }

        });

        var descComp = null;
        if (data.descFocused) {
            descComp = <TextField
                className={descClass}
                multiline={true}
                type="text"
                margin="normal"
                variant="outlined"
                rows={4}
                key={keyPre + "_description"}
                onBlur={(ev) => this.blurDescription(itway + "/description", ev.target.value)}
                defaultValue={data.description}
                autoFocus={true}
            />
        } else {
            descComp = <span>{data.description}</span>
        }
        var prodlinesIcons = <div className="qProductLineButtonContainer">
            <div className="prodLineIcon"/>
            <div className="prodLineIcon"/>
        </div>;

        if (!readonly) {
            prodlinesIcons = <div className="qProductLineButtonContainer">
                <div className="icon-deleteIcon" onClick={() => sAction.updateDetailCustom("delItem", {way: way, index: ind})}/>
                <div className="icon-editDesc" onClick={() => this.setDescription(ind)}/>
            </div>;
        }

        return (
            <Draggable draggableId={`${this.props.groupIndex}-${ind}`} index={ind}>
                {provided => (<div {...provided.draggableProps} ref={provided.innerRef}>
                        <div className="qProductLine">
                            {!readonly ? <div className="qProductLineButtonContainer">
                                <div className="icon-Sortable draggable hoverBlue" {...provided.dragHandleProps}
                                     onMouseDown={() => document.activeElement.blur()}/>
                            </div> : null }
                            {prodlinesIcons}
                            {renderComp}
                        </div>
                        <div className="quoteDescContainer">
                            {descComp}
                        </div>
                    </div>
                )}
            </Draggable>);
    }
}

export default QProduct
