export default function activateListViewFiltering(prefix){
    const src = prefix+"/actFiltering"
    const value = !this.dataGet(src);
    this.dsClear()
    this.dsAdd("set",src,value);
    if(value == false){
        this.dsAdd("set",prefix+"/openFilterData",null);
        this.dsAdd("set",prefix+"/filter",[]);
        
    }
    this.dsProcess();
    if(value == false){
        this.reloadList(0,1,prefix);
    }
} 