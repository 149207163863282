import React from "react";
import PureComponent from "../pure";
import MaterialPopper from '@material-ui/core/Popover';

import sAction from "sAction"
export default class Popper extends PureComponent {

    close(index){
        sAction.dataDelete("conf/popper",index)
    }
    render() {
        const data = this.props.data;
        var poppersRender = [];
        data.forEach((popper, index) => {
            const className = popper.get('className') ? popper.get('className') : '';
            const selector = popper.get("selector");
            const el = document.querySelector(selector);
            var header = popper.get("header") ?? ""
            header = header.replace(":","");
            if (el != null) {
                poppersRender.push(<MaterialPopper
                    className={"acmPopover " + className}
                    anchorEl={el}
                    open={data.get("open") ?? true}
                    key={index}
                >
                    <div className="popperHeader">
                    <label>
                        {header}
                        </label>
                        <div className="icon-closeIconLight closePopperIcon" 
                        onClick={() => this.close(index)}
                    />
                    </div>

                    <div className="popperContent">
                    {popper.get("content")}
                    </div>
                </MaterialPopper>)
            }
        })
        return (
            poppersRender
        )
    }
}