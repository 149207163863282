export default function quoteCreateOrder(data, invoiceIds) {
    this.load();
    this.rest.post('checkAccAddressFromQuote', {quoteID: data.id}, (returnAddressData) => {
        // Pokud existuje adresa na firme
        if (returnAddressData.status === true) {
            let files = [];
            let email = {
                module: 'Quotes',
                id: data.id,
                name: this.dataGet(data.prefix + "/fields/name/value"),
            };

            let params = {
                action: "quote_create_ord",
                record: data.id,
            };
            this.rest.post("customAction", params, (orderReturnData) => {
                if (orderReturnData.errCode == "OK") {
                    orderReturnData.ignoreBackupInvoice = data.ignoreBackupInvoice;
                    files.push(getFileData('print_order', orderReturnData));

                    const offerType = this.dataGet(data.prefix + '/fields/offer_type/value');
                    if (offerType === 'reklamace') {
                        // Jedna se o rekladaci  nic dalsiho netisknout, jen poslat maila
                        reloadSubpanel(this, data, true, false);
                        sendMail(this, files, email, data);
                    } else {
                        // Nejedna se o reklamaci, pokracovat v logice
                        if (!returnAddressData.message || !returnAddressData.message.data || !returnAddressData.message.data.ico) {
                            let dataProhlaseni = orderReturnData;
                            dataProhlaseni.id = data.id;
                            files.push(getFileData('ceste_prohlaseni', dataProhlaseni));
                        }
                        if (!returnAddressData.message || !returnAddressData.message.data || !returnAddressData.message.data.dic) {
                            files.push(getFileData('souhlas_s_gdpr', data));
                            files.push(getFileData('vop', data));
                        }
                        const reklamacniRad = this.dataGet(data.prefix + '/fields/print_reclamation/value');
                        if (reklamacniRad && reklamacniRad !== '0') {
                            files.push(getFileData('reklamacni_rad', data));
                        }

                        const backupInvoice = this.dataGet(data.prefix + '/fields/backup_invoice/value');
                        if (!backupInvoice || backupInvoice === '0' || !parseInt(this.dataGet(`${data.prefix}/fields/cash_adv/value`))) {
                            // Nema zalohovou fakturu, takze poslat bez ni
                            reloadSubpanel(this, data, true, false);
                            sendMail(this, files, email, data);
                        } else {
                            const invParams = {
                                action: 'quote_create_backup_inv',
                                record: data.id,
                                orderID: orderReturnData.record,
                                ignoreBackupInvoice: data.ignoreBackupInvoice,
                                datum_splatnosti: this.dataGet(`${data.prefix}/fields/cash_adv_date/value`),
                                typ_faktury: 'zalohova',
                                cash_adv: 'cash_adv',
                                invoiceIds: invoiceIds,
                            };
                            // Vytvori zalohovou fakturu 1
                            this.rest.post('customAction', invParams, (invoiceReturnData) => {
                                if (invoiceReturnData.errCode == "OK") {
                                    invoiceReturnData.num = '';
                                    files.push(getFileData('print_invoice', invoiceReturnData));
                                    // Pokud existuje zaloha 2
                                    if (parseInt(this.dataGet(`${data.prefix}/fields/cash_adv_two/value`))) {
                                        invParams.typ_faktury = 'zalohova_2';
                                        invParams.datum_splatnosti = this.dataGet(`${data.prefix}/fields/cash_adv_two_date/value`);
                                        invParams.cash_adv = 'cash_adv_two';
                                        this.rest.post('customAction', invParams, (invoiceTwoReturnData) => {
                                            if (invoiceReturnData.errCode == "OK") {
                                                invoiceTwoReturnData.num = '2';
                                                files.push(getFileData('print_invoice', invoiceTwoReturnData));
                                                // Pokud existuje zaloha 3
                                                if (parseInt(this.dataGet(`${data.prefix}/fields/cash_adv_three/value`))) {
                                                    invParams.typ_faktury = 'zalohova_3';
                                                    invParams.datum_splatnosti = this.dataGet(`${data.prefix}/fields/cash_adv_three_date/value`);
                                                    invParams.cash_adv = 'cash_adv_three';
                                                    this.rest.post('customAction', invParams, (invoiceThreeReturnData) => {
                                                        invoiceThreeReturnData.num = '3';
                                                        if (invoiceReturnData.errCode == "OK") {
                                                            files.push(getFileData('print_invoice', invoiceThreeReturnData));
                                                            reloadSubpanel(this, data, true, true);
                                                            sendMail(this, files, email, data);
                                                        } else {
                                                            this.unLoad();
                                                            this.alert(invoiceThreeReturnData.errMsg);
                                                        }
                                                    });
                                                } else {
                                                    reloadSubpanel(this, data, true, true);
                                                    sendMail(this, files, email, data);
                                                }
                                            } else {
                                                this.unLoad();
                                                this.alert(invoiceTwoReturnData.errMsg);
                                            }
                                        });
                                    } else {
                                        reloadSubpanel(this, data, true, true);
                                        sendMail(this,  files, email, data);
                                    }
                                } else {
                                    this.unLoad();
                                    this.alert(invoiceReturnData.errMsg);
                                }
                            });
                        }
                    }
                } else {
                    this.unLoad();
                    this.alert(orderReturnData.errMsg);
                }
            });
        } else {
            // Firma nema adresu
            this.unLoad();
            this.alert(this.translate(returnAddressData.errorMessage));
        }
    });
}


function reloadSubpanel(sAction, data, ord = true, inv = false) {
    if (ord) {
        sAction.reloadSubpanel({parentId: data.id, parentModule: 'Quotes', module: 'acm_orders', relName: 'quotes_acm_orders_1', prefix: data.prefix, way: data.prefix + '/subpanels/4'});
    }
    if (inv) {
        sAction.reloadSubpanel({parentId: data.id, parentModule: 'Quotes', module: 'acm_invoices', relName: 'quotes_acm_invoices_1', prefix: data.prefix, way: data.prefix + '/subpanels/3'});
    }
}

function sendMail(sAction, files, email, params) {
    sAction.getSavedPrintFiles({ files: files, email: email, params: params });
}

function getFileData(file, data) {
    switch (file) {
        case 'print_order':
            return {
                ignoreBackupInvoice: data.ignoreBackupInvoice,
                buttonId: 'print_order',
                module: 'acm_orders',
                prefix: 'view',
                type: 'all',
                id: data.record,
                name: data.name + ' objednavka',
                returnFile: true,
                action: 'printPdf',
                filetype: 'pdf',
                save: true,
            };
        case 'print_invoice':
            return {
                buttonId: 'print_invoice',
                module: 'acm_invoices',
                prefix: 'view',
                type: 'all',
                id: data.record,
                name: data.name + ' zálohová faktura ' + data.num,
                returnFile: true,
                action: 'printPdf',
                filetype: 'pdf',
                save: true,
            };
        case 'souhlas_s_gdpr':
            return {
                save: true,
                filetype: 'pdf',
                action: 'printPdf',
                // action: 'static',
                returnFile: true,
                buttonId: 'souhlas_s_gdpr',
                module: 'GDPR',
                prefix: 'view',
                type: 'all',
                id: data.id,
                name: 'souhlas_s_GDPR',
            };
        case 'ceste_prohlaseni':
            return {
                save: true,
                filetype: 'pdf',
                action: 'printPdf',
                // action: 'static',
                returnFile: true,
                buttonId: 'ceste_prohlaseni',
                module: 'Prohlaseni',
                ord: data.record,
                prefix: 'view',
                type: 'all',
                id: data.id,
                name: 'cestne_prohlaseni',
            };
        case 'reklamacni_rad':
            return {
                save: true,
                filetype: 'pdf',
                action: 'printPdf',
                // action: 'static',
                returnFile: true,
                buttonId: 'reklamacni_rad',
                module: 'ReklamacniRad',
                ord: data.record,
                prefix: 'view',
                type: 'all',
                id: data.id,
                name: 'reklamacni_rad',
            };
        case 'vop':
            return {
                save: true,
                filetype: 'pdf',
                action: 'printPdf',
                // action: 'static',
                returnFile: true,
                buttonId: 'vop',
                module: 'VOP',
                prefix: 'view',
                type: 'all',
                id: data.id,
                name: 'Podmínky_VOP',
            };
    }
}
