import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDateTimePicker  } from '@mui/x-date-pickers';
import { parse } from 'date-fns';
import * as languages from '@mui/x-date-pickers/locales';
import * as languagesMonthsAndDays from  'date-fns/locale';

import sAction from "sAction"

export default class AcmDateTime extends React.Component {
    constructor(props) {
        super()
        this.state = {
            value: this.entryFormat(props.value),
            open: true,
        }
        this.input = React.createRef();
        this.dateTimeFormat = sAction.getDateFormat("datepicker")+" "+sAction.getTimeFormat("datepicker");
    }
    componentDidMount(props) {
        if (this.props.autoOpen == true) {
            this.input.current.click()
        }
    }
    entryFormat(date) {
        if (date == undefined || date == "") {
            return null
        }
        return date
    }
    onChange(date) {
        const dateObj = new Date(date)
        const month = dateObj.getMonth() < 9 ? "0" + (dateObj.getMonth() + 1) : dateObj.getMonth() + 1
        const day = dateObj.getDate() < 10 ? "0" + dateObj.getDate() : dateObj.getDate()
        const hour = dateObj.getHours() < 10 ? "0" + dateObj.getHours() : dateObj.getHours()
        const minutes = dateObj.getMinutes() < 10 ? "0" + dateObj.getMinutes() : dateObj.getMinutes()
        const returnDate = dateObj.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minutes + ":00";
        this.setState({ value: date, open: false })
        if (date === null) {
            this.setState({
              value: null,
              open: false,
            });
            if (this.props.onChange !== undefined) {
              this.props.onChange(this.props.value);
            }
        } else {
            if (this.props.onChange != undefined) {
                this.props.onChange(returnDate)
            }
        }
    }

    formDateTime(inputDate) {
        let date, month, year, hours, minutes;
        date = inputDate.getDate();
        month = inputDate.getMonth() + 1;
        year = inputDate.getFullYear();
        date = date.toString().padStart(2, '0');
        month = month.toString().padStart(2, '0');
        hours = inputDate.getHours();
        minutes = inputDate.getMinutes();
        const formatedDate = `${date}.${month}.${year} ${hours}:${minutes}`;
        if (formatedDate === '01.01.1970 1:0') {
            return parse(this.entryFormat(this.props.value), this.dateTimeFormat, new Date());
        }
        return parse(formatedDate, this.dateTimeFormat, new Date());
      }
    open() {
    }
    render() {
        const currentLanguage = sAction.dataGet('conf/language');

        return (
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                localeText={languages[currentLanguage.replace('_','')].components.MuiLocalizationProvider.defaultProps.localeText}
                adapterLocale={languagesMonthsAndDays[currentLanguage.slice(0, 2)]}
                >
                <MobileDateTimePicker
                    clearable
                    className={"AcmDate " + (this.props.className || null) + ' ' + (this.props.error ? 'error' : null)}
                    value={this.formDateTime(new Date(this.state.value))}
                    autoFocus={this.props.autoFocus ?? false}
                    onChange={date => this.onChange(date)}
                    onClose={() => this.props.onClose != undefined ? this.props.onClose() : {}}
                    ampm={false}
                    format={this.dateTimeFormat}
                    inputRef={this.input}
                    displayWeekNumber={true}
                    error={this.props.error}
                    slotProps={{
                        actionBar: { actions: ["clear", "today", "cancel", "accept"] },
                    }}
                />
            </LocalizationProvider>
        )
    }
}
