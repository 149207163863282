export default function getProductPrice(data, way, rate, type) {
    this.rest.post('getProductPrice', data, returnData => {
        if (returnData['status']) {
            this.dsClear();
            if (type == 'czk') {
                this.dsAdd('set', way + '/list_price', 0);
                this.dsAdd('set', way + '/czk_price', returnData['price']);
            } else {
                this.dsAdd('set', way + '/list_price', returnData['price']);
                this.dsAdd('set', way + '/czk_price', returnData['price'] * rate);
            }
            this.dsAdd("set", way + '/forceUpdate', false);
            this.dsProcess();
        }
    });
}