export default function createMeetingFromTask(data) {
    const params = {action: 'createMeetingFromTask', record : data.id};

    this.load();
    this.rest.post('customAction', params, (response) => {
        if(response.status === true){
            this.href(`#detail/Meetings/${response.record}`);
        }
        else{
            this.unLoad();
            this.alert(response.text);
        }
    });


}
