import detailDefault from "../../detailDefault";

export default class detailAccounts extends detailDefault {
  load(sAction, data) {
    this.updateDetailApiVisibility(sAction, data);
  }

  update(sAction, data) {
    switch (data.field) {
      case "primary_address_country":
        this.updateDetailApiVisibility(sAction, data)
        break;
    }
  }

  //fce skryva/odkryva detail fieldy podle zeme, jestli je zeme CZECH REPUBLIC
  updateDetailApiVisibility(sAction, data) {
    let address;
    if (data.value) {
      address = data.value;
    } else {
      address = sAction.dataGet(data.prefix + "/fields/primary_address_country/value");
    }

    sAction.dsClear();
    if (!address || address === "CZECH REPUBLIC") {
      //zobrazi ikony
      sAction.dsAdd("set", data.prefix + "/fields/account_name/def/showDetailApi", "ApiName");
      sAction.dsAdd("set", data.prefix + "/fields/ticker_symbol/def/showDetailApi", "ApiICO");
      sAction.dsAdd("set", data.prefix + "/fields/primary_address_street/def/showDetailApi", "ApiStreet");
      sAction.dsAdd("set", data.prefix + "/fields/alt_address_street/def/showDetailApi", "ApiStreet");
      sAction.dsAdd("set", data.prefix + "/fields/primary_address_postalcode/def/showDetailApi", "ApiPSC");
      sAction.dsAdd("set", data.prefix + "/fields/alt_address_postalcode/def/showDetailApi", "ApiPSC");
    } else {
      //skryje ikony
      sAction.dsAdd("set", data.prefix + "/fields/account_name/def/showDetailApi", "");
      sAction.dsAdd("set", data.prefix + "/fields/ticker_symbol/def/showDetailApi", "");
      sAction.dsAdd("set", data.prefix + "/fields/primary_address_street/def/showDetailApi", "");
      sAction.dsAdd("set", data.prefix + "/fields/alt_address_street/def/showDetailApi", "");
      sAction.dsAdd("set", data.prefix + "/fields/primary_address_postalcode/def/showDetailApi", "");
      sAction.dsAdd("set", data.prefix + "/fields/alt_address_postalcode/def/showDetailApi", "");
    }
    sAction.dsProcess();
  }
}
