export default function reloadList(
  offset = 0,
  page = 1,
  prefix,
  savedSearch = null
) {
  this.load(); // aktvuje načítání
  const list = this.dataGet(prefix); // získáme data z aktuálního listview

  const module = list.modul;

  const searchData = this.reloadListParams({list,prefix,savedSearch,offset,page});
  var lastOrder = this.getStorage("lastOrder");
  if(lastOrder == null){
    lastOrder = {}
  }
  lastOrder[module] = searchData.order
  this.setStorage("lastOrder",lastOrder);
  

  this.setStorage("listViewSearch",{module:module,data:searchData});

  this.rest.post("list/" + module, searchData, data => {

    if (typeof data !== "object") {
      this.error("špatný návrat ze serveru");
      this.unLoad();
      return;
    }

    var content = this.listContent(data);

    /*
      Pokud se nejedná o uložené vyhledávání předá se aktuální řazení a směr zpět do proměné "content"
      aby se informace propsala do store
    */
    if(data["saved_search_id"] != undefined && data["saved_search_id"] != null){
      savedSearch = data["saved_search_id"];
    }
    
    if (savedSearch == null) {
      content.orderBy = searchData.order.field;
      content.asc = searchData.order.asc;
    }
    // _______________________________________________________________________________________________

    const actFiltering = list.actFiltering;
    var akce = {
      type: "RELOADLIST",
      listViewPrefix: prefix,
      view: this.getViewName(),
      page: module,
      filter: list.filter,
      defaultFilter: list.defaultFilter,
      selected: list.selected,
      selectedActive:list.selectedActive,
      fieldFunction: list.fieldFunction,
      actPagination: page,
      savedSearch,
      content,
      actFiltering,
    };
    this.dataSetCustom(akce);

    /*
      Pokud se jedná o uložené vyhledávíní je potřeba provolat funkci afterLoadListView, kvůli tomu aby 
      se správně zobrazovalo listview
    */
    if (savedSearch != null) {
      this.afterLoadListView(prefix);
    }
    // _________________________________________________________________________________________________
  });
}
