export default function deleteRecords(paramData) {
    const records = this.dataGet(paramData.prefix + "/selected");
    var label = records.size > 1 ? "LBL_BUTTON_CONFIRM_DELETE_RECORDS" : "LBL_BUTTON_CONFIRM_DELETE_RECORD";
    this.confrim(this.translate(label), () => {
        const module = this.dataGet(paramData.prefix + "/modul");
        var ids = [];
        records.forEach(id => {
            ids.push(id);
        });
        this.load();

        const data = {
            ids: ids,
        }

        this.rest.delete("deleteRecords/" + module, data, returnData => {
            if(returnData.state == true){
                if(returnData.notAccessRecords.length != 0){
                    this.error(this.translate("LBL_MASS_DELETE_ACCESS_ERROR"));
                }
                this.reloadList(0, 1, paramData.prefix);
            }
        })
        this.popupHide();
    });
}