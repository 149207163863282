import React from 'react';
import moment from 'moment';

import PureComponent from '../pure';
import Button from "../formElements/Button";
import ButtonFloat from "../formElements/Button";
import ButtonCircle from "../formElements/ButtonFloat";
import CalSettingsForm from './calSettingsForm'

import  sAction  from "sAction";

class CalHeader extends PureComponent{
    constructor (props) {
      super(props);
      this.state = {
        startDate: moment(),
        cssSelector: this.props.calCssSelector,
        openPicker: false
      };
      
    }

    handleChange(date) {
      this.setState({
        startDate: date
      });
      sAction.calBulkActions(this.state.cssSelector, "gotoDate", date, true);
    }
    

    fncView(view){
      // sAction.calBulkActions(this.state.cssSelector, "changeView", view, false);
      sAction.dataSet('calendar/calViewType', view)
    }



    render(){
        const views = sAction.app_strings.calendar_initial_view;
        var viewsBtns = [];
        //$(this.state.cssSelector).fullCalendar();

        const curView = sAction.calBulkActions(this.state.cssSelector, "getView").name;

        for(var view in views) {
          let icon = '';
          switch (view){
            case 'dayGridMonth':
              icon = <div className={"icon-calendar-month calHeaderIcon"}></div>;
              break;
            case 'timeGridWeek':
              icon = <div className={"icon-calendar-week calHeaderIcon"}></div>;
              break;
            case 'timeGridDay':
              icon = <div className={"icon-calendar-day calHeaderIcon"}></div>;
              break;
            case 'listACM':
              icon = <div className={"icon-calendar-agenda calHeaderIcon"}></div>;
              break;
          }
          
          let pom = view;

          let buttonClass = "actionPanelButton calViewButton hoverLightBlue";

          if(view == curView){
            buttonClass += " activeView";
          }

          if(view == "agendaDay"){
            <i className="fas fa-clipboard-list"></i>
          }
          viewsBtns.push(<Button className={buttonClass} key={view} onClick={() => {this.fncView(pom)}}>{icon}{views[view]}</Button>);
          viewsBtns.push(<div className="viewPanelButtonsDelimiter"></div>);
        }

        const setting = this.props.setting;

        let sharedLbl = sAction.translate("LBL_FC_SHARED", "Calendar");
        if(this.props.sharedView){
          sharedLbl = sAction.translate("LBL_FC_NOTSHARED", "Calendar");
        }

        return(
          <div>
            <div className="viewActionPanel calPanel">
              <div className="acmCalHeaderLeftMenu">
              {/*<div className="icon-calendar calHeaderIcon"></div>*/}
              {viewsBtns}
              {/*<div className="viewPanelButtonsDelimiter"></div>*/}
              {/*<Button onClick={() => sAction.calToggleSharedView()} className="calViewButton"> <div className="icon-CalSharedIcon calHeaderIcon"></div>{sharedLbl}</Button>*/}
              <div>
              </div>
              </div>
              <div className="acmCalHeaderCenterMenu">
              </div>
              <div className="acmCalHeaderRightMenu">
                <Button className="calViewButton hoverGreen acmButton" onClick={() => sAction.popup("calSettingsForm", {setting: setting})}>
                <div className={"calHeaderIcon icon-CalSettingsIcon"} ></div>
                {sAction.translate("LBL_SETTINGS", "Calendar")}
              </Button>                 
              </div>
            
            </div>

          </div>
        )
      }
  }
  export default CalHeader
