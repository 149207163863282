export default function actCloseCreateNew(paramData){

    if (paramData.canSave === true) {
        var closeStatus = "Held";
        if(paramData.module == "Tasks"){
            closeStatus = "Completed";
        }

        this.dataSet(paramData.prefix+"/fields/status/value", closeStatus);
        this.dataSet(paramData.prefix+"/changes/fields/status", closeStatus);
    }

    var self = this;
    this.saveRecordBase(paramData, data => {
      if (data.state == true) {
        self.dsClear();
        self.dataSet(paramData.prefix+"/changes", {fields: [], files: []});
        
        //TODO co kdyz to bude otevreno v rightpanelu !!!!!!
        const name = self.dataGet(paramData.prefix+"/fields/name/value");
        const parentName = self.dataGet(paramData.prefix+"/fields/parent_name/value");
        const parentId = self.dataGet(paramData.prefix+"/fields/parent_name/def/id_value");
        const parentModule = self.dataGet(paramData.prefix+"/fields/parent_name/def/parent_type_value");

        self.detailPredefinedFields = [
            {name: "name", value: name, type: "field"}, 
        ];

        if (paramData.module == "Meetings" || paramData.module == "Calls") {
          //popis
          const description = self.dataGet(paramData.prefix+"/fields/description/value");
          self.detailPredefinedFields.push({ type: "field", name: "description", value: description });

          //faze prilezitosti => sondovani
          self.detailPredefinedFields.push({ type: "field", name: "sales_stage", value: "Prospecting" });

          //vazba na accounts
          if (parentModule === "Accounts") {
            self.detailPredefinedFields.push({
              type: "relate_simple",
              name: "account_id",
              fieldName: "account_name",
              value: { name: parentName, id: parentId }
            });
          }
        } else {
          self.push({name: "parent_name", id_name: "parent_id", parentName, parentId, parentModule, type: "parent"});
        }

        //if(this.getModuleName() != paramData.module){
        if(paramData.type == "rightPanel"){
            this.closeRightPanel();
        }
        window.location.hash = "#detail/" + paramData.newModule;
        

        /*
        if(this.getModuleName() != paramData.module){
          this.closeRightPanel();
          this.unLoad();
        }else{
          window.location.hash = "#list/" + msg.module;
        }
        */
      } else {
        self.error(data.msg);
      }
    });
}