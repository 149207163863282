import PropTypes from "prop-types";
import React from "react";
import PureComponent from "../../pure";

import  sAction  from "sAction";

import Radio from "../../formElements/Radio";
import { EmailPopupButtons } from "../email/shared/EmailPopupButtons";
import InputText from "../../formElements/InputText";
import Select from "../../formElements/Select";


/**
 * zobrazuje popup okno na upravení widgetu
 */
export default class HomeWidgetEdit extends PureComponent {
  constructor(props) {
    super(props);

    this.nameRef = React.createRef();
    this.measure = "";
    this.state = {
      widgetSize: 450,
      widgetAutoSize: true,
      widgetSizeUnit: "px"
    };

    let defaultValue = sAction.dataGet(this.props.way + "/data/options/height");
    if (defaultValue) {
      if (defaultValue.indexOf("px") !== -1) {
        this.state.widgetSize = defaultValue.replace(/px/, "");
        this.state.widgetSizeUnit = "px";
      } else if (defaultValue.indexOf("%") !== -1) {
        this.state.widgetSize = defaultValue.replace(/%/, "");
        this.state.widgetSizeUnit = "%";
      }
      this.state.widgetAutoSize = false;
    }
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
    if (name === "widgetSizeUnit" && this.state.widgetSize > 100) {
      this.setState({ widgetSize: 100 });
    }
  };

  // ulozeni zmen v popupu
  savePopup = () => {
    sAction.popupHide();

    const name = this.nameRef.current.value;
    let value = this.state.widgetSize;
    const { widgetSizeUnit, widgetAutoSize } = this.state;

    // zmena velikosti boxu
    if (widgetAutoSize) {
      sAction.dataSet(this.props.way+"/data/options/height", "");
    } else {
      if (widgetSizeUnit === "px" && parseInt(value, 10) < 200) value = "200";
      if (widgetSizeUnit === "%" && parseInt(value, 10) < 10) value = "10";
      sAction.dataSet(
        this.props.way + "/data/options/height",
        `${value}${widgetSizeUnit}`
      );
    }

    // prejmenovani widgetu
    sAction.dataSet(this.props.way + "/name", name);

    // ulozeni layoutu
    sAction.homeLayoutSave();
  };

  render() {
    const { widgetSize, widgetSizeUnit, widgetAutoSize } = this.state;
    const type = this.props.type;
    return (
      <React.Fragment>
        <div className="acmPopupHeader">
          {sAction.translate("LBL_EDIT_WIDGET", "Home")}
        </div>
        {/* Upravit widget */}
        <div className="acmPopupContent">
          <table className="w100">
            <tbody>
              <tr>
                <th>
                  {sAction.translate("LBL_ENTER_NAME", "Home") /* Název */}:
                </th>
                <td>
                  <InputText
                    autoFocus
                    id="name"
                    type="text"
                    inputRef={this.nameRef}
                    className="withBorder"
                    defaultValue={this.props.name}
                    fullWidth
                    onKeyUp={ev => {
                      if (ev.key === "Enter") {
                        ev.preventDefault();
                        this.savePopup();
                      }
                    }}
                  />
                </td>
              </tr>
              {type != "view" && (
                <tr>
                  <th>
                    {sAction.translate(
                      "LBL_ENTER_WIDGET_SIZE",
                      "Home"
                    ) /* Výška */}
                    :
                  </th>
                  <td style={{ padding: "10px 0" }}>
                    <Radio
                      tabIndex={0}
                      onChange={e =>
                        this.handleChange({
                          target: {
                            name: "widgetAutoSize",
                            value: e.target.checked
                          }
                        })
                      }
                      checked={widgetAutoSize}
                    />
                    {sAction.translate("LBL_AUTO_SIZE", "Home")}
                    <Radio
                      tabIndex={0}
                      onChange={e =>
                        this.handleChange({
                          target: {
                            name: "widgetAutoSize",
                            value: !e.target.checked
                          }
                        })
                      }
                      checked={!widgetAutoSize}
                    />
                    {sAction.translate("LBL_OWN_SIZE", "Home")}
                    <span
                      style={{ visibility: widgetAutoSize ? "hidden" : "" }}
                    >
                      <input
                        className="form-control form-control-sm datetimehours calTimeInput"
                        type="number"
                        min={widgetSizeUnit === "%" ? "10" : "200"}
                        max={widgetSizeUnit === "%" ? "100" : ""}
                        step="10"
                        value={widgetSize}
                        onChange={this.handleChange}
                        name="widgetSize"
                        style={{ marginLeft: 12, height: 40 }}
                        onKeyUp={ev => {
                          if (ev.key === "Enter") {
                            ev.preventDefault();
                            this.savePopup();
                          }
                        }}
                      />{" "}
                      <div
                        style={{
                          width: "60px",
                          display: "inline-block"
                        }}
                      >
                        <Select
                          containerClassName="withBorder"
                          value={this.state.widgetSizeUnit}
                          options={[
                            { label: "px", value: "px" },
                            { label: "%", value: "%" }
                          ]}
                          onChange={e =>
                            this.handleChange({
                              target: {
                                name: "widgetSizeUnit",
                                value: e.target.value
                              }
                            })
                          }
                        />
                      </div>
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <EmailPopupButtons onSave={this.savePopup} />
      </React.Fragment>
    );
  }
}

HomeWidgetEdit.propTypes = {
  way: PropTypes.string.isRequired, //"view/tabs/0/cols/0/rows/0/data/options/height"
  name: PropTypes.string.isRequired
};
