export default function sendEmailCustomer(params) {
  this.load();

  const parentType = this.dataGet(`${params.prefix}/fields/parent_name/def/parent_type_value`)
  const parentID = this.dataGet(`${params.prefix}/fields/parent_name/def/id_value`)

  this.rest.post("email/getCustomerData", { module: params.module, id: params.id,parentType:parentType ,parentID:parentID}, (email) => {
    this.unLoad();

    // if (!email.status) {
    //   this.alert(email.err)
    //   return;
    // }

    const data = {
      parent_type: params.module,
      parent_id: params.id,
      parent_name: params.name,

      to_addrs: email.email || "",
    };

    this.openEmailPopup(data);
  });
}
