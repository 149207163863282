

export default function removeFilter(data){

    const filterGroup = {
        "filters": data.filters,
        "operator": data.operator,
        "field" : data.field,
        
      }
      

      var akce = {
        type: "REMOVEFROMFILTER",
        listViewPrefix: data.prefix,
        filter: filterGroup,
      }
      this.dataSetCustom(akce)

      
      this.reloadList(0,1,data.prefix);


}