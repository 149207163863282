export default function route(init = true, data = null) {
  this.load();
  if (init == false) {
    this.dsClear();
    this.dsAdd("set","view",null);
    this.dsAdd("set","notifications/open",false);
  }

  const view = this.getViewName();
  const module = this.getModuleName();

  this.dsAdd("set", "conf/view", view);
  this.dsAdd("set", "conf/page", module);
  this.dsAdd("set", "conf/load", false);
  this.dsAdd("set", "conf/popup/show", false);
  this.dsAdd("set", "conf/popup/content", null);
  this.dsAdd("set", "menu/activeModule", module);

  if (module == "Emails") {
    init = view == "list";
    const openEmail = view == "detail";
    this.routeEmail(init, openEmail);
  } else if (view == "list") {
    var recordsPerPage = null;
    if (data && data.user && data.user.records_per_page) {
      recordsPerPage = data.user.records_per_page;
    } else if (data && data.recordsPerPage) {
      recordsPerPage = data.recordsPerPage;
    }
    this.routeListView(init, recordsPerPage);
  } else if (view == "detail") {
    this.routeDetailView();
  } else if (view == "home" || view == "") {
    this.routeHome(init);
  } else if (view == "globalSearch"){
    this.routeGlobalSearch();
  } else if(view == "leadConvert"){
    this.routeLeadConvert();
  } else if (view == "admin") {
    this.routeAdmin();
  } else if(view == "merge"){
    this.routeMerge();
  } else if (view == "custom") {
    this.routeCustom();
  }
   else {
    this.dsProcess();
  }
}
