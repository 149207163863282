export default function calcSums(way, params){
    var dph = params.data.dph;

    if(params.type == "group"){
        return calcGroupSums(params.data.toJS(), this, dph, way);
    }

    var data = params.data.toJS();

    var total_cost_price = 0;
    var total_list_price = 0;
    var total_discount_amount = 0;
    var total_discount_price = 0;
    var total_tax = 0;

    var self = this;

    data.groups.forEach(function(group){
        let pom = calcGroupSums(group, self, dph, way);
        total_cost_price += pom.total_cost_price;
        total_list_price += pom.total_list_price;
        total_discount_amount += pom.total_discount_amount;
        total_discount_price += pom.total_discount_price;
        total_tax += pom.total_tax;
    });

    // total_cost_price = total_cost_price;
    // total_list_price = total_list_price;
    // total_discount_amount = total_discount_amount;
    // total_discount_price = total_discount_price;
    // total_tax = total_tax;
    total_tax = total_discount_price * dph / 100;
    // total = total_tax + total_discount_price;

    let globalDiscount = (total_discount_price * (data.globalDiscount / 100));

    return {
        total_cost_price : total_cost_price,
        total_list_price : total_list_price,
        total_discount_amount : total_discount_amount,
        total_discount_price_before_global : total_discount_price,
        total_discount_price : total_discount_price - globalDiscount,
        total_discount: total_discount_price,
        total_tax_before_global : total_tax,
        total_tax : (total_discount_price - globalDiscount) * dph / 100,
        total_before_global:  total_tax + total_discount_price,
        total: (total_discount_price - globalDiscount) + (total_discount_price - globalDiscount) * dph / 100,
    };
}

function calcGroupSums(data, sAction, dph, way = null){
    var total_cost_price = 0;
    var total_list_price = 0;
    var total_discount_amount = 0;
    var total_discount_price = 0;
    var total_tax = 0;
    data.lines.forEach(function(prod, key){
        if(prod.opce_c){
            return;
        }
        let pricePerOne = sAction.parseNumber(prod.czk_price);
        if (prod.product_type === 'percentMain' || prod.product_type === 'percentAbove') {
            let parent = {};
            if (prod.product_type === 'percentMain') {
                let mainWay = way.split('/productData')[0] + '/productData/groups/0/lines/0'
                parent = sAction.dataGet(mainWay);
                parent = parent.toJS();
            } else if (prod.product_type === 'percentAbove') {
                parent = data.lines[key == 0 ? 0 : (key - 1)];
            }
            pricePerOne = parent.czk_price;
            if (parent.discount_select == 1) {
                // Procentualni
                pricePerOne *= 1 - (parent.discount_amount / 100);
            } else {
                // Pevna
                pricePerOne -= parent.discount_amount;
            }
            pricePerOne *= parent.quantity;
            pricePerOne *= prod.percents / 100;
        }
        pricePerOne = pricePerOne;

        let line_discout_price = prod.quantity * pricePerOne;

        total_cost_price += prod.quantity*sAction.parseNumber(prod.cost_price);
        total_list_price += line_discout_price;
        let discount = sAction.parseNumber(prod.discount_amount);
        if(prod.discount_select){
            discount = (sAction.parseNumber(pricePerOne)/100)*discount;

        }
        total_discount_amount += prod.quantity*discount;

        line_discout_price = line_discout_price - (prod.quantity*discount);

        total_discount_price += line_discout_price;

        if(prod.tax_class != "Non-Taxable"){
            total_tax += (line_discout_price / 100) * dph;
        }
    });


    return {
        total_cost_price : total_cost_price,
        total_list_price : total_list_price,
        total_discount_amount : total_discount_amount,
        total_discount_price : total_discount_price,
        total_tax : total_tax,
    };
}