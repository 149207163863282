import React from "react";
import PureComponent from "../pure";
import  sAction  from "sAction";
import ResetPassRequest from "./ResetPassRequest";
import LoginForm from "./LoginForm";
import ResetPass from "./ResetPass";

export default class Login extends PureComponent {
  constructor() {
    super();
    this.state = {
      backgroundImage: 'url("' + this.getImageWithFullPath() + '")',
      url: window.location.hash,
    };
  }

  // V součastnosti funkce vrací číslo od 1 do 6 v závislosti na čase v týdnu. Číslo se mění jednou za 1,16 dní.
  getBackgroundFileNumber() {
    const imagesCount = 6;
    const dayCoefficient = 86400000;
    const d = new Date();
    return Math.floor(d.getTime() / dayCoefficient) % imagesCount + 1;
  }

  getImageWithFullPath() {
    const imageFile = this.getBackgroundFileNumber() + '.jpg';
    return 'img/login-background/' + imageFile;
  }

  goToLogin() {
    window.location.hash = '#login';
    this.setState({ url: '#login' });
  }

  goToResetPassword() {
    window.location.hash = '#login/resetPassword';
    this.setState({ url: '#login/resetPassword' });
  }

  render() {
    const hasBgImages = sAction.param.showLoginBackgroundImages;
    let backgroundImage;
    if (hasBgImages) {
      backgroundImage = (<div
        id={"loginPageBackground"}
        style={{ backgroundImage: this.state.backgroundImage }}
      />);
    } else {
      backgroundImage = '';
    }

    var loginWindow;
    switch (window.location.hash) {
      // Přihlašovací okno
      case '#login':
      case '#login/':
        loginWindow = (
          <LoginForm
            goToResetPassword={() => this.goToResetPassword()}
          />
        );
        break;
      // Žádost o reset hesla
      case '#login/resetPassword':
      case '#login/resetPassword/':
        loginWindow = (
          <ResetPassRequest
            goToLogin={() => this.goToLogin()}
          />
        );
        break;
      // Ostatní případy + reset hesla dle tokenu
      default:
        const urlArray = window.location.hash.split("/");
        if (urlArray[1] == 'resetPassword' && urlArray[2]) {
          loginWindow = (
            <ResetPass
              goToLogin={() => this.goToLogin()}
            />
          );
          break;
        }
    }

    return (
      <div>{backgroundImage}
        <div id="loginPage">
          <div id="loginContentContainer">
            <div id="loginContainer" style={hasBgImages ? { boxShadow: 'none' } : {}}>
              <div className="loginContent">
                {loginWindow}
              </div>
            </div>
            <div className="loginPageCreatedBy">
              <div className="version">v{this.props.version}</div>
              <div className="createdBy">{sAction.translate("LBL_LOGIN_CREATED_BY")}<img src={sAction.param.acmarkLogo} /></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
