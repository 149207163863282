export default function routeListView(init, recordsPerPage) {
  var self = this;
  const module = self.getModuleName();

  var lastOrderData = this.getStorage("lastOrder");
  if(lastOrderData == null || (lastOrderData[module] == null || lastOrderData[module] == undefined)){
    lastOrderData = [
      {
        module: module,
        field: "date_entered",
        sort: "asc"
      }
    ]
  }else{
    lastOrderData = lastOrderData[module]
  }
 
  var limit = null;
  if (recordsPerPage) {
    limit = recordsPerPage;
  } else {
    const recordsPerPage = this.dataGet("conf/user/records_per_page");
    var limit = recordsPerPage ? recordsPerPage : this.dataGet("conf/recordsPerPage");
  }
  if (!limit) {
    limit = 25;
  }
  const searchData = {
    limit: limit,
    offset: 0,
    order: lastOrderData,
    filter: {}
  };

  this.setStorage("listViewSearch", { module: module, data: searchData });

  self.rest.post("list/" + module, searchData, data => {

    var content = self.listContent(data);
    content.orderBy = searchData.order.field;
    content.asc = searchData.order.asc;
    var akce = {
      type: "PAGELOADLIST",
      page: module,
      content: content,
      buttons: data.menu,
      function: self
    };
    self.dsAddCustom(akce);
    

    const orderField = lastOrderData[0]["field"];
    const sort = lastOrderData[0]["sort"];
    this.dsAdd("set","view/orderBy",orderField);
    if(sort == "ASC"){
      this.dsAdd("set","view/asc",1);
    }else{
      this.dsAdd("set","view/asc",0);
    }


    self.dsProcess();
  });
}
