export default function ares(sendData, prefix = "view") {
  this.load();

  sendData.ares_zastupci = !!this.dataGet(prefix + "/fields/ares_zastupci");
  const url = sendData.ico ? "ares" : "ares2";

  this.rest.post("detailapi/" + url, sendData, data => {
    this.unLoad();

    var fields = ["billing", "primary", "Contactsprimary"];

    fields.forEach(field => {
      data.forEachObject((v, k) => {
        if (k.charAt(0) === "_") {
          data[field + k] = v;
          delete data[k];
        }
      });
    });

    if (typeof data !== "object") {
      this.error(this.translate("LBL_ARES_NO_DATA", "Accounts"));
      return;
    }

    if (!data.num) {
      this.error(
        this.translate("LBL_ARES_NOT_FOUND", "Accounts")
      );
      return;
    }

    if (data.num && data.num > 1) {
      delete data.num;
      const params = {
        header: this.translate("LBL_VYBER","acm_reports"),
        "accounts": data
      }
      this.popup("aresChooseAccountPopup",  params );
      return;
    }

    this.updateFields(data, prefix);

    if (data.ticker_symbol) {
      this.checkICO(data.ticker_symbol);
    }
  });
}
